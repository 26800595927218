import _ from 'lodash';
import { VUE_APP_WCMSBaseUrl } from './Env';

export default {
  QUESTION_REESPONSE_DEL: '~',

  /* from https://stackoverflow.com/a/901144 */
  getParameterByName(name, url) {
    const myUrl = url || window.location.href;
    const myName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${myName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(myUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  // getDefaultFromQueryStringParm(currentValue, qsName, url) {
  //   const qsValue = this.getParameterByName(qsName, url);
  //   return qsValue || currentValue;
  // },

  defaultOrFirstId(list, currentId, useCurrentIdIfValid) {
    if (list.length === 0) {
      return '';
    }
    if (useCurrentIdIfValid) {
      return _.filter(list, { id: currentId }).length ? currentId : list[0].id;
    }
    return list[0].id;
  },
  defaultOrFirstName(list, currentName, useCurrentNameIfValid) {
    if (list.length === 0) {
      return '';
    }
    if (useCurrentNameIfValid) {
      // const found = _.find(list, { name: currentName });
      const found = _.find(list, (item) => item.name.toLowerCase() === currentName.toLowerCase());
      if (found) {
        return found.id;
      }
    }
    return list[0].id;
  },

  addSelectOne(list, obj) {
    const newObj = obj || { id: '', name: 'Select one', abbr: 'Select one' };
    list.splice(0, 0, newObj);
    return list;
  },

  addAllLocation(list, obj) {
    const newObj = obj || { id: '', name: 'All available locations', abbr: 'All available locations' };
    list.splice(0, 0, newObj);
    return list;
  },

  addNationalLocation(list, obj) {
    const newObj = obj || { id: '', name: 'National', abbr: 'National' };
    list.splice(0, 0, newObj);
    return list;
  },

  addNationalLocationWithId(list, obj, nationalId) {
    const newObj = obj || { id: nationalId, name: 'National', abbr: 'National' };
    list.splice(0, 0, newObj);
    return list;
  },

  // validateTableIdsExistInLookups(lookps, table, tableColumn, lookupType, errors) {
  //   _(table).map(tableColumn).uniq()
  //     .forEach((id) => {
  //       if (_(lookps).find({ id, type: lookupType }) === undefined) {
  //         errors.push(`Missing lookup for id: "${id}", type: "${lookupType}" `);
  //       }
  //     });
  // },

  validateTableIdsExistInLookups(lku, table, tableColumn, lookupType, errors) {
    _(table)
      .map(tableColumn)
      .uniq()
      .forEach((id) => {
        if (lku[lookupType][id] === undefined) {
          // id = 99 is Missing Location
          if (id !== '99') {
            errors.push(`Missing lookup for id: "${id}", type: "${lookupType}" `);
          }
        }
      });
  },

  checkForMissingLookupTypes(lku) {
    // const start = performance.now();
    const missingTypes = [];
    const expectedTypes = [
      'AgeGroup',
      'Category',
      'DataSource',
      'DataSourceType',
      'DataValueType',
      'Gender',
      'Location',
      'Question',
      'RaceEthnicity',
      'Response',
      'RiskFactor',
      'RiskFactorResponse',
      'Topic',
      'Year',
    ];
    expectedTypes.forEach((type) => {
      if (_.size(lku[type]) === 0) {
        missingTypes.push(`Lookups is missing rows for type: ${type}`);
      }
    });
    // console.log('checkForMissingLookupTypes time: ', performance.now() - start);
    return missingTypes;
  },

  checkForMissingLookupIdsInDSLink(lku, dsLink) {
    // const start = performance.now();
    const missingIds = [];
    // this.validateTableIdsExistInLookups(lku, dsLink, 'dstid', 'DataSourceType', missingIds);
    this.validateTableIdsExistInLookups(lku, dsLink, 'dsid', 'DataSource', missingIds);
    // this.validateTableIdsExistInLookups(lku, dsLink, 'cid', 'Category', missingIds);
    // this.validateTableIdsExistInLookups(lku, dsLink, 'tid', 'Topic', missingIds);
    this.validateTableIdsExistInLookups(lku, dsLink, 'qid', 'Question', missingIds);
    // console.log('checkForMissingLookupIdsInDSLink time: ', performance.now() - start);
    return missingIds;
  },

  checkForMissingLookupIdsInIndicatorAllIds(lku, indicatorAllIds) {
    // const start = performance.now();
    const missingIds = [];
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'yr', 'Year', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'rs', 'Response', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'ag', 'AgeGroup', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'ge', 'Gender', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 're', 'RaceEthnicity', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'rf', 'RiskFactor', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'rfr', 'RiskFactorResponse', missingIds);
    this.validateTableIdsExistInLookups(lku, indicatorAllIds, 'dv', 'DataValueType', missingIds);
    // console.log('checkForMissingLookupIdsInIndicatorAllIds time: ', performance.now() - start);
    return missingIds;
  },

  checkForMissingLookupIdsInReportDataIds(lku, reportDataIds) {
    // const start = performance.now();
    const missingIds = [];
    // just check location since others should already be validated due
    // to previous indicatorAllIds check
    this.validateTableIdsExistInLookups(lku, reportDataIds, 'loc', 'Location', missingIds);
    // console.log('checkForMissingLookupIdsInReportDataIds time: ', performance.now() - start);
    return missingIds;
  },

  checkForMissingIndicatorResources1Footnotes(dsLink, footnotes) {
    // const start = performance.now();
    const missing = [];
    _(dsLink)
      .map('dsid')
      .uniq()
      .forEach((id) => {
        if (_(footnotes).find({ type: 'IndicatorResources1', sc: id }) === undefined) {
          missing.push(`Missing footnote type: "IndicatorResources1" for data source: "${id}"`);
        }
      });
    // console.log('checkForMissingIndicatorResources1Footnotes time: ', performance.now() - start);
    return missing;
  },

  // checkForMissingIndicatorResources2Footnotes(dsLink, footnotes) {
  //   // const start = performance.now();
  //   const missing = [];
  //   _(dsLink).map(item => _.pick(item, ['dsid', 'cid'])).uniqWith(_.isEqual)
  //     .forEach((row) => {
  //       if (_(footnotes).find({ type: 'IndicatorResources2', sc: row.dsid, qc: row.cid }) === undefined) {
  //         missing.push(`Missing footnote type: "IndicatorResources2" for data source: "${row.dsid}" category: "${row.cid}"`);
  //       }
  //     });
  //   // console.log('checkForMissingIndicatorResources2Footnotes time: ', performance.now() - start);
  //   return missing;
  // },
  checkForMissingIndicatorResources2Footnotes(dsLink, footnotes) {
    // const start = performance.now();
    const missing = [];
    _(dsLink)
      .map((item) => _.pick(item, ['dsid']))
      .uniqWith(_.isEqual)
      .forEach((row) => {
        if (_(footnotes).find({ type: 'IndicatorResources2', sc: row.dsid }) === undefined) {
          missing.push(`Missing footnote type: "IndicatorResources2" for data source: "${row.dsid}"`);
        }
      });
    // console.log('checkForMissingIndicatorResources2Footnotes time: ', performance.now() - start);
    return missing;
  },

  checkForDuplicateDataRows(data) {
    const duplicateRowErrors = [];
    _(data)
      .groupBy((r) => `${r.loc},${r.yr},${r.rs},${r.ag},${r.ge},${r.re},${r.rf},${r.rfr},${r.cf}`)
      .forEach((val, key) => {
        if (val.length > 1) {
          duplicateRowErrors.push(`Duplicate key error - found ${val.length} rows for Id's: ${key}.`);
        }
      });
    return duplicateRowErrors;
  },

  convertRelativeUrlToWCMSUrl(url) {
    // leave absolute url as is
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) return url;
    // assume this is a relative WCMS URL, prepend the base URL from the config file
    return VUE_APP_WCMSBaseUrl + url;
  },

  getLocationName(locationId, locations) {
    let locationName = '';
    if (typeof locationId === 'undefined' || locationId === null || locationId.trim() === '') {
      locationName = 'All available locations';
    } else {
      try {
        locationName = _(locations).find({ id: locationId }).name;
      } catch {
        locationName = 'All available locations';
      }
    }
    return locationName;
  },
  getLocationAbbr(locationId, locations) {
    let locationAbbr = '';
    if (typeof locationId === 'undefined' || locationId === null || locationId.trim() === '') {
      locationAbbr = 'All';
    } else {
      try {
        locationAbbr = _(locations).find({ id: locationId }).abbr;
      } catch {
        locationAbbr = 'All';
      }
    }
    return locationAbbr;
  },

  getLocationIdForOmniture(locationId) {
    if (typeof locationId === 'undefined' || locationId === null || locationId === '') {
      return 'A'; // all location;
    }
    return locationId;
  },
  getViewForOmniture(view) {
    if (view) {
      return view.substr(0, 1);
    }
    return '';
  },
  getQuestionId(indicatorId) {
    if (indicatorId && indicatorId.indexOf(this.QUESTION_REESPONSE_DEL) > -1) {
      return indicatorId.split(this.QUESTION_REESPONSE_DEL)[0];
    }
    return '';
  },
  getResponseId(indicatorId) {
    if (indicatorId && indicatorId.indexOf(this.QUESTION_REESPONSE_DEL) > -1) {
      return indicatorId.split(this.QUESTION_REESPONSE_DEL)[1];
    }
    return '';
  },
  getDetailedAgeGroupIndicators() {
    // QDRD~R2_ALLSTG : Prevalence of DR, Detailed Age Groups
    // QDRD~R2_VT : Prevalence of Vision Threatening DR, Detailed Age Groups
    // QDRD~R2_NVT : Prevalence of Non-Vision Threatening DR, Detailed Age Groups
    return ['QDRD~R2_ALLSTG', 'QDRD~R2_VT', 'QDRD~R2_NVT'];
  },
};
