// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import Vue from 'vue';
import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import 'es6-object-assign/auto'; // for IE11 when use vue-js-modal
import 'es6-promise/auto'; // for IE11 when use Axios and esri-loader
import { sync } from 'vuex-router-sync';
import $ from 'jquery';
import api from './api';
import router from './router';
import store from './store';
import App from './App.vue';

// make jquery globally available
window.$ = $;

sync(store, router);
api.get('config').then((response) => {
  const vehssConfig = response.data;

  // Set vehss configuration here.
  /* eslint-disable no-underscore-dangle */
  window.__vehssConfig = {
    environment: vehssConfig.environment,
    omnitureChannel: vehssConfig.omnitureInteractionConfig.channel,
    omnitureInteractionsUrl: vehssConfig.omnitureInteractionConfig.interactionsUrl,
  };

  const v3App = createApp(App);
  v3App.use(router);
  v3App.use(store);
  v3App.use(Notifications);

  // Handle the error
  // eslint-disable-next-line func-names
  v3App.config.errorHandler = function (error, vm, info) {
    console.error('An error occurred:', error);
    console.error('Vue component:', vm);
    console.error('Error info:', info);
  };
  // v3App.config.devtools = VUE_APP_EnableDevTools;
  // v3App.config.productionTip = false; //removed in v3
  v3App.mount('#app');
});
