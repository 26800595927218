import api from '../../../api';
import helper from '../../../helper';

const actions = {
  getIndicatorAllIdsAndSetIndicatorId({ state, commit, rootState }, { indicatorId, locationId }) {
    if (state.GSGo.dataSourceId && indicatorId) {
      let apiUrl = `indicatorAllIds/${state.GSGo.dataSourceId}/${indicatorId}`;
      if (typeof locationId !== 'undefined' && locationId !== null && locationId.trim() !== '') {
        apiUrl = `${apiUrl}/${locationId}`;
      }
      if (state.GSGo && state.GSGo.countyFlag === 'Y') {
        apiUrl = `${apiUrl}?CountyFlag=Y`;
      }
      // Initialize
      commit('setReportDataIds', []);
      commit('setLoadingIndicatorAllIds', true);
      // get all Id's for the selected indicatorId
      // console.log('start get indicatorAllIds', performance.now());
      // console.log('call getIndicatorAllIdsAndSetIndicatorId');
      // call api
      api
        .get(apiUrl)
        .then((response) => {
          // console.log('got indicatorAllIds', performance.now());
          // console.log('getIndicatorAllIds then', response);
          const indicatorAllIdsMissingLookupErrors = helper.checkForMissingLookupIdsInIndicatorAllIds(
            rootState.lku,
            response.data,
          );
          if (indicatorAllIdsMissingLookupErrors.length > 0) {
            indicatorAllIdsMissingLookupErrors.forEach((err) => commit('addDataError', err));
          }
          // console.log('completed checkForMissingLookupIdsInIndicatorAllIds',
          //   performance.now());
          // commit('setIndicatorId', indicatorId);
          // console.log('save getIndicatorAllIdsAndSetIndicatorId');
          // console.log(response.dat);
          commit('setIndicatorAllIds', response.data);
          // commit('setIndicatorChanged', true);
          commit('setLoadingIndicatorAllIds', false);
        })
        .catch((err) => {
          commit('setLoadingIndicatorAllIds', false);
          commit('addApiError', err, { root: true });
        });
    } else {
      // commit('setIndicatorId', indicatorId);
      commit('setIndicatorAllIds', []);
    }
  },
  getReportDataIdsAndSetStratFilterIds({ state, commit, getters, rootState }, { filterObj, locationId }) {
    // return a promise so caller knows when the async api call is complete
    // https://stackoverflow.com/a/40167499
    // console.log('call getReportDataIdsAndSetStratFilterIds');
    return new Promise((resolve, reject) => {
      // console.log('start get reportDataIds', performance.now());
      commit('setLoadingReportDataIds', true);
      const qs = getters.queryStringForAllFilteredIds(filterObj);
      const questionId = helper.getQuestionId(state.GSGo.indicatorId);
      let apiUrl = `reportDataIds/${state.GSGo.dataSourceId}/${questionId}/?${qs}`;
      if (typeof locationId !== 'undefined' && locationId !== null && locationId.trim() !== '') {
        apiUrl = `reportDataIds/${state.GSGo.dataSourceId}/${questionId}/${locationId}/?${qs}`;
      }
      // console.log(' getReportDataIdsAndSetStratFilterIds url');
      // console.log(apiUrl);
      // Initialise
      commit('setReportDataIds', []);
      // call api
      api
        .get(apiUrl)
        .then((response) => {
          // console.log('got reportDataIds', performance.now());
          if (rootState.dataErrors.length > 0) commit('clearDataErrors');
          const reportDataIdsMissingLookupErrors = helper.checkForMissingLookupIdsInReportDataIds(
            rootState.lku,
            response.data,
          );
          if (reportDataIdsMissingLookupErrors.length > 0) {
            reportDataIdsMissingLookupErrors.forEach((err) => {
              commit('addDataError', err);
            });
          }
          const duplicateRowErrors = helper.checkForDuplicateDataRows(response.data);
          if (duplicateRowErrors.length > 0) {
            duplicateRowErrors.forEach((err) => {
              commit('addDataError', err);
            });
          }
          // console.log('completed checkForMissingLookupIdsInReportDataIds',
          // performance.now());
          // console.log('ReportDataIds');
          // console.log(response.data);
          // console.log('save getReportDataIdsAndSetStratFilterIds');
          commit('setReportDataIds', response.data);
          commit('setStratFilters', filterObj);

          if (state.flags.changingFromNationalChartTableToMapView) {
            commit('setChangingFromNationalChartTableToMapView', false);
          }
          if (state.flags.changingToNationalChartTableView) {
            commit('setChangingToNationalChartTableView', false);
          }
          if (state.flags.changingToStateChartTableView) {
            commit('setChangingToStateChartTableView', false);
          }
          if (state.flags.changingFromStateChartTableToChartOrTableView) {
            commit('setChangingFromStateChartTableToChartOrTableView', false);
          }
          if (state.flags.changingFromNationalChartTableToChartOrTableView) {
            commit('setChangingFromNationalChartTableToChartOrTableView', false);
          }
          if (state.flags.changingFromNationalChartOrTableToMapView) {
            commit('setChangingFromNationalChartOrTableToMapView', false);
          }
          if (state.flags.changingFromStateChartTableToMapView) {
            commit('setChangingFromStateChartTableToMapView', false);
          }
          if (state.flags.changingFromStateChartOrTableToMapView) {
            commit('setChangingFromStateChartOrTableToMapView', false);
          }
          if (state.flags.changingFromNationalMapToChartOrTableView) {
            commit('setChangingFromNationalMapToChartOrTableView', false);
          }
          if (state.flags.changingFromStateMapToChartOrTableView) {
            commit('setChangingFromStateMapToChartOrTableView', false);
          }
          commit('setLoadingReportDataIds', false);
          resolve();
        })
        .catch((err) => {
          commit('setLoadingReportDataIds', false);
          commit('addApiError', err, { root: true });
          reject(err);
        });
    });
  },
  getCountryCountyReportDataIdsAndSetStratFilterIds({ state, commit, getters }, { filterObj }) {
    // console.log('call getCountryCountyReportDataIdsAndSetStratFilterIds');
    return new Promise((resolve, reject) => {
      const qs = getters.queryStringForAllFilteredIds(filterObj);
      const questionId = helper.getQuestionId(state.GSGo.indicatorId);
      const apiUrl = `countryCountyReportDataIds/${state.GSGo.dataSourceId}/${questionId}/?${qs}`;

      const lastUrl = state.lastGetCountryCountyReportDataIdsIdsUrl;
      if (apiUrl === lastUrl) {
        if (state.flags.changingFromNationalChartTableToMapView) {
          commit('setChangingFromNationalChartTableToMapView', false);
        }
        resolve();
      } else {
        // Initialize
        commit('setCountryCountyReportDataIds', []);
        commit('setCountryCountyLoadingReportDataIds', true);
        // call api
        api
          .get(apiUrl)
          .then((response) => {
            commit('setCountryCountyReportDataIds', response.data);
            if (state.flags.changingFromNationalChartTableToMapView) {
              commit('setStratFilters', filterObj);
              commit('setChangingFromNationalChartTableToMapView', false);
            }
            commit('setCountryCountyLoadingReportDataIds', false);
            commit('setLastGetCountryCountyReportDataIdsIdsUrl', apiUrl);
            resolve();
          })
          .catch((err) => {
            commit('setCountryCountyLoadingReportDataIds', false);
            commit('addApiError', err, { root: true });
            reject(err);
          });
      }
    });
  },
};

export default actions;
