<template>
  <div class="resultsTable">
    <table class="centered minwidth">
      <caption class="accessibleHide">
        {{ tableSummaryDesc}}
      </caption>
      <thead>
        <tr class="headerRow">
          <td class="labelColumn" />
          <th
            v-for="column in subGroupColumns()"
            :id="`th${column.id}`"
            :key="column.id"
            scope="col"
            class="bold"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="group in tableObj.groupRows">
          <tr v-if="group.name !== ''" :key="group.id">
            <th
              :id="`gh${group.id}`"
              :colspan="subGroupColumns().length + 1"
              scope="colgroup"
              class="groupRow"
            >
              {{ group.name }}
            </th>
          </tr>
          <tr
            v-for="(row, rownum) in groupRows(group.id)"
            :key="`group${group.id}_${rownum}`"
            :class="{ bold: rownum === 0 }"
          >
            <th
              :id="`rh${group.abbr}_${rownum}`"
              :class="{ bold: rownum === 0 }"
              class="rowLabel"
              scope="row"
            >
              {{ row.label }}
            </th>
            <td
              v-for="strat in subGroupColumns()"
              :key="strat.id"
              :headers="`th${strat.id} gh${group.id} rh${group.abbr}_${rownum}`"
              class="stratData"
              v-html="row[strat.id]"
            />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import getPivotedTableViewRows from './TableViewUtil';
import getPivotedTableViewRows2 from './TableViewUtil2';

export default {
  name: 'TableView',

  computed: {
    ...mapState({
      lku: (state) => state.lku,
      reportDataIdsOrg: (state) => state.LP.reportDataIds,
      compareId: (state) => state.LP.stratFilters.compareId,
      compareId2: (state) => state.LP.stratFilters.compareId2,
      locationIdOrg: (state) => state.LP.GSGo.locationId,
      location: (state) => state.LP.GSGo.location,
      countyFlag: (state) => state.LP.GSGo.countyFlag,
      view: (state) => state.LP.view,
      nationalId: (state) => state.LP.nationalId,
    }),
    ...mapGetters([
      'countyFlag',
      'dataValueType',
      'confidenceIntervalLabel',
      'tableSummary',
      'sampleSizeSymbol',
      'compareName',
      'compareName2',
      'dataSourceName',
      'reportDataIdsHasSampleSize',
    ]),
    tableObj() {
      if (this.locationId && this.countyFlag !== 'Y') {
        return getPivotedTableViewRows2(
          this.lku,
          this.reportDataIds,
          this.compareId,
          this.compareId2,
          this.confidenceIntervalLabel,
          this.sampleSizeSymbol,
          this.locationId,
        );
      }

      return getPivotedTableViewRows(
        this.lku,
        this.reportDataIds,
        this.compareId,
        this.confidenceIntervalLabel,
        this.sampleSizeSymbol,
        this.countyFlag,
      );
    },
    tableSummaryDesc() {
      if (this.locationId) {
        return this.createSummaryForOneLocation();
      }
      return this.tableSummary;
    },
    reportDataIds() {
      if (this.view === 'NationalChartTable') {
        return _(this.reportDataIdsOrg).filter({ loc: this.nationalId }).value();
      }
      return this.reportDataIdsOrg;
    },
    locationId() {
      if (this.view === 'NationalChartTable') {
        return this.nationalId;
      }
      return this.locationIdOrg;
    },
  },
  methods: {
    groupRows(id) {
      return _(this.tableObj.pivotedTableViewRows).filter({ groupId: id }).value();
    },

    subGroupColumns() {
      const newSubGroupColumns = [];
      const orgSubGroupColumns = this.tableObj.subGroupColumns;
      for (let i = 0; i < orgSubGroupColumns.length; i += 1) {
        if (orgSubGroupColumns[i].id !== '') {
          if (this.coloumnHasValue(orgSubGroupColumns[i].id)) {
            newSubGroupColumns.push(orgSubGroupColumns[i]); // only add subGroupColumn which has value
          }
        }
      }
      return newSubGroupColumns;
    },

    coloumnHasValue(columnId) {
      const columValues = _(this.tableObj.pivotedTableViewRows).map(columnId).value();
      let hasValue = false;
      for (let i = 0; i < columValues.length; i += 1) {
        if (columValues[i] !== '') {
          hasValue = true;
          break;
        }
      }
      return hasValue;
    },

    createSummaryForOneLocation() {
      if (this.compareId === '') {
        return `This table gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category comparing Overall values (columns) for ${this.location} where each row subheading gives percent, ${this.reportDataIdsHasSampleSize ? 'confidence interval, and sample size' : 'and confidence interval'} (as rows).`;
      }
      if (this.compareId === 'YEAR' && this.compareId2 === '') {
        return `This table gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category comparing Overall values (columns) for each available year (as row headings) where each row subheading gives percent, ${this.reportDataIdsHasSampleSize ? 'confidence interval, and sample size' : 'and confidence interval'} (as rows).`;
      }
      if (this.compareId === 'YEAR' && this.compareId2 !== '') {
        return `This table gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category comparing ${this.compareName2} (as ${this.subGroupColumns().length} columns) for each available year (as row headings) where each row subheading gives percent, ${this.reportDataIdsHasSampleSize ? 'confidence interval, and sample size' : 'and confidence interval'} (as rows).`;
      }

      return `This table gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category comparing ${this.compareName2} (as ${this.subGroupColumns().length} columns) for each ${this.compareName} (as row headings) where each row subheading gives percent, ${this.reportDataIdsHasSampleSize ? 'confidence interval, and sample size' : 'and confidence interval'} (as rows).`;
    },
  },
};

</script>

<style scoped>
  .resultsTable {
    overflow: visible;
    margin: 10px;
  }

  .resultsTable table {
    border-collapse: collapse;
    font-size: 14px;
  }

  .resultsTable th,
  .resultsTable td {
    border: 1px solid grey;
  }

  td,
  th {
    padding: 0 10px;
  }

  .centered {
    margin: 0 auto;
  }

  .headerRow {
    background-color: #B1DAFB;
    font-weight: bold;
  }

  .labelColumn {
    width: 100px;
  }

  .groupRow {
    text-align: left;
    background-color: #e3f2fe;
    font-weight: bold;
  }

  .rowLabel {
    font-weight: normal;
    text-align: left;
  }

  .bold {
    font-weight: bold;
  }

  .stratData {
    text-align: center;
  }

  .minwidth {
    min-width: 300px;
  }
</style>
