import colorSchemes from './ColorSchemes';
import constants from '../constants';

export default function getColorInRange(num, range) {
  let i = 0;
  const numClasses = range.length;
  for (i; i < range.length; i += 1) {
    if (Number(num) >= Number(range[i].min.toFixed(2))
        && Number(num) <= Number(range[i].max.toFixed(2))) {
      if (numClasses < 3) {
        return constants.colorRamp.noDataColor;
      }
      return colorSchemes[constants.colorRamp.defaultColorScheme][numClasses][i];
    }
  }

  return constants.colorRamp.noDataColor;
}
