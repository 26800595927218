import _ from 'lodash';
import isNumber from 'is-number';
import numeral from 'numeral';

function getGroupOrder(inCompareId) {
  switch (inCompareId) {
    case '':
      return 'asc';
    case 'YEAR':
      return 'desc';
    case 'RESPONSE':
      return 'asc';
    case 'AGE':
      return 'asc';
    case 'GENDER':
      return 'asc';
    case 'RACE':
      return 'asc';
    default:
      return 'asc';
  }
}

function getStratCompareColumn(inCompareId) {
  switch (inCompareId) {
    case '':
      return 'loc';
    case 'YEAR':
      return 'yr';
    case 'RESPONSE':
      return 'rs';
    case 'AGE':
      return 'ag';
    case 'GENDER':
      return 'ge';
    case 'RACE':
      return 're';
    default:
      // Diabetes = RFDM
      // Smoking = RFSM
      if (inCompareId.substring(0, 2) === 'RF') return 'rfr';
      throw new Error(`Unexpected compareId: ${inCompareId}`);
  }
}

function getStratCompareLookupType(inCompareId) {
  switch (inCompareId) {
    case '':
      return 'Location';
    case 'YEAR':
      return 'Year';
    case 'RESPONSE':
      return 'Response';
    case 'AGE':
      return 'AgeGroup';
    case 'GENDER':
      return 'Gender';
    case 'RACE':
      return 'RaceEthnicity';
    default:
      if (inCompareId.substring(0, 2) === 'RF') return 'RiskFactorResponse';
      throw new Error(`Unexpected compareId: ${inCompareId}`);
  }
}

export default function getPivotedTableViewRows2(
  lku,
  reportDataIds,
  compareId,
  compareId2,
  confidenceIntervalLabel,
  sampleSizeSymbol,
  locationId,
) {
  /* eslint-disable no-param-reassign */
  // console.log(`compareId: ${compareId}`);
  // console.log(`compareId2: ${compareId2}`);
  // Set Group and Subgroup based on compareId
  if (compareId !== '' && compareId !== 'YEAR') {
    if (compareId2 === '') {
      // compareId2 id  === '' and name === None
      compareId2 = compareId; // compareId2 = compareId1 = subgroup
      compareId = ''; // so it will default to 'loc' as group
    }
  }
  // ---------------------------------------------------------GROUP: groupRows: compareId------------------------------------
  const locationFilter = { loc: locationId };

  // Group by compare1: compareId = Years, compareId2 = ''
  // groupRows: [{"id":"YR7","name":"2015","sort":7},{"id":"YR5","name":"2014","sort":5}]

  const groupCompare = getStratCompareColumn(compareId); // loc, yr, rs, ag, ge, re, rfr
  const groupLookupType = getStratCompareLookupType(compareId); // Location, Year, Response, AgeGroup, Gender, RaceEthnicity, RiskFactorResponse
  const groupOrder = getGroupOrder(compareId);
  const groupRows = _(reportDataIds).filter(locationFilter).map(groupCompare).uniq()
    .map((id) => lku[groupLookupType][id])
    .orderBy('sort', groupOrder)
    .value();
  // ---------------------------------------------------------SUB-GROUP: subGroupColumns: compareId2 ------------------------------------
  const subGroupCompare = getStratCompareColumn(compareId2); // loc, yr, rs, ag, ge, re, rfr
  const subGroupLookupType = getStratCompareLookupType(compareId2); // Location, Year, Response, AgeGroup, Gender, RaceEthnicity, RiskFactorResponse
  const subGroupOrder = getGroupOrder(compareId2);

  function getSubGroupColumns() {
    // this case only happens when:
    // - compareId1 id = 'YEAR' and compareId2 id = '' and mame = Overall
    // - compareId1 id != '' or 'YEAR' AND compareId2 id = '' and name = None ---> delete
    if (compareId2 === '') {
      return [{
        id: 'OVERALL', name: 'Overall', abbr: 'Overall', sort: 1,
      }];
    }
    return _(reportDataIds).map(subGroupCompare).uniq()
      .map((id) => lku[subGroupLookupType][id])
      .orderBy('sort', subGroupOrder)
      .value();
  }

  const subGroupColumns = getSubGroupColumns(); // [{"id":"OVERALL","name":"Overall","abbr":"Overall","sort":1}] || [{"id":"GALL","name":"Total","sort":1}]
  // console.log(`reportDataIds: ${JSON.stringify(reportDataIds)}`);
  // console.log(`subGroupCompare: ${subGroupCompare}`);
  // console.log(`subGroupLookupType: ${subGroupLookupType}`);
  // console.log(`compareId2: ${compareId2}`);
  // console.log(`subGroupColumns: ${JSON.stringify(subGroupColumns)}`);

  // ---------------------------------------------------------GET DATA VALUE-------------------------------------
  function getDataRowLabel(dataValueType, rptDataIds) {
    const dataValueUnit = rptDataIds.length > 0 ? rptDataIds[0].dvu : '';
    if (dataValueUnit === '%') return 'Percent (%)';
    if (dataValueUnit === '') return dataValueType;
    return `${dataValueType} (${dataValueUnit})`;
  }

  const hasCI = _(reportDataIds).map('lci').filter((x) => _.isNumber(x)).value().length > 0;
  const hasSampleSize = _(reportDataIds).map('ss').filter((x) => _.isNumber(x)).value().length > 0;
  const dataRowLabel = getDataRowLabel('Crude Prevalence', reportDataIds);
  const decimalPlaces = 2;

  // ---------------------------------------------------------BUILD DATA ROW------------------------------------
  // console.log('hasCI', hasCI);
  // console.log('hasSampleSize', hasSampleSize);
  // console.log('dataRowLabel', dataRowLabel);
  // groupId   = 01
  // groupName = Alabama
  // rowType   = Data || SampleSize || SampleSize
  // grpRows   = {"loc":"01","yr":"YR7","rs":"RYES","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFAPAR","rfr":"RFALLPAR2","dv":3.2,"dvu":"%","lci":3,"hci":3.4,"ss":47476},
  // subGroupCols = {"id":"OVERALL","name":"Overall","abbr":"Overall","sort":1}
  function buildDataRow(groupId, groupName, rowType, grpRows, subGroupCols) {
    let rowLabel;
    if (rowType === 'Data') {
      rowLabel = dataRowLabel; // Percent (%) || Crude Prevalence
    } else if (rowType === 'CI') {
      rowLabel = confidenceIntervalLabel; // 95% CI
    } else {
      rowLabel = sampleSizeSymbol; // N || n
    }
    const tableRowObj = { groupId, group: groupName, label: rowLabel };

    // console.log(`subGroupColumns: ${JSON.stringify(subGroupCols)}`);
    // Loop through each row in subGroupCols
    _(subGroupCols).forEach((col) => {
      // add column for each strat
      const filter = {};
      if (col.id !== 'OVERALL') filter[subGroupCompare] = col.id; // yr : YR7 ||

      const groupRowsForThisColumn = _(grpRows).filter(filter).value(); // grpRows above: filter out one row for this subgroup column
      // [{"loc":"01","yr":"YR9","rs":"RYES","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFAPAR","rfr":"RFALLPAR2","dv":6.95,"dvu":"%","lci":6.13,"hci":7.76,"ss":256000}]
      // console.log(`groupRowsForThisColumn: ${JSON.stringify(groupRowsForThisColumn)}`);

      let colContent = '';
      if (groupRowsForThisColumn.length === 0) {
        colContent = '';
      } else {
        // Only use the first row if there are more than 1 row for this subgroup.
        // Duplicate id data errors are now caught via
        // the LP action getReportDataIdsAndSetStratFilterIds.
        // {"loc":"01","yr":"YR7","rs":"RYES","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFAPAR","rfr":"RFALLPAR2","dv":3.2,"dvu":"%","lci":3,"hci":3.4,"ss":47476},
        // Percent (%)  3.20 (dv)
        // 95% CI     3.00 - 3.40 (lci-hci)
        // N          47,476 (ss)
        const firstRow = groupRowsForThisColumn[0];
        if (rowType === 'Data') {
          const dataValue = firstRow.dv;
          const footNoteSymbol = firstRow.fs;
          const value = isNumber(dataValue)
            ? Number(dataValue).toFixed(decimalPlaces)
            : dataValue;
          if (value) {
            if (footNoteSymbol) {
              colContent = `${value}<sup>${footNoteSymbol}</sup>`;
            } else {
              colContent = value;
            }
          } else if (footNoteSymbol) {
            colContent = footNoteSymbol;
          }
        } else if (rowType === 'CI') {
          let lowCI = firstRow.lci;
          let highCI = firstRow.hci;
          lowCI = isNumber(lowCI) ? Number(lowCI).toFixed(decimalPlaces) : '';
          highCI = isNumber(highCI) ? Number(highCI).toFixed(decimalPlaces) : '';
          colContent = lowCI && highCI ? `${lowCI} - ${highCI}` : '';
        } else {
          const sampleSize = firstRow.ss;
          colContent = isNumber(sampleSize) ? numeral(sampleSize).format('0,0') : '';
        }
      }
      // add property for strat column
      tableRowObj[col.id] = colContent; // OVERALL : 3.2
    });
    return tableRowObj;
  }

  // ---------------------------------------------------------COMPOSE pivotedTableViewRows------------------------------------
  // groupRows: [{"id":"YR7","name":"2015","sort":7},{"id":"YR5","name":"2014","sort":5}]

  const pivotedTableViewRows = [];
  _(groupRows).forEach((group) => {
    const groupFilter = {};
    groupFilter.loc = locationId; // loc : 01
    if (groupCompare !== 'loc') {
      groupFilter[groupCompare] = group.id; // yr : YR7
    }

    if (group.groupId) {
      groupFilter.groupId = group.groupId; // yr : YR7
    }
    const groupByDataRows = _.filter(reportDataIds, groupFilter);
    pivotedTableViewRows.push(buildDataRow(group.id, group.name ? group.name : group.groupName, 'Data', groupByDataRows, subGroupColumns));
    if (hasCI) pivotedTableViewRows.push(buildDataRow(group.id, group.name ? group.name : group.groupName, 'CI', groupByDataRows, subGroupColumns));
    if (hasSampleSize) pivotedTableViewRows.push(buildDataRow(group.id, group.name ? group.name : group.groupName, 'SampleSize', groupByDataRows, subGroupColumns));
  });

  // return
  // console.log('pivotedTableViewRows', pivotedTableViewRows);
  // pivotedTableViewRows = [{ groupId, group: groupName, label: rowLabel, colNmae1: value, colName2: value, ...}, {...}...]
  return { pivotedTableViewRows, subGroupColumns, groupRows };
}
