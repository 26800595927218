<template>
  <!-- (21, 22) Footer Area -->
  <footer
    aria-label="Footer"
  >
    <!-- (21) Footer -->
    <div class="container-fluid footer-wrapper">
      <div class="">
        <!--footer-cdc.html" -->
        <!-- Begin included content (from /TemplatePackage/4.0/includes/footer-cdc.html) -->
        <div class="row">
          <address
            class="col-md-3"
            itemscope="itemscope"
            itemtype="https://schema.org/Organization"
          >
            <strong>CONTACT CDC-INFO</strong>
            <div class="row mt-2">
              <div class="col-1">
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <use xlink:href="#phone" />
                </svg>
              </div>
              <div class="col">
                <span>Have questions? We have answers.</span>
                <span itemprop="telephone">
                  1-800-CDC-INFO (800-232-4636)
                </span>
                <span itemprop="telephone">
                  TTY: 888-232-6348
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1">
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <use xlink:href="#email" />
                </svg>
              </div>
              <div
                class="col"
                itemprop="contactPoint"
                itemscope="itemscope"
                itemtype="https://schema.org/ContactPoint"
              >
                <span itemprop="url">
                  <a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx">
                    Email CDC-INFO
                  </a>
                </span>
              </div>
            </div>
          </address>
          <div class="col-md-3 mt-3 mt-md-0">
            <strong>CDC INFORMATION</strong>
            <ul>
              <li>
                <a href="https://www.cdc.gov/about/default.htm">
                  About CDC
                </a>
              </li>
              <li>
                <a href="https://jobs.cdc.gov">
                  Jobs
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/funding">
                  Funding
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/Other/policies.html">
                  Policies
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/other/plugins/index.html">
                  File Viewers &amp; Players
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul>
              <li>
                <a href="https://www.cdc.gov/Other/policies.html">
                  Privacy
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/od/foia">
                  FOIA
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/eeo/nofearact/index.htm">
                  No Fear Act
                </a>
              </li>
              <li>
                <a href="https://oig.hhs.gov">
                  OIG
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/other/nondiscrimination.html">
                  Nondiscrimination
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/contact/accessibility.html">
                  Accessibility
                </a>
              </li>
              <li>
                <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">
                  Vulnerability Disclosure Policy
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-3 mt-2 mt-md-0 connect-icons">
            <strong>CONNECT WITH CDC</strong><br>
            <ul class="list-inline mt-3">
              <li>
                <a
                  href="https://www.facebook.com/CDC"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span class="visually-hidden">
                    Facebook
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#fb" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/CDCgov"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span class="visually-hidden">
                    Twitter
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#twitter" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/CDCstreamingHealth"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span class="visually-hidden">
                    Youtube
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#youtube" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/CDCgov/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span class="visually-hidden">
                    Instagram
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#ig" />
                  </svg>
                </a>
              </li>
            </ul><br class="d-none d-md-block">
            <ul class="list-inline mt-2">
              <li>
                <a
                  href="#"
                  class="footer-syndlink"
                >
                  <span class="visually-hidden">
                    Syndicate
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#synd" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/cdctv">
                  <span class="visually-hidden">
                    CDC TV
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#cdctv" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://tools.cdc.gov/podcasts/rss.asp">
                  <span class="visually-hidden">
                    RSS
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#rss" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx">
                  <span class="visually-hidden">
                    Email
                  </span><svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlink:href="#email" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      <!-- End included content (from /TemplatePackage/4.0/includes/footer-cdc.html) -->
      <!--footer-cdc.html" -->
      </div>
    </div>

    <!-- (22) Sub-Footer -->
    <div class="container-fluid agency-footer">
      <div class="">
        <!--footer-agency.html" -->
        <!-- Begin included content (from /TemplatePackage/4.0/includes/footer-agency.html) -->
        <div
          class="row"
          itemscope="itemscope"
          itemtype="https://schema.org/Organization"
        >
          <div class="col-md-6 mt-1 mt-md-0">
            <span
              class="value"
              itemprop="contactPoint"
              itemscope="itemscope"
              itemtype="https://schema.org/ContactPoint"
            >
              <span itemprop="url">
                <a href="https://www.hhs.gov">
                  U.S. Department of Health &amp; Human Services
                </a>
              </span>
            </span>
          </div>
          <div class="col-md-3 mt-1 mt-md-0">
            <span
              class="value"
              itemprop="contactPoint"
              itemscope="itemscope"
              itemtype="https://schema.org/ContactPoint"
            >
              <span itemprop="url">
                <a href="https://www.usa.gov/">
                  USA.gov
                </a>
              </span>
            </span>
          </div>
          <div class="col-md-3 mt-1 mt-md-0">
            <span class="value">
              <span>
                <a
                  href="https://www.cdc.gov/Other/disclaimer.html"
                  class="footer-ext"
                >
                  CDC Website Exit Disclaimer
                </a> <svg
                  class="icon x16"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <use xlink:href="#ext" />
                </svg>
              </span>
            </span>
          </div>
        </div>
      <!-- End included content (from /TemplatePackage/4.0/includes/footer-agency.html) -->
      <!--footer-agency.html" -->
      </div>
    </div>
  </footer>
</template>

<script>
</script>

<style>
</style>
