import LargePanels from '../components/LP/LargePanels.vue';
// import { VUE_APP_DefaultRouteName } from '../Env';
// import { VUE_APP_HomeUrl } from '../Env';

const titlePrefix = process.env.VUE_APP_SystemName;
const titleSuffix = process.env.VUE_APP_PageTitleSuffix;

export default [
  {
    path: '/LP',
    name: 'LargePanels',
    component: LargePanels,
    meta: {
      title: `${titlePrefix} Explore by Indicator ${titleSuffix}`,
      pageTitle: 'Explore by Indicator',
      metaTags: [
        {
          name: 'description',
          content: 'LP description goes here',
        },
        {
          name: 'keywords',
          content: 'LP keywords go here',
        },
      ],
    },
  },
  // {
  //   path: VUE_APP_HomeUrl,
  //   name: 'wcms',
  //   // work around for route to an external link
  //   // https://github.com/vuejs/vue-router/issues/1280#issuecomment-356976705
  //   beforeEnter() {
  //     document.location.href = VUE_APP_HomeUrl;
  //   },
  // },
  // default route
  {
    path: '/:catchAll(.*)',
    name: 'Notfound',
    component: LargePanels,
  },
];
