<template>
  <div class="linkbucket" id="divLinkbucket">
    <div class="contentRow">
      <div class="linkTextFirst">
        <a href="#" @click.prevent="showURLModel">
          <span class="linkIcon">
            <i class="fa fa-share">&nbsp;</i>
          </span>
          Share Link
        </a>
      </div>
      <div class="linkText">
        <a id="anch_5" :href="dataPortalUrl" rel="noopener noreferrer" target="_blank"> Data Portal </a>
      </div>
      <div class="linkText">
        <a v-if="helpUrl" :href="helpUrl" rel="noopener noreferrer" target="_blank"> Help </a>
      </div>
      <template>
        <Teleport to="body" role="dialog" aria-modal="”true”">
          <Transition name="modal">
            <div v-if="showModal" class="modal">
              <div class="modal-wrapper">
                <div class="modal-container">
                  <div class="d-flex modalHeader">
                    <div class="me-auto">Share Link</div>
                    <div>
                      <input type="button" value="" class="visually-hidden" ref="cancelButton1" />
                    </div>
                    <div class="modalCloseX">
                      <a href="#" @click.prevent="closeURLModel">
                        <span class="closeIcon">
                          <i class="fa fa-times" ref="cancelButton" />
                          <span class="visually-hidden">Close</span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="modalInput">
                    <input id="txtURL" v-model="currentURL" ref="txtURL" class="form-control" />
                  </div>
                  <div class="d-flex">
                    <div class="modalLabel me-auto">
                      <label for="txtURL">Copy and paste URL</label>
                    </div>
                    <div class="modalButton">
                      <input
                        type="button"
                        class="btn btn-sm btn-blue-p"
                        value="Copy"
                        @click="copyClicked"
                        @keydown.tab="tabKeyOnCancelButton"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </Teleport>
      </template>
    </div>
  </div>
</template>

<script>
/* global triggerOmnitureInteractions */
/* import { ref } from 'vue'; */
import { mapState, mapGetters } from 'vuex';
// import helper from '../../helper';
import { VUE_APP_DataPortalUrl, VUE_APP_HelpUrl } from '../../Env';

export default {
  data() {
    return {
      currentURL: window.location.href,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      GSGo: (state) => state.LP.GSGo,
      indicatorId: (state) => state.LP.GSGo.indicatorId, // Omniture Only
      stratFilters: (state) => state.LP.stratFilters,
      view: (state) => state.LP.view,
    }),
    ...mapGetters(['yearInteractionCode', 'compareViewInteractionCode']),

    dataPortalUrl() {
      return VUE_APP_DataPortalUrl;
    },
    helpUrl() {
      return VUE_APP_HelpUrl;
    },
  },
  watch: {
    showModal() {
      if (this.showModal) {
        const self = this;
        this.$nextTick().then(() => {
          self.$refs.txtURL.focus();
        });
      }
    },
  },
  methods: {
    showURLModel() {
      this.addAriaHidden();
      this.showModal = true;
      this.currentURL = window.location.href;
    },
    closeURLModel() {
      this.removeAriaHidden();
      this.showModal = false;
    },
    copyClicked() {
      try {
        document.getElementById('txtURL').select();
        document.execCommand('copy');
      } catch (err) {
        console.log('Unable to copy');
      }
      // const countyImnitureId = this.getCountyIdForOmniture();
      // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-IL ${helper.getLocationIdForOmniture(this.GSGo.locationId)},${this.GSGo.dataSourceId},${countyImnitureId},${this.indicatorId},${helper.getViewForOmniture(this.view)},${this.compareViewInteractionCode(this.view, this.GSGo.locationId, this.stratFilters.compareViewYear, this.stratFilters.compareId, this.stratFilters.compareId2)},${this.yearInteractionCode(this.stratFilters.yearId)},${this.stratFilters.responseId},${this.stratFilters.ageId},${this.stratFilters.genderId},${this.stratFilters.raceId},${this.stratFilters.riskFactorId},${this.stratFilters.riskFactorResponseId},${this.stratFilters.dataValueTypeId}`);
      triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier} - SHARED`);
      this.closeURLModel();
    },
    getCountyIdForOmniture() {
      let countyValue = 'False';
      if (this.GSGo) {
        countyValue = this.GSGo.countyFlag === 'Y' ? 'True' : 'False';
      }
      return countyValue;
    },
    tabKeyOnCancelButton(event) {
      if (!event.shiftKey) {
        this.$nextTick(() => {
          this.$refs.cancelButton1.focus();
        });
      }
    },
    addAriaHidden() {
      // add this for 508
      const skipmenu = document.getElementById('skipmenu');
      if (skipmenu) {
        skipmenu.setAttribute('aria-hidden', 'true');
      }
      const app = document.getElementById('app');
      if (app) {
        app.setAttribute('aria-hidden', 'true');
      }
    },
    removeAriaHidden() {
      // add this for 508
      const skipmenu = document.getElementById('skipmenu');
      if (skipmenu) {
        skipmenu.removeAttribute('aria-hidden');
      }
      const app = document.getElementById('app');
      if (app) {
        app.removeAttribute('aria-hidden');
      }
    },
  },
};
</script>

<style scoped>
.linkbucket {
  display: table;
  padding: 0 8px 8px 8px;
}
.contentRow {
  display: table-row;
}
.linkIcon {
  color: black;
  text-decoration: none !important;
}
.linkTextFirst {
  display: table-cell;
  padding-left: 4px;
}
.linkText {
  display: table-cell;
  padding-left: 20px;
}
.closeIcon {
  color: #fff;
  text-decoration: none !important;
}
.modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
