<template>
  <div>
    <div
      v-if="panelFootnotes.length > 0"
      class="b"
    >
      Footnotes
    </div>
    <template>
      <div
        v-for="(fn, index) in panelFootnotes"
        :key="index"
        class="pl3"
      >
        <div class="dib w2">
          {{ fn.fs }}
        </div>
        <div class="dib">
          {{ fn.ft }}
        </div>
      </div>
    </template>
    <div
      v-if="notes.length > 0"
      class="b mt1"
    >
      Note:
    </div>
    <template>
      <div v-for="(n, index) in notes" :key="index" class="pl3">
        {{ n.ft }}
      </div>
    </template>

    <!-- <div class="mt1">
      <span class="b">
        Data Source:
      </span>
      <a
        :href="dataSourceUrl"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ dataSourceFootnoteText }}
      </a>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import helper from '../../helper';

export default {
  computed: {
    ...mapState({
      footnotes: (state) => state.footnotes,
      reportDataIds: (state) => state.LP.reportDataIds,
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
    }),
    panelFootnotes() {
      let fnsymbols = _(this.reportDataIds).map('fs').uniq().value();
      // note fnsymbols => [undefined] if none of the rows have a fs property
      // use compact to convert this to an empty array
      fnsymbols = _.compact(fnsymbols);
      const footnotes = _(fnsymbols).map((x) => _(this.footnotes).find({ fs: x, type: 'Footnote' }))
        .orderBy('sort', 'asc').value();
        // use compact to remove undefined array values which could occur if the
        // footnote symbol is not in the footnote table
        // TODO: add notification if footnote symbol is missing
      return _.compact(footnotes);
    },
    notes() {
      const questionId = helper.getQuestionId(this.indicatorId);
      return _(this.footnotes).filter({ qc: questionId, type: 'Note' })
        .orderBy('sort', 'asc').value();
    },
    dataSourceUrl() {
      const row = _(this.footnotes).find({ fs: this.dataSourceId, type: 'DataSource' });
      return row ? helper.convertRelativeUrlToWCMSUrl(row.url) : '';
    },
    dataSourceFootnoteText() {
      const row = _(this.footnotes).find({ fs: this.dataSourceId, type: 'DataSource' });
      return row ? row.ft : '';
    },
  },
};
</script>

<style scoped>
  .ml2 {
    padding-top: 10px;
  }

  .b {
    font-weight: bold;
  }

  .mt1 {
    margin-top: 1rem;
  }

  .dib {
    display: inline-block;
  }

  .pl3 {
    padding-left: 1rem;
  }

  .w2 {
    width: 2rem;
  }
</style>
