<template>
  <div class="pa2">
    <div v-if="notes.length" class="b mt1">Notes</div>
    <div v-for="(note, index) in notes" :key="`note${index}`" class="pl3">
      {{ note.ft }}
    </div>

    <div v-if="definitions.length" class="b mt1">Measure Definitions</div>
    <div v-for="(def, index) in definitions" :key="`def${index}`" class="pl3">
      {{ def.ft }}
    </div>

    <div class="b mt1">Suggested Citation</div>
    <div class="pl3">
      {{ citation.ft }} [accessed {{ today }}]. URL:
      <a :href="citation.url" rel="noopener noreferrer" target="_blank">
        {{ getFormatedUrl(citation.url) }}
      </a>
    </div>

    <div v-if="!printerFriendly" class="rldiv">
      <div class="rlheader">Related Links</div>
      <ul class="ma0 pv2 cf">
        <li v-for="(link, index) in relatedLinks" :key="`rl${index}`" class="fl w-third">
          <a :href="getFormatedUrl(link.url)" rel="noopener noreferrer" target="_blank">
            {{ link.ft }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import isNumber from 'is-number';
import helper from '../../helper';
import getUSDataAndMappableLocationCountFromReportDataIds from './Maps/MapViewContainerUtil';

export default {
  data() {
    return {
      convertRelativeUrlToWCMSUrl: helper.convertRelativeUrlToWCMSUrl,
    };
  },
  computed: {
    ...mapState({
      footnotes: (state) => state.footnotes,
      dataSourceTypeId: (state) => state.LP.GSGo.dataSourceTypeId,
      view: (state) => state.LP.view,
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      countyFlag: (state) => state.LP.GSGo.countyFlag,
      reportDataIds: (state) => state.LP.reportDataIds,
      lku: (state) => state.lku,
      locationId: (state) => state.LP.GSGo.locationId,
    }),
    ...mapGetters(['printerFriendly']),
    notes() {
      // notes for any data source
      const noteAny = _(this.footnotes)
        .filter((x) => x.type === 'GlobalNote' && (x.sc === undefined || x.sc === null))
        .value();
      // notes for County
      let countyNotes = [];
      if (this.countyFlag === 'Y') {
        countyNotes = _(this.footnotes)
          .filter((x) => x.type === 'GlobalNote' && x.sc === 'County')
          .value();
      }
      // notes for the selected data source
      const noteForDS = _(this.footnotes)
        .filter({ type: 'GlobalNote', stc: 'DataSource', sc: this.dataSourceId })
        .value();
      // combine and sort
      return _(noteAny.concat(noteForDS).concat(countyNotes)).orderBy('sort', 'asc').value();
    },
    definitions() {
      // md for the selected dataSourceType
      const mdForDSType = _(this.footnotes)
        .filter({ type: 'MeasureDefinition', stc: 'DataSourceType', sc: this.dataSourceTypeId })
        .value();
      // md for the selected dataSourceId
      const mdForDS = _(this.footnotes)
        .filter({ type: 'MeasureDefinition', stc: 'DataSource', sc: this.dataSourceId })
        .value();
      // md for any dataSourceType
      let mdForAnyDSType = _(this.footnotes)
        .filter((x) => x.type === 'MeasureDefinition' && x.sc === undefined)
        .value();
      if (this.view === 'NationalMap' || this.view === 'StateMap' || this.view === 'NationalCountyMap') {
        // hide the Missing Location footnote for the Map view
        mdForAnyDSType = _(mdForAnyDSType)
          .filter((x) => x.ft.indexOf('Missing Location') !== 0)
          .value();
      }
      // combined list, sorted
      let arrayDefitions = _(_.concat(mdForDSType, mdForDS, mdForAnyDSType)).orderBy('sort', 'asc').value();
      if (!this.sampleSizeDisplayed()) {
        arrayDefitions = _(arrayDefitions)
          .filter((x) => x.ft.indexOf('People') !== 0)
          .value();
      }
      return arrayDefitions;
    },
    citation() {
      return _(this.footnotes).find({ type: 'SuggestedCitation' });
    },
    today() {
      return moment().format('MMM D, YYYY');
    },
    relatedLinks() {
      // rl for the selected dataSourceType
      const rlForDSType = _(this.footnotes).filter({ type: 'RelatedLinks', sc: this.dataSourceTypeId }).value();
      // links for any dataSourceType
      const rlForAnyDSType = _(this.footnotes)
        .filter((x) => x.type === 'RelatedLinks' && (x.sc === undefined || x.sc === null))
        .value();
      // combined list, sorted
      return _(rlForDSType.concat(rlForAnyDSType)).orderBy('sort', 'asc').value();
    },
  },
  methods: {
    getFormatedUrl(url) {
      return helper.convertRelativeUrlToWCMSUrl(url);
    },
    sampleSizeDisplayed() {
      const apiData = getUSDataAndMappableLocationCountFromReportDataIds(
        this.lku,
        this.reportDataIds,
        this.countyFlag,
        this.locationId,
      );
      return isNumber(apiData.USDataValue) && isNumber(apiData.USSampleSize);
    },
  },
};
</script>

<style scoped>
.b {
  font-weight: bold;
}

.mt1 {
  margin-top: 0.5rem;
}

.rldiv {
  border: 1px solid #c0c0c0;
  margin: 20px 0;
}

.rldiv::after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
}

.rlheader {
  padding: 0.25rem;
  font-weight: bold;
  background-color: lightgray;
  border-bottom: 1px solid #c0c0c0;
}

.pa2 {
  padding-top: 1rem;
}

.pv2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pl3 {
  padding-left: 1rem;
}

.ma0 {
  margin: 0;
}

.w-third {
  width: 33.33333%;
}

.fl,
.fr {
  _display: inline;
}

.fl {
  float: left;
}

.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}
</style>
