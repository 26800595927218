/* global s */
// import Vue from 'vue';
// import Router from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import find from 'array-find';
import _ from 'lodash';
import routes from './routes';

const triggerPageViewDebounce = _.debounce(() => {
  s.pageName = document.title;
  s.t();
}, 1000);

// Vue.use(Router);

// const router = new Router({
//   mode: 'history',
//   base: process.env.VUE_APP_RouterBase,
//   routes,
// });
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_RouterBase),
  routes,
});

// code from https://alligator.io/vuejs/vue-router-modify-head/
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = find(to.matched.slice().reverse(), (r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = find(to.matched.slice().reverse(), (r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
  // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
  }

  if (to.path !== from.path) {
    // trigger a page view beacon if the path changes but not if
    // only the query string has changed.
    // A debounce delay is needed to allow Omniture code to initialize after page load.
    triggerPageViewDebounce();
  }

  next();
});

export default router;
