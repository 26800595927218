// import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import LargePanels from './modules/LP';
import route from './modules/route';

// Vue.use(Vuex);
const store = Vuex.createStore({
  state: {
    flags: {
      initialPageLoad: true,
      showDebugIds: false,
      showMode: '',
      loadingInitialApiData: false,
      loadingDSLinkLocationApiData: false,
      loadingQuestionLocationApiData: false,
      loadingCategoryLevelApiData: false,
      loadingRiskFactorApiData: false,
      loadingDefaultDSApiData: false,
    },
    lku: {
      AgeGroup: {},
      Category: {},
      DataSource: {},
      DataSourceType: {},
      DataValueType: {},
      Gender: {},
      Location: {},
      Question: {},
      RaceEthnicity: {},
      Response: {},
      RiskFactor: {},
      RiskFactorResponse: {},
      Topic: {},
      Year: {},
    },
    dsLink: [],
    footnotes: [],
    apiErrors: [],
    dataErrors: [],
    dsCatLevel: [],
    dsLocationLink: [],
    dsQuestionLocationLink: [],
    dsRiskFactor: [],
    defaultDataSourceId: '',
  },
  getters,
  mutations,
  actions,
  modules: {
    route,
    LP: LargePanels,
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;

// export default new Vuex.Store({
//   state,
//   getters,
//   mutations,
//   actions,
//   modules: {
//     route,
//     LP: LargePanels,
//   },
//   strict: process.env.NODE_ENV !== 'production',

// });
