import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
  flags: {
    showHeadingsAndFilters: false,
    goClicked: false,
    loadingIndicatorAllIds: false,
    loadingReportDataIds: false,
    indicatorChanged: false,
    globalSelectorChanged: false,
    reSelectLevel4: false,
    showFootnotes: true,
    generatingPdf: false,
    // flag for IQ interaction
    hideCountryButtons: false,
    hideNationalMapIcon: false,
    hideStateMapIcon: false,
    loadingCountryCountyReportDataIds: false,
    showCountryCountyMapIcon: false,
    changingFromNationalChartTableToMapView: false,
    changingToNationalChartTableView: false,
    changingToStateChartTableView: false,
    changingFromStateChartTableToChartOrTableView: false,
    changingFromNationalChartTableToChartOrTableView: false,
    changingFromNationalChartOrTableToMapView: false,
    changingFromStateChartTableToMapView: false,
    changingFromStateChartOrTableToMapView: false,
    changingFromNationalMapToChartOrTableView: false,
    changingFromStateMapToChartOrTableView: false,
  },
  // GSGo: {
  //   dataSourceTypeId: '',
  //   dataSourceType: '',
  //   dataSourceId: '',
  //   dataSource: '',
  //   topicId: '',
  //   topic: '',
  //   categoryId: '',
  //   category: '',
  //   indicatorId: '',
  //   indicator: '',
  //   locationId: '',
  //   location: '',
  //   countyFlag: 'N',
  // },
  GSGo: {
    level1Name: '',
    level2Name: '',
    level3Name: '',
    level4Name: '',
    level4Names: [],
    indicatorId: '',
    dataSourceId: '',
    dataSource: '',
    locationId: '',
    location: '',
    gsDataSourceId: '',
    gsLocationId: '',
    riskFactorSubCatId: '',
    countyFlag: 'N',
    countyExist: 'N',
  },
  indicatorAllIds: [],
  stratFilters: {
    compareId: '',
    yearId: '',
    responseId: '',
    ageId: '',
    genderId: '',
    raceId: '',
    riskFactorId: '',
    riskFactorResponseId: '',
    dataValueTypeId: '',
    compareViewYear: '',
    compareId2: '',
  },
  view: 'NationalMap',
  reportDataIds: [],
  mapClassifierId: 'quantile',
  mapClassifierCount: 7,
  compareAllIndicator: false,
  countryCountyReportDataIds: [],
  nationalId: '59',
  singleLocation: false,
  lastCompareValue1: '',
  lastCompareValue2: '',
  lastGetIndicatorAllIdsUrl: '',
  lastGetReportDataIdsUrl: '',
  lastGetCountryCountyReportDataIdsIdsUrl: '',
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: false,
};
