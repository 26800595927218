function getHostEnvironment() {
  const hostName = window.location.hostname;
  if (hostName === 'vehss-dev.apps.ecpaas-dev.cdc.gov') return 'dev';
  if (hostName === 'vehss-qa.apps.ecpaas-dev.cdc.gov') return 'qa';
  if (hostName === 'int-ddt-vehss.cdc.gov') return 'int';
  if (hostName.includes('localhost')) return 'localhost';
  // default to prod
  return 'prod';
}

let VUE_APP_MapServiceUrl_0 = 'https://nccd-proxy.cdc.gov/arcgis/DDT_VEHSS/';
let VUE_APP_MapFieldPrefixStates_0 = 'NCCD_OPENDATA_DDT_VEHSS."CDC\\NCCDARCGIS".%DDT_VEHSS_Data.';
let VUE_APP_HomeUrl_0 = 'https://www.cdc.gov/vision-health-data/index.html';
let VUE_APP_DataPortalUrl_0 = 'https://data.cdc.gov/browse?category=Vision+%26+Eye+Health';
let VUE_APP_HelpUrl_0 = 'https://www.cdc.gov/vision-health-data/help/index.html';
let VUE_APP_WCMSBaseUrl_0 = 'https://www.cdc.gov/';
let VUE_APP_EnableDevTools_0 = false;
let VUE_APP_DefaultRouteName_0 = 'wcms';

if (['dev', 'localhost'].includes(getHostEnvironment())) {
  VUE_APP_EnableDevTools_0 = true;
  VUE_APP_DefaultRouteName_0 = 'LargePanels';
  VUE_APP_HomeUrl_0 = 'https://wwwdev.cdc.gov/vision-health-data/index.html';
  VUE_APP_DataPortalUrl_0 = 'https://data.cdc.gov/browse?category=Vision+%26+Eye+Health';
  VUE_APP_HelpUrl_0 = 'https://www.cdc.gov/vision-health-data/help/index.html';
  VUE_APP_MapServiceUrl_0 = 'https://nccd-proxy-dev.cdc.gov/arcgis/DDT_VEHSS/';
  VUE_APP_MapFieldPrefixStates_0 = 'NCCD_OPENDATA_DDT_VEHSS."CDC\\NCCDARCGIS".%DDT_VEHSS_Data_1.';
  VUE_APP_WCMSBaseUrl_0 = 'https://wwwdev.cdc.gov/';
}

if (['qa'].includes(getHostEnvironment())) {
  VUE_APP_HomeUrl_0 = 'https://wwwdev.cdc.gov/vision-health-data/index.html';
  VUE_APP_MapServiceUrl_0 = 'https://nccd-proxy-qa.cdc.gov/arcgis/DDT_VEHSS/';
  VUE_APP_MapFieldPrefixStates_0 = 'NCCD_OPENDATA_DDT_VEHSS."CDC\\NCCDARCGIS".%DDT_VEHSS_Data_1.';
}

// Int is pointing to the QA map proxy which is pointing to QA map service
if (['int'].includes(getHostEnvironment())) {
  VUE_APP_HomeUrl_0 = 'https://wwwdev.cdc.gov/vision-health-data/index.html';
  VUE_APP_MapServiceUrl_0 = 'https://nccd-proxy-qa.cdc.gov/arcgis/DDT_VEHSS/';
  VUE_APP_MapFieldPrefixStates_0 = 'NCCD_OPENDATA_DDT_VEHSS."CDC\\NCCDARCGIS".%DDT_VEHSS_Data_1.';
}

const VUE_APP_EnableDevTools = VUE_APP_EnableDevTools_0;
const VUE_APP_DefaultRouteName = VUE_APP_DefaultRouteName_0;
const VUE_APP_HomeUrl = VUE_APP_HomeUrl_0;
const VUE_APP_DataPortalUrl = VUE_APP_DataPortalUrl_0;
const VUE_APP_HelpUrl = VUE_APP_HelpUrl_0;
const VUE_APP_MapServiceUrl = VUE_APP_MapServiceUrl_0;
const VUE_APP_MapFieldPrefixStates = VUE_APP_MapFieldPrefixStates_0;
const VUE_APP_WCMSBaseUrl = VUE_APP_WCMSBaseUrl_0;

export {
  VUE_APP_EnableDevTools,
  VUE_APP_DefaultRouteName,
  VUE_APP_HomeUrl,
  VUE_APP_DataPortalUrl,
  VUE_APP_HelpUrl,
  VUE_APP_MapServiceUrl,
  VUE_APP_MapFieldPrefixStates,
  VUE_APP_WCMSBaseUrl,
};
