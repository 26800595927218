<template>
  <!--(3)Header Bar -->
  <div class="container-fluid header-wrapper">
    <div class="">
      <header
        aria-label="Header"
        class="pt-2 pb-2"
      >
        <div class="row">
          <div class="col cdc-logo">
            <!-- header-logo.html -->
            <!-- Begin included content (from /TemplatePackage/4.0/includes/header-logo.html) -->
            <a href="https://www.cdc.gov">
              <span class="visually-hidden">
                Centers for Disease Control and Prevention.
                CDC twenty four seven. Saving Lives, Protecting People
              </span><svg
                width="470"
                height="47"
                class="d-none d-md-block logo-large"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <use xlink:href="#masthead-subpage" />
              </svg>
            </a>
            <a
              href="https://www.cdc.gov"
              tabindex="-1"
            >
              <span class="visually-hidden">
                Centers for Disease Control and Prevention.
                CDC twenty four seven. Saving Lives, Protecting People
              </span><svg
                width="246"
                height="41"
                class="d-md-none logo-small"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <use xlink:href="#masthead-1-col" />
              </svg>
            </a>
            <!-- End included content (from /TemplatePackage/4.0/includes/header-logo.html) -->
            <!-- header-logo.html -->
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
</script>

<style>
</style>
