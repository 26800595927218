import axios from 'axios';
import _ from 'lodash';
import api from '../api';
import helper from '../helper';

const actions = {
  getInitialApiData: ({ commit, state }) => {
    const dsLinkURL = 'DSLink';
    // const categoryLevelURL = 'CategoryLevel';
    // if (locationId !== 'undefined' && locationId !== null && locationId.trim() !== '') {
    //   dsLinkURL = `${dsLinkURL}/${locationId}`;
    // }
    commit('setLoadingInitialApiData', true);
    // console.log('call getInitialApiData');
    // axios.all([api.get('lookups'), api.get(dsLinkURL), api.get('footnotes'), api.get(categoryLevelURL)])
    axios
      .all([api.get('lookups'), api.get(dsLinkURL), api.get('footnotes')])
      .then(
        axios.spread((lookups, ds, fn) => {
          const lookupTypes = _(lookups.data).map('type').uniq().value();
          // returns object keyed by lookup type containing object keyed by id
          // containing object with id, name, abbr (if exists), sort
          const lku = _(lookupTypes).reduce((acc, type) => {
            acc[type] = _(lookups.data)
              .filter({ type })
              .map((item) => _.pick(item, ['id', 'name', 'namealias', 'abbr', 'vt', 'sort']))
              .keyBy('id')
              .value();
            return acc;
          }, {});

          // console.log('save getInitialApiData');
          // console.log(lku);
          commit('setLku', lku);
          commit('setDSLink', ds.data);
          commit('setFootnotes', fn.data);
          // commit('setDSCatLevel', dsCat.data);
          // console.log('lku');
          // console.log(lku);

          let dataErrors = [];
          dataErrors = dataErrors.concat(helper.checkForMissingLookupTypes(state.lku));
          dataErrors = dataErrors.concat(helper.checkForMissingLookupIdsInDSLink(state.lku, state.dsLink));
          dataErrors = dataErrors.concat(
            helper.checkForMissingIndicatorResources1Footnotes(state.dsLink, state.footnotes),
          );
          dataErrors = dataErrors.concat(
            helper.checkForMissingIndicatorResources2Footnotes(state.dsLink, state.footnotes),
          );
          if (dataErrors.length) {
            dataErrors.forEach((error) => {
              commit('addDataError', error);
            });
          }
          commit('setLoadingInitialApiData', false);
        }),
      )
      .catch((err) => {
        commit('setLoadingInitialApiData', false);
        commit('addApiError', err);
      });
  },
  getCategoryLevels: ({ commit }, { dataSourceId, locationId, riskFactorCatId, riskFactorSubCatId }) => {
    const thisDataSourceId = dataSourceId === '' ? 'ALL' : dataSourceId;
    const thislocationId = locationId === '' ? 'ALL' : locationId;
    const categoryLevelURL = `CategoryLevel?dataSourceId=${thisDataSourceId}&locationId=${thislocationId}&riskFactorCatId=${riskFactorCatId}&riskFactorSubCatId=${riskFactorSubCatId}`;

    commit('setLoadingCategoryLevelApiData', true);
    // console.log('call getCategoryLevels');
    api
      .get(categoryLevelURL)
      .then((response) => {
        commit('setDSCatLevel', response.data);
        commit('setLoadingCategoryLevelApiData', false);
      })
      .catch((err) => {
        commit('setLoadingCategoryLevelApiData', false);
        commit('addApiError', err);
      });
  },
  getDSLinkLocationApiData: ({ commit, state }, { locationId, questionId, responseId }) => {
    let dsLinkURL = 'DSLink';
    if (typeof locationId !== 'undefined' && locationId !== null && locationId.trim() !== '') {
      dsLinkURL = `${dsLinkURL}/${locationId}/${questionId}/${responseId}`;
      commit('setLoadingDSLinkLocationApiData', true);
      // console.log('call getDSLinkLocationApiData');
      api
        .get(dsLinkURL)
        .then((response) => {
          commit('setDSLocationLink', response.data);
          let dataErrors = [];
          dataErrors = dataErrors.concat(helper.checkForMissingLookupIdsInDSLink(state.lku, state.dsLocationLink));
          if (dataErrors.length) {
            dataErrors.forEach((error) => {
              commit('addDataError', error);
            });
          }
          // console.log('save getDSLinkLocationApiData');
          commit('setLoadingDSLinkLocationApiData', false);
        })
        .catch((err) => {
          commit('setLoadingDSLinkLocationApiData', false);
          commit('addApiError', err);
        });
    }
  },
  getQuestionLocationApiData: ({ commit }, questionId) => {
    const questionLocationURL = `QuestionLocation/${questionId}`;
    commit('setLoadingQuestionLocationApiData', true);
    // console.log('call getQuestionLocationApiData');
    api
      .get(questionLocationURL)
      .then((response) => {
        // console.log('save getQuestionLocationApiData');
        commit('setDSQuestionLocationLink', response.data);
        commit('setLoadingQuestionLocationApiData', false);
      })
      .catch((err) => {
        commit('setLoadingQuestionLocationApiData', false);
        commit('addApiError', err);
      });
  },
  getRiskFactors: ({ commit }, { dataSourceId, locationId }) => {
    const riskFactorURL = `lookups/GetRiskFactor?dataSourceId=${dataSourceId}&locationId=${locationId}`;

    commit('setLoadingRiskFactorApiData', true);
    // console.log('call getRiskFactors');
    api
      .get(riskFactorURL)
      .then((response) => {
        // console.log('response.data');
        // console.log(response.data);
        commit('setDSRiskFactor', response.data);
        commit('setLoadingRiskFactorApiData', false);
      })
      .catch((err) => {
        commit('setLoadingRiskFactorApiData', false);
        commit('addApiError', err);
      });
  },
  getRiskFactorDefaultDS: ({ commit }, { riskFactorCatId, riskFactorSubCatId }) => {
    const url = `lookups/GetDefaultDataSource?riskFactorCatId=${riskFactorCatId}&riskFactorSubCatId=${riskFactorSubCatId}`;
    commit('setLoadingDefaultDSApiData', true);
    api
      .get(url)
      .then((response) => {
        commit('setDefaultDataSourceId', response.data);
        commit('setLoadingDefaultDSApiData', false);
      })
      .catch((err) => {
        commit('setLoadingDefaultDSApiData', false);
        commit('addApiError', err);
      });
  },
};

export default actions;
