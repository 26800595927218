import _ from 'lodash';
import moment from 'moment';
import * as d3 from 'd3';
import helper from '../../../helper';
import { VUE_APP_WCMSBaseUrl } from '../../../Env';

/* eslint no-shadow: ["error", {"allow": ["getters"]}] */
const getters = {
  question: (state, getters, rootState) => {
    const questionId = helper.getQuestionId(state.GSGo.indicatorId);
    const row = rootState.lku.Question[questionId];
    return row ? row.name : '';
  },
  dataSourceTypeName: (state, getters, rootState) => {
    const row = rootState.lku.DataSourceType[state.GSGo.dataSourceTypeId];
    return row ? row.name : '';
  },
  dataSourceName: (state, getters, rootState) => {
    const row = rootState.lku.DataSource[state.GSGo.dataSourceId];
    return row ? row.name : '';
  },
  topicName: (state, getters, rootState) => {
    const row = rootState.lku.Topic[state.GSGo.topicId];
    return row ? row.name : '';
  },
  categoryName: (state, getters, rootState) => {
    const row = rootState.lku.Category[state.GSGo.categoryId];
    return row ? row.name : '';
  },
  queryStringForAllFilteredIds: (state) => (filterObj) => {
    const parmsObj = {
      CountyFlag: state.GSGo.countyFlag,
      YearId: filterObj.yearId,
      ResponseId: filterObj.responseId,
      AgeId: filterObj.ageId,
      GenderId: filterObj.genderId,
      RaceId: filterObj.raceId,
      RiskFactorId: filterObj.riskFactorId,
      RiskFactorResponseId: filterObj.riskFactorResponseId,
      DataValueTypeId: filterObj.dataValueTypeId,
    };
    const parms = _.map(parmsObj, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    return parms.join('&');
  },
  queryStringForExportToCsv: (state) => {
    const parmsObj = {
      LocationId: state.GSGo.locationId,
      DataSourceTypeId: state.GSGo.dataSourceTypeId,
      TopicId: state.GSGo.topicId,
      YearId: state.stratFilters.yearId,
      ResponseId: state.stratFilters.responseId,
      AgeId: state.stratFilters.ageId,
      GenderId: state.stratFilters.genderId,
      RaceId: state.stratFilters.raceId,
      RiskFactorId: state.stratFilters.riskFactorId,
      RiskFactorResponseId: state.stratFilters.riskFactorResponseId,
      DataValueTypeId: state.stratFilters.dataValueTypeId,
      WCMSBaseUrl: VUE_APP_WCMSBaseUrl,
    };
    const parms = _.map(parmsObj, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    return parms.join('&');
  },
  showWaitSpinner: (state, getters, rootState) =>
    rootState.flags.loadingInitialApiData ||
    rootState.flags.loadingCategoryLevelApiData ||
    state.flags.loadingIndicatorAllIds ||
    state.flags.loadingReportDataIds ||
    state.flags.generatingPdf ||
    state.flags.changingFromNationalChartOrTableToMapView,
  compareList: (state, getters, rootState) => {
    const clist = [];
    if (_(state.indicatorAllIds).map('yr').uniq().value().length > 1) {
      clist.push({ id: 'YEAR', name: 'Years' });
    }
    // if (_(state.indicatorAllIds).map('rs').uniq().value().length > 1) {
    //   clist.push({ id: 'RESPONSE', name: getters.responseLabelPlural });
    // }
    if (_(state.indicatorAllIds).map('ag').uniq().value().length > 1) {
      clist.push({ id: 'AGE', name: 'Age Groups' });
    }
    if (_(state.indicatorAllIds).map('ge').uniq().value().length > 1) {
      clist.push({ id: 'GENDER', name: 'Gender' });
    }
    if (_(state.indicatorAllIds).map('re').uniq().value().length > 1) {
      clist.push({ id: 'RACE', name: 'Race/Ethnicity' });
    }
    const rfLookups = _(state.indicatorAllIds)
      .map('rf')
      .uniq()
      .map((x) => rootState.lku.RiskFactor[x])
      .orderBy('sort', 'asc')
      .value();

    // add risk factor entry if it has 2 or more risk factor responses
    _(rfLookups).forEach((x) => {
      const rfrCount = _(state.indicatorAllIds).filter({ rf: x.id }).map('rfr').uniq().value().length;
      if (rfrCount > 1) {
        clist.push({ id: x.id, name: x.name });
      }
    });
    return helper.addSelectOne(clist, { id: '', name: 'Overall' });
  },
  compareListRF: (state, getters, rootState) => {
    const clist = [];
    const rfLookups = _(state.indicatorAllIds)
      .map('rf')
      .uniq()
      .map((x) => rootState.lku.RiskFactor[x])
      .orderBy('sort', 'asc')
      .value();
    _(rfLookups).forEach((x) => {
      const rfrCount = _(state.indicatorAllIds).filter({ rf: x.id }).map('rfr').uniq().value().length;
      if (rfrCount > 1) {
        clist.push({ id: x.id, name: x.name });
      }
    });
    return clist;
  },
  compare: (state, getters) => {
    // check to see if the compareId1 is a riskfactor
    let row = _(getters.compareListRF).find({ id: state.stratFilters.compareId });
    if (typeof row !== 'undefined') {
      return row ? row.name : '';
    }
    // check to see if the compareId2 is a riskfactor
    row = _(getters.compareListRF).find({ id: state.stratFilters.compareId2 });
    if (typeof row !== 'undefined') {
      return row ? row.name : '';
    }
    // else
    return '';
  },
  compareName: (state, getters) => {
    const row = _(getters.compareList).find({ id: state.stratFilters.compareId });
    if (state.stratFilters.compareId === 'RESPONSE') {
      return 'Indicator';
    }
    return row ? row.name : '';
  },
  compareName2: (state, getters) => {
    const row = _(getters.compareList).find({ id: state.stratFilters.compareId2 });
    return row ? row.name : '';
  },
  compareGroupCount: (state) => {
    const sf = state.stratFilters;
    if (sf.compareId === '') return 1;

    const groupColumnMapping = {
      YEAR: 'yr',
      RESPONSE: 'rs',
      AGE: 'ag',
      GENDER: 'ge',
      RACE: 're',
    };

    const compareColumn = groupColumnMapping[sf.compareId] ? groupColumnMapping[sf.compareId] : 'rfr';

    return _(state.reportDataIds).map(compareColumn).uniq().value().length;
  },
  compareViewInteractionCode: (state, getters) => (view, locationId, viewYear, compareId, compareId2) => {
    if (
      view &&
      (view.toLowerCase() === 'nationalmap' ||
        view.toLowerCase() === 'nationalcountymap' ||
        view.toLowerCase() === 'statemap')
    ) {
      return '';
    }

    const locationCode = helper.getLocationIdForOmniture(locationId);
    const compareViewYearCode = getters.compareViewYearInteractionCode(viewYear);
    const compareIdCode = getters.compareInteractionCode(compareId);
    const compareId2Code = getters.compareInteractionCode(compareId2);
    if (locationCode === 'A') {
      return `${compareViewYearCode}-${compareIdCode}-`;
    }

    if (compareId2Code === 'O') {
      return `${compareViewYearCode}-${compareIdCode}-`;
    }
    return `${compareViewYearCode}-${compareIdCode}-${compareId2Code}`;
  },
  compareInteractionCode: () => (compareId) => {
    // Note: the last 3 entries are RiskFactorId's. This code will need to be
    // updated if new risk factors are added or changed.
    const compareIdToCode = {
      YEAR: 'Y',
      RESPONSE: 'R',
      AGE: 'A',
      GENDER: 'G',
      RACE: 'E',
      RFDM: 'D',
      RFHT: 'H',
      RFSM: 'S',
    };
    if (compareId === '') return 'O';
    const code = compareIdToCode[compareId];
    return code === undefined ? '?' : code;
  },
  compareViewYearInteractionCode: () => (viewYear) => {
    if (viewYear === '1') {
      return 'O';
    }
    if (viewYear === '0') {
      return 'A';
    }
    return '';
  },
  year: (state, getters, rootState) => {
    const row = rootState.lku.Year[state.stratFilters.yearId];
    return row ? row.name : 'All Available Years';
  },
  yearInteractionCode: (state, getters, rootState) => (yearId) => {
    if (yearId === '') return '';
    return rootState.lku.Year[yearId].name.replace('-', '');
  },
  responseLabel: (state) => {
    if (state.GSGo.dataSourceTypeId === 'SURVEY') {
      return 'Response';
    }
    return 'Subgroup';
  },
  responseLabelPlural: (state) => {
    if (state.GSGo.dataSourceTypeId === 'SURVEY') {
      return 'Responses';
    }
    return 'Subgroups';
  },
  response: (state, getters, rootState) => {
    const responseId = helper.getResponseId(state.GSGo.indicatorId);
    const row = rootState.lku.Response[responseId];
    return row ? row.name : '';
    // if (state.stratFilters.responseId) {
    //   return state.GSGo.level4Name;
    // }
    // return '';
  },
  age: (state, getters, rootState) => {
    const row = rootState.lku.AgeGroup[state.stratFilters.ageId];
    return row ? row.namealias : '';
  },
  gender: (state, getters, rootState) => {
    const row = rootState.lku.Gender[state.stratFilters.genderId];
    return row ? row.namealias : '';
  },
  race: (state, getters, rootState) => {
    const row = rootState.lku.RaceEthnicity[state.stratFilters.raceId];
    return row ? row.namealias : '';
  },
  riskFactor: (state, getters, rootState) => {
    const row = rootState.lku.RiskFactor[state.stratFilters.riskFactorId];
    return row ? row.name : '';
  },
  riskFactorResponse: (state, getters, rootState) => {
    const row = rootState.lku.RiskFactorResponse[state.stratFilters.riskFactorResponseId];
    return row ? row.name : '';
  },
  riskFactorResponseAlternate: (state, getters, rootState) => {
    let row =
      rootState.lku.RiskFactorResponse[`${state.stratFilters.riskFactorId}-${state.stratFilters.riskFactorResponseId}`];
    if (row) {
      return row.namealias;
    }

    row = rootState.lku.RiskFactorResponse[state.stratFilters.riskFactorResponseId];
    return row ? row.namealias : '';
  },
  dataValueType: (state, getters, rootState) => {
    const row = rootState.lku.DataValueType[state.stratFilters.dataValueTypeId];
    return row ? row.name : '';
  },
  dataSourceViewTitle: (state, getters, rootState) => {
    const row = rootState.lku.DataSource[state.GSGo.dataSourceId];
    return row ? row.vt || '' : '';
  },
  // sampleSizeSymbol: (state) => (state.GSGo.dataSourceId === 'BRFSS' ? 'n' : 'N'),
  sampleSizeSymbol: (state) => (state.GSGo.dataSourceId === 'BRFSS' ? 'People' : 'People'),
  // use compact to remove undefined array entries when ss is missing
  reportDataIdsHasSampleSize: (state) => _(state.reportDataIds).map('ss').compact().value().length > 0,
  // todo - make these data driven:
  dataValueUnit: () => 'Percent (%)',
  dataValueUnitShort: () => '%',
  decimalPlaces: () => 2,
  mapSummary: (state, getters) =>
    `This US map gives ${getters.dataValueType} data from the ${
      state.GSGo.dataSource
    } database for the selected topic and category in ${state.mapClassifierCount} ${_.startCase(
      state.mapClassifierId,
    )}(s). For an accessible view of the data, choose display table.`,
  chartSummary: (state, getters) =>
    `This chart gives ${getters.dataValueType} data from the ${
      state.GSGo.dataSource
    } database for the selected topic and category with ${
      state.stratFilters.compareId === ''
        ? 'Overall values'
        : `${getters.compareGroupCount} bars for ${getters.compare}`
    } for each available location on the y-axis. For an accessible view of the data, choose display table.`,
  tableSummary: (state, getters) =>
    `This table gives ${getters.dataValueType} data from the ${
      state.GSGo.dataSource
    } database for the selected topic and category comparing ${
      state.stratFilters.compareId === ''
        ? 'Overall values (as columns)'
        : `${getters.compare} (as ${getters.compareGroupCount} columns)`
    } for available locations (as row subheadings) where each row subheading gives percent${
      getters.reportDataIdsHasSampleSize ? ', confidence interval and sample size' : ' and confidence interval'
    } (as rows).`,
  confidenceIntervalLabel: () => '95% CI',
  countyFlag: (state) => state.GSGo.countyFlag,
  queryStringForSelectedView: (state) => {
    const parmsObj = {
      Level1: state.GSGo.level1Name,
      Level2: state.GSGo.level2Name,
      Level3: state.GSGo.level3Name,
      Level4: state.GSGo.level4Name,
      LocationId: state.GSGo.locationId,
      DataSourceId: state.GSGo.dataSourceId,
      RiskFactorSubCatId: state.GSGo.riskFactorSubCatId,
      IndicatorId: state.GSGo.indicatorId,
      ShowFootnotes: state.flags.showFootnotes,
      View: state.view,
      CompareViewYear: state.stratFilters.compareViewYear,
      CompareId: state.stratFilters.compareId,
      CompareId2: state.stratFilters.compareId2,
      YearId: state.stratFilters.yearId,
      ResponseId: state.stratFilters.responseId,
      AgeId: state.stratFilters.ageId,
      GenderId: state.stratFilters.genderId,
      RaceId: state.stratFilters.raceId,
      RiskFactorId: state.stratFilters.riskFactorId,
      RiskFactorResponseId: state.stratFilters.riskFactorResponseId,
      DataValueTypeId: state.stratFilters.dataValueTypeId,
      MapClassifierId: state.mapClassifierId,
      MapClassifierCount: state.mapClassifierCount,
      CountyFlag: state.GSGo.countyFlag,
    };
    const parms = _.map(parmsObj, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    return parms.join('&');
  },
  viewInteractionCode: (state) => state.view.substr(0, 1),
  // NOT-IN-USE
  setBrowerUrlBarToMatchCurrentView: (state, getters, rootState) => (router) => {
    const showModeObj = {};
    if (rootState.flags.showMode.length) showModeObj.showMode = rootState.flags.showMode;

    const showDebugIdsObj = {};
    if (rootState.flags.showDebugIds.length) {
      showDebugIdsObj.showDebugIds = rootState.flags.showDebugIds;
    }
    router.replace({
      path: '/LP',
      query: {
        Level1: state.GSGo.level1Name,
        Level2: state.GSGo.level2Name,
        Level3: state.GSGo.level3Name,
        Level4: state.GSGo.level4Name,
        LocationId: state.GSGo.locationId,
        DataSourceId: state.GSGo.dataSourceId,
        GSDataSourceId: state.GSGo.gsDataSourceId,
        GSLocationId: state.GSGo.gsLocationId,
        RiskFactorSubCatId: state.GSGo.riskFactorSubCatId,
        IndicatorId: state.GSGo.indicatorId,
        ShowFootnotes: state.flags.showFootnotes,
        View: state.view,
        CompareViewYear: state.stratFilters.compareViewYear,
        CompareId: state.stratFilters.compareId,
        CompareId2: state.stratFilters.compareId2,
        YearId: state.stratFilters.yearId,
        ResponseId: state.stratFilters.responseId,
        AgeId: state.stratFilters.ageId,
        GenderId: state.stratFilters.genderId,
        RaceId: state.stratFilters.raceId,
        RiskFactorId: state.stratFilters.riskFactorId,
        RiskFactorResponseId: state.stratFilters.riskFactorResponseId,
        DataValueTypeId: state.stratFilters.dataValueTypeId,
        MapClassifierId: state.mapClassifierId,
        MapClassifierCount: state.mapClassifierCount,
        CountyFlag: state.GSGo.countyFlag,
        ...showModeObj,
        ...showDebugIdsObj,
      },
    });
  },
  csvExport: (state, getters, rootState) => {
    // console.log(state.GSGo);
    const DataSource = state.GSGo.dataSourceId;
    const Category = state.GSGo.category;
    const Topic = state.GSGo.topic;
    const { lku } = rootState;

    const questionId = helper.getQuestionId(state.GSGo.indicatorId);
    // const Question = state.indicatorId ? lku.Question[state.indicatorId].name : ''; // TODO: check
    const Question = questionId ? lku.Question[questionId].name : '';

    const dataRows = _(state.reportDataIds)
      .map((d) => {
        const year = lku.Year[d.yr].name;
        return {
          YearStart: year.substr(0, 4),
          YearEnd: year.length === 9 ? year.substr(5, 4) : year.substr(0, 4),
          LocationAbbr: lku.Location[d.loc].abbr,
          LocationDesc: lku.Location[d.loc].name,
          CountyName: d.cn,
          DataSource,
          Topic,
          Category,
          Question,
          Response: lku.Response[d.rs].name,
          Age: lku.AgeGroup[d.ag].name,
          Gender: lku.Gender[d.ge].name,
          Race_Ethnicity: lku.RaceEthnicity[d.re].name,
          Risk_Factor: lku.RiskFactor[d.rf].name,
          Risk_Factor_Repsonse: lku.RiskFactorResponse[d.rfr].name,
          Data_Value_Unit: getters.dataValueUnitShort,
          Data_Value_Type: getters.dataValueType,
          Data_Value: d.dv,
          Data_Value_Footnote_Symbol: d.ss > 0 ? d.fs : '',
          Data_Value_Footnote: d.fs ? _(rootState.footnotes).filter({ fs: d.fs, type: 'Footnote' }).value()[0].ft : '',
          Low_Confidence_Limit: d.ss > 0 ? d.lci : '',
          High_Confidence_Limit: d.ss > 0 ? d.hci : '',
          Sample_Size: d.ss,
        };
      })
      .value();

    const footnotes = _(rootState.footnotes)
      .filter(
        (f) =>
          (f.type === 'DataSource' && f.fs === state.GSGo.dataSourceId) ||
          (f.type === 'IndicatorResources1' && f.sc === state.GSGo.dataSourceId) ||
          (f.type === 'IndicatorResources2' && f.sc === state.GSGo.dataSourceId && f.qc === state.GSGo.categoryId) ||
          (f.type === 'GlobalNote' && !f.sc) ||
          (f.type === 'GlobalNote' && f.stc === 'DataSource' && f.sc === state.GSGo.dataSourceId) ||
          (f.type === 'MeasureDefinition' && f.stc === 'DataSourceType' && f.sc === state.GSGo.dataSourceTypeId) ||
          (f.type === 'MeasureDefinition' && f.stc === 'DataSource' && f.sc === state.GSGo.dataSourceId) ||
          (f.type === 'MeasureDefinition' && !f.sc) ||
          (f.type === 'RelatedLinks' && f.sc === state.GSGo.dataSourceTypeId) ||
          (f.type === 'RelatedLinks' && !f.sc) ||
          f.type === 'SuggestedCitation',
      )
      .orderBy(['type', 'sort'], ['asc', 'asc'])
      .map((f) => ({
        FootnoteText: f.type === 'SuggestedCitation' ? `${f.ft} [accessed ${moment().format('MMM D, YYYY')}]` : f.ft,
        Url: f.url ? helper.convertRelativeUrlToWCMSUrl(f.url) : '',
        FootnoteType: f.type,
      }))
      .value();

    return d3.csvFormat(dataRows.concat(footnotes));
  },
};

export default getters;
