<template>
  <div>
    <fieldset class="form-group">
      <div class="no-wrap">
        <div class="displayInline">
          <label for="gsLocation" class="col-form-label-sm">Location</label>
        </div>
        <div class="displayInline ms-1">
          <select
            id="gsLocation"
            :value="modelValue"
            class="form-select form-select-sm"
            @change="updateValue($event.target.value)"
          >
            <option v-for="loc in locations" :key="loc.id" :value="loc.id">
              {{ loc.name }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<!-- ////////////////////////////////////////////////////////////////////////////////// -->
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import helper from '../../helper';

export default {
  name: 'GlobalSelectorLocation',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      lku: (state) => state.lku,
    }),
    locations() {
      const flattened = _(this.lku.Location).reduce((lo, val) => lo.concat([val]), []);
      const result = [];
      _.forEach(flattened, (n) => {
        // remove National
        if (n.name !== 'National' && n.name !== 'national') {
          result.push(n);
        }
      });
      return helper.addNationalLocation(_(result).orderBy('sort', 'asc').value());
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
