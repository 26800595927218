import _ from 'lodash';
import * as d3 from 'd3';
import isNumber from 'is-number';

/* eslint-disable */
function makeChart (dataObj,  lku, compareStr, settingsObj, chartMountNodeIdStr, countyFlag) {
  let compareStrOverall = 'Overall' //- one bar per State
  let dataCompareColumn = settingsObj.dataCompareColumn
  let legendTitleStr = settingsObj.legendTitleStr
  let decimalPlaces = settingsObj.decimalPlaces; //2
  let chartSummary = settingsObj.chartSummary
  let sampleSizeSymbol = settingsObj.sampleSizeSymbol; //"N"
  const isCountyView = countyFlag === 'Y';

  //compareStr: AgeGroup
  //settingsObj
  //chartSummary: "This chart gives Crude Prevalence data from the Managed Vision Care database for the selected topic and category with Overall values for each available location on the y-axis. For an accessible view of the data, choose display table."
  //chartTitleStr: "Percent (%)"
  //colorsArrStr: (12)["#1f78b4", "#e31a1c", "#33a02c", "#6a3d9a", "#ff7f00", "#b15928", "#a6cee3", "#fb9a99", "#b2df8a", "#cab2d6", "#fdbf6f", "#ffff99"]
  //confidenceIntervalLabel: "95% CI"
  //dataCompareColumn: "ag"
  //decimalPlaces: 2
  //legendTitleStr: ""
  //sampleSizeSymbol: "N"

	 //console.log('dataObj: ', dataObj)
	 //console.log('lookupObj: ', lku)
	 //console.log('compareStr: ', compareStr)
	 //console.log('settingsObj: ', settingsObj)
	 //console.log('chartMountNodeIdStr: ', chartMountNodeIdStr)


  if(_){} // needs _ (lodash)

  // adding location sort and location name
  let dataObjAdded = _.forEach(dataObj, function (o) {
    o.sort = lku.Location[o.loc].sort;
    o.locName = isCountyView ? o.cn : lku.Location[o.loc].name;
  	return o;
  })

   //console.log(`dataObjAdded: ${JSON.stringify(dataObjAdded)}`);
  let dataObjSorted = []
  let colorsObj = {}
  let totalBarsArr = []
  let emptyLocArr = []
  let groupTypes = {}
  let allLocationsArr = []
  let totalBars = 0

  if (compareStr === compareStrOverall) {

    // locations to check for bars
    let allLocations = _.map(dataObjAdded, isCountyView ? 'cf' : 'loc')// if countyFlag then 'cf' else 'loc'
    allLocationsArr = _.uniq(allLocations)
    // allLocationsArr: 05,04,08,09,66,15,19,16,21,22,25,26,27,30,37,33,32,36,39,44,45,46,49,51,53,55,02,01,06,11,10,12,13,17,18,20,24,23,29,28,38,31,34,35,40,41,42,47,48,59,50,54,56
    // console.log(`allLocationsArr: ${allLocationsArr}`);

    _.forEach(allLocationsArr, function(value) {
      let thisLocationArr = _.filter(dataObjAdded, function(el){
        return isCountyView ? el.cn === value : el.loc === value
      })
      //thisLocationArr: [{"loc":"05","yr":"YR8","rs":"R3_ALL","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFALL","rfr":"RFTOT","dv":0.05,"dvu":"%","lci":0.04,"hci":0.06,"sort":6,"locName":"Arkansas"}]
      //console.log(`thisLocationArr: ${JSON.stringify(thisLocationArr)}`);

      // eliminating locations with no bars
      let emptyLocation = true
      _.forEach(thisLocationArr, function(el) {
        if (isNumber(el.dv)) {
          emptyLocation = false
        }
      })

      if (emptyLocation) { emptyLocArr.push(value) }
    })

    // removing empty state locations with no bars
    let dataObjAddedRem = _.filter(dataObjAdded, function(el) {
      return isCountyView ? !_.includes(emptyLocArr, el.cn) : !_.includes(emptyLocArr, el.loc)
    })

    // sorting by location and group
    dataObjSorted = _.sortBy(dataObjAddedRem, ['sort'])
    //dataObjSorted = _.sortBy(dataObjAdded, [function(o) { return o.sort }])

  } else { // Not Overall

    // adding type name and type sort
    groupTypes = lku[compareStr]
    // compareStr: AgeGroup
    // groupTypes: {"AGEALL":{"id":"AGEALL","name":"All Ages","sort":1},"AGE017":{"id":"AGE017","name":"0-17 years","sort":2},...}
    // console.log(`compareStr: ${compareStr}`);
    // console.log(`groupTypes: ${JSON.stringify(groupTypes)}`);
    // console.log(`groupTypes:`, groupTypes);

    //dataCompareColumn: "ag"
    //o[dataCompareColumn]: "AGE1839"
    //groupTypes[o[dataCompareColumn]].name: "18-39 years"
    //o[dataCompareColumn]: "AGE4064"
    //groupTypes[o[dataCompareColumn]].name: "40-64 years"
    dataObjAdded = _.forEach(dataObjAdded, function (o) {
      //console.log(`o[dataCompareColumn]: ${JSON.stringify(o[dataCompareColumn])}`);
       //console.log(`groupTypes[o[dataCompareColumn]].name: ${JSON.stringify(groupTypes[o[dataCompareColumn]].name)}`);
       const gt = groupTypes[o[dataCompareColumn]];
       if(gt) {
        o.sortGroup = groupTypes[o[dataCompareColumn]].sort;
        o.groupName = groupTypes[o[dataCompareColumn]].name;
       }
    	return o
    })
    //  console.log(`dataObjAdded: ${JSON.stringify(dataObjAdded)}`);

    // total number of bars, sorted by group sort order
    let allGroups = _.map(dataObjAdded, dataCompareColumn)
    // console.log(`allGroups: ${JSON.stringify(allGroups)}`);
    //allGroups: ["AGE1839","AGE6584","AGE4064","AGEALL"...]

    totalBarsArr = _.sortBy(_.uniq(allGroups), [function(o) {
      return groupTypes[o] ? groupTypes[o].sort : undefined
    }])
    totalBars = totalBarsArr.length
    // console.log(`totalBarsArr: ${JSON.stringify(totalBarsArr)}`);
    //totalBarsArr: ["AGEALL","AGE017","AGE1839","AGE4064","AGE6584","AGE85PLUS"]

    // locations to check for total bars
    //let allLocations = _.map(dataObjAdded, 'cn')
    let allLocations = _.map(dataObjAdded, isCountyView ? 'cn' : 'loc')
    allLocationsArr = _.uniq(allLocations)

    _.forEach(allLocationsArr, function(value) {

      let thisLocationArr = _.filter(dataObjAdded, function(el){
        // return el.cn === value
        return isCountyView ? el.cn === value : el.loc === value
      })

      // console.log(`thisLocationArr: ${JSON.stringify(thisLocationArr)}`);

      // eliminating locations with no bars
      let emptyLocation = true
      _.forEach(thisLocationArr, function(el) {
        if (isNumber(el.dv)) {
          emptyLocation = false
        }
      })

      if (emptyLocation) { emptyLocArr.push(value) }


      // adding missing bars to location when needed
      //console.log(`thisLocationArr: ${thisLocationArr}`)
       //console.log(`_.size(thisLocationArr: ${_.size(thisLocationArr)}`) : 5
       //console.log(`totalBars: ${totalBars}`) : 6
      if (_.size(thisLocationArr) != totalBars && _.size(thisLocationArr) > 0) {
        _.forEach(totalBarsArr, function(el) {

            let missingBar = _.find(thisLocationArr, [dataCompareColumn, el])

            if (typeof missingBar === 'undefined') {
              let mBarObj = {}
              mBarObj['loc'] = thisLocationArr[0].loc
              if(isCountyView) {
                mBarObj['cn'] = thisLocationArr[0].cn
                mBarObj['cf'] = thisLocationArr[0].cf
              }
              mBarObj[dataCompareColumn] = el
              mBarObj['locName'] = thisLocationArr[0].locName
              mBarObj['sort'] = thisLocationArr[0].sort
              mBarObj['sortGroup'] = thisLocationArr[0].sortGroup

              dataObjAdded.push(mBarObj)
            }
        })
      }// .adding missing bars to location
    })

    // console.log(`dataObjAdded: ${JSON.stringify(dataObjAdded)}`);
    // removing empty state locations with no bars
    // console.log('dataObjAdded',dataObjAdded.length, dataObjAdded)    ;
    let dataObjAddedRem = _.filter(dataObjAdded, function(el) {
      return (isCountyView) ? !_.includes(emptyLocArr, el.cn) : !_.includes(emptyLocArr, el.loc)
    })
    // console.log('dataObjAddedRem',dataObjAddedRem.length,dataObjAddedRem );

    // sorting by location and group
    dataObjSorted = _.sortBy(dataObjAddedRem, isCountyView ? ['cf', 'sort', 'sortGroup'] : ['sort', 'sortGroup'])

    // extending colors if not enough
    while (settingsObj.colorsArrStr.length < totalBarsArr.length) {
      settingsObj.colorsArrStr.push(settingsObj.colorsArrStr[0])
    }

    // console.log('totalBarsArr',totalBarsArr);
    // making bars color object
    for (let i = 0; i < totalBarsArr.length; i++) {
      colorsObj[totalBarsArr[i]] = settingsObj.colorsArrStr[i]
    }
  } // Not Overall

  // get chart node for width and clear out
  let chartMaxWidth = 800
  if (compareStr === compareStrOverall) { chartMaxWidth = 1200 }
  let chartMountNode = document.getElementById(chartMountNodeIdStr)
  let tentvSvgWidth = (chartMountNode.clientWidth || 375)

  if (tentvSvgWidth > chartMaxWidth) {
    tentvSvgWidth = chartMaxWidth
  } else if (tentvSvgWidth < 375) {
    tentvSvgWidth = 375
  }

	// settings
	let barMargin = 12
  let stateBarMargin = 25

  if(compareStr !== compareStrOverall) { barMargin = 0 }
  if(compareStr === compareStrOverall) { stateBarMargin = barMargin }

	let barThickness = 18

	let paddingTextToChart = 15
  let spaceLeftForText = 250 + paddingTextToChart;
	let fontSize = 14

	let intervalLineColor = 'Black'
	let intervalStrokeWidth = 1

	let spaceAtTop = 70
	let axisColor = '#CCC'

  // calculated width settings
  let barSvgWidth = tentvSvgWidth - spaceLeftForText - 3 * fontSize
   //console.log(`barSvgWidth: ${barSvgWidth}`);

  let svgChartWidth = tentvSvgWidth


	let maxHci = d3.max(dataObj, function(d){ return parseFloat(d.hci) })
	let maxDv = d3.max(dataObj, function(d){ return parseFloat(d.dv) })
	let maxHoriz = Math.max(maxHci, maxDv)

   //console.log(`maxHoriz: ${maxHoriz}`);

  let scaleExtend = 1 - maxHoriz % 1
  if (maxHoriz < 1) { scaleExtend = 0.1 }

  let _maxForGridLines = 15 // maximum for maxHoriz val after grid lines are at 5 interval
  if (maxHoriz + 1 > _maxForGridLines) { scaleExtend = 5 - maxHoriz % 5 }

  //console.log(`scaleExtend: ${scaleExtend}`);

	var xScale = d3.scaleLinear()
									.domain([0, maxHoriz + scaleExtend])
									.range([0, barSvgWidth])

   //console.log(`dataObjSorted: ${JSON.stringify(dataObjSorted)}`);
	let svgChartHeight = spaceAtTop + dataObjSorted.length * (barMargin + barThickness) + barMargin + (allLocationsArr.length - emptyLocArr.length) * stateBarMargin
  if(compareStr === compareStrOverall) {
    svgChartHeight = spaceAtTop + dataObjSorted.length * (barMargin + barThickness) + barMargin
  }


  // clear out the chart div for a new chart
  while(chartMountNode.firstChild) {
    chartMountNode.removeChild(chartMountNode.firstChild)
  }

	// title
	// let chartTitle = settingsObj.chartTitleStr || ''
	// d3.select('#' + chartMountNodeIdStr)
	// 	.append('h4')
	// 	.text(chartTitle)
	// 	.style('text-align', 'center')
  //   .style('width', svgChartWidth + 'px')


  // legend
  let legendWidth = 250
  if (compareStr !== compareStrOverall) {

    d3.select('#' + chartMountNodeIdStr)
      .append('div')
      .attr('id', 'legend')
      .style('width', legendWidth + 'px')
      .html(getLegendStr())
  }

  function getLegendStr() {

    let legendStr = '<div id="legendTitle">' + legendTitleStr + '</div>'

    _.forEach(totalBarsArr, function(groupId, index) {
      legendStr += '<div class="legendLine"><div class="legendSquare"><svg width="20" height="15"><rect style="fill:' + colorsObj[totalBarsArr[index]] + ';" width="15" height="15"/></svg></div>'
       legendStr += '<div class="legendSquareText">' + groupTypes && groupTypes[groupId] ? groupTypes[groupId].name : '' + '</div></div>'
    })
    return legendStr
  }


	// chart
	let svgChart = d3.select('#' + chartMountNodeIdStr)
									.append('svg')
									.attr('width', svgChartWidth)
									.attr('height', svgChartHeight)
                  .attr('id', 'svgChart')

  // chart summary
  svgChart.append('desc')
          .text(chartSummary)

  // chart tiltle
  svgChart.append('text')
		      .text(settingsObj.chartTitleStr || '')
		      //.attr('font-family', 'Lato')
		      .attr('text-anchor', 'middle')
		      .attr('font-size', '14px')
          .attr('font-weight', 'bold')
	        .attr('x', spaceLeftForText - paddingTextToChart + (svgChartWidth - spaceLeftForText) / 2)
		      .attr('y', 25)


	let barTooltip = d3.select('#' + chartMountNodeIdStr)
											.append('div')
											.style('opacity', 0)
											.attr('id', 'barTooltip')


  // grid vertical lines
  let maxForGridLines = _maxForGridLines
  let minForGridLines = 1

  let gridAdjust = 1
  if (maxHoriz + 1 > maxForGridLines) { gridAdjust = 5 }

	let gridArr = [], i = 0

  if (maxHoriz < minForGridLines) {  // microscale
    gridAdjust = 0.1

    while (i < maxHoriz + gridAdjust) {
      gridArr.push(i)
      i = i + gridAdjust
    }

  } else {
    while (i < maxHoriz + gridAdjust) {
      gridArr.push(i)
      i++
    }
  }


	svgChart.selectAll('vertGridLines')
		.data(gridArr)
		.enter()
		.append('line')
		.attr('x1', function(data, index) {
      if (maxHoriz + 1 < maxForGridLines) {
        return xScale(data) + spaceLeftForText
      }
      if (index % 5 === 0) { return xScale(data) + spaceLeftForText }
			})
		.attr('x2', function(data, index) {
      if (maxHoriz + 1 < maxForGridLines) {
        return xScale(data) + spaceLeftForText
      }
      if (index % 5 === 0) { return xScale(data) + spaceLeftForText }
			})
			.attr('y1', function(data, index) {
        if (maxHoriz + 1 < maxForGridLines) {

          if ( compareStr !== compareStrOverall ) { return spaceAtTop - stateBarMargin/2 }
          return spaceAtTop + stateBarMargin/2
        }
        if (index % 5 === 0) { return spaceAtTop - stateBarMargin/2 }
      })
			.attr('y2', function(data, index) {
        if (maxHoriz + 1 < maxForGridLines) {
          return svgChartHeight - stateBarMargin/2
        }
        if (index % 5 === 0) { return svgChartHeight - stateBarMargin/2 }
      })
			// .attr('stroke-dasharray', function(data, index) {
			// 	if (index !== 0) return '3, 10'
			// })
			.attr('stroke-width', '1')
			.attr('stroke', axisColor)

	svgChart.selectAll('vertGridLinesText')
		.data(gridArr)
		.enter()
		.append('text')
		.text(function(data, index) {
      if (maxHoriz < minForGridLines) { return index/10 } // microscale
      if (maxHoriz + 1 < maxForGridLines) {
        return index
      }
      if (index % 5 === 0) { return index }
		})
		//.attr('font-family', 'Lato')
		.attr('text-anchor', 'center')
		.attr('font-size', fontSize)
		.attr('x', function(data, index){
      if (maxHoriz < minForGridLines) { return spaceLeftForText + xScale(data) - fontSize/3} // microscale
			return spaceLeftForText + xScale(index) - fontSize/3
		})
		.attr('y', spaceAtTop - fontSize - stateBarMargin / 2)


   // console.log('dataObjSorted',dataObjSorted);
	// bars
	svgChart.selectAll('rect')
		.data(dataObjSorted)
		.enter()
		.append('rect')
		.attr('x', spaceLeftForText)
		.attr('y', function(data, index){
      if ( compareStr !== compareStrOverall ) {
        return spaceAtTop + (barThickness + barMargin) * index + barMargin + stateBarMargin * Math.floor(index / totalBarsArr.length)
      }
			return spaceAtTop + (barThickness + barMargin) * index + barMargin
		})
		.attr('width', function(data, index) {
      if(typeof data.dv !== 'undefined') {
			  return xScale(data.dv)
      }
		})
		.attr('height', function(data, index) {
      if (typeof data.dv !== 'undefined') {
        return barThickness
      }
    })
		.attr('fill', function(data, index) {

      if (compareStr === compareStrOverall) { return settingsObj.colorsArrStr[0] }

      return colorsObj[data[dataCompareColumn]]

    })
		.on('mouseover', function(event, d) {
			barTooltip.transition()
									.duration(450)
									.style('opacity', 1)
			barTooltip.html(getTooltipStr(d))
        .style('left', (event.pageX + 15) + 'px')
        .style('top', (event.pageY - 20) + 'px')
		})
		.on('mouseout', function(event, d) {
			barTooltip.transition()
									.duration(250)
									.style('opacity', 0)
		})


    // interval bars
		let intervalLines = svgChart.selectAll('intervalBarsG')
												.data(dataObjSorted)
												.enter()
												.append('g')
		intervalLines
				.append('line')
				.attr('x1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            return xScale(data.lci) + spaceLeftForText
          }
				})
				.attr('y1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
            return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2
          }
				})
				.attr('x2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            return xScale(data.hci) + spaceLeftForText
          }
				})
				.attr('y2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
            return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2
          }
				})
				.attr('stroke', intervalLineColor)
				.attr('stroke-width', intervalStrokeWidth)

		intervalLines // interval left line
				.append('line')
				.attr('x1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
					  return xScale(data.lci) + spaceLeftForText
          }
				})
				.attr('y1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 - barThickness/8 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
					  return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 - barThickness/8
          }
				})
				.attr('x2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
					  return xScale(data.lci) + spaceLeftForText
          }
				})
				.attr('y2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + barThickness/8 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
					  return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + barThickness/8
          }
				})
				.attr('stroke', intervalLineColor)
				.attr('stroke-width', intervalStrokeWidth)

			intervalLines // interval right line
				.append('line')
				.attr('x1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
					  return xScale(data.hci) + spaceLeftForText
          }
				})
				.attr('y1', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 - barThickness/8 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
					  return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 - barThickness/8
          }
				})
				.attr('x2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
					  return xScale(data.hci) + spaceLeftForText
          }
				})
				.attr('y2', function(data, index) {
          if(typeof data.lci !== 'undefined' && typeof data.hci !== 'undefined') {
            if ( compareStr !== compareStrOverall ) {
              return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + barThickness/8 + stateBarMargin * Math.floor(index / totalBarsArr.length)
            }
					  return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + barThickness/8
          }
				})
				.attr('stroke', intervalLineColor)
				.attr('stroke-width', intervalStrokeWidth)

			intervalLines
				.on('mouseover', function(event, d) {
					//console.log(d)
					barTooltip.transition()
											.duration(450)
											.style('opacity', 1)
					barTooltip.html(getTooltipStr(d))
            .style('left', (event.pageX + 15) + 'px')
            .style('top', (event.pageY - 20) + 'px')
				})
				.on('mouseout', function(event, d) {
					// console.log('out')
					barTooltip.transition()
											.duration(250)
											.style('opacity', 0)
				})

  function getTooltipStr (d) {
    const groupName = compareStr !== compareStrOverall ? '<br>' + d.groupName : ''

    let tooltipStr = '<strong>' + d.locName + groupName + '<br>' + Number(d.dv).toFixed(decimalPlaces) + d.dvu + '</strong>'

    if (isNumber(d.lci) && isNumber(d.hci)) {
      tooltipStr += '<br>' + settingsObj.confidenceIntervalLabel + ' (' + Number(d.lci).toFixed(decimalPlaces)
      + ' - ' + Number(d.hci).toFixed(decimalPlaces) + ')'
    }

    if (isNumber(d.ss)) {
      tooltipStr += '<br>' + sampleSizeSymbol + ' = ' + Number(d.ss).toLocaleString()
    }

    return tooltipStr
  }

    // y axis horiz tick lines
    let tickLineLen = 8
		svgChart.selectAll('horizTickLines')
			.data(dataObjSorted)
			.enter()
			.append('line')
			.attr('x1', function(data, index) {
        if ( data[dataCompareColumn] === totalBarsArr[0] ) {
          return spaceLeftForText - tickLineLen
        }
      })
			.attr('x2', function(data, index) {
        if ( data[dataCompareColumn] === totalBarsArr[0] ) {
          return spaceLeftForText
        }
      })
			.attr('y1', function(data, index) {
          if ( data[dataCompareColumn] === totalBarsArr[0] ) {
            if ( compareStr !== compareStrOverall ) {
              // if (index === 0) {
              //   return spaceAtTop + barMargin * index + barThickness  * index + barMargin/2 + stateBarMargin * Math.floor(index / totalBarsArr.length) - (stateBarMargin / 2)
              // }
              return spaceAtTop + barMargin * index + barThickness  * index + barMargin/2 + stateBarMargin * Math.floor(index / totalBarsArr.length) - (stateBarMargin / 2)
            }
            return spaceAtTop + barMargin * index + barThickness  * index + barMargin/2
          }
				})
			.attr('y2', function(data, index) {
          if ( data[dataCompareColumn] === totalBarsArr[0] ) {
            if ( compareStr !== compareStrOverall ) {
              // if (index === 0) {
              //   return spaceAtTop + barMargin * index + barThickness  * index + barMargin/2 + stateBarMargin * Math.floor(index / totalBarsArr.length) - (stateBarMargin / 2)
              // }
              return spaceAtTop + barMargin * index + barThickness  * index + barMargin/2 + stateBarMargin * Math.floor(index / totalBarsArr.length) - (stateBarMargin / 2)
            }
            return spaceAtTop + barMargin * index + barThickness * index + barMargin/2
          }
				})
			.attr('stroke-width', '1')
			.attr('stroke', axisColor)
		svgChart.append('line') // adding last tick at bottom of chart
					.attr('x1', spaceLeftForText - tickLineLen)
					.attr('x2', spaceLeftForText)
					.attr('y1', function(data, index) {
							return  svgChartHeight - stateBarMargin/2
						})
					.attr('y2', function(data, index) {
							return  svgChartHeight - stateBarMargin/2
						})
					.attr('stroke-width', '1')
					.attr('stroke', axisColor);

  // state text
  var prevName = '';
	svgChart.selectAll('locationText')
		.data(dataObjSorted)
		.enter()
		.append('text')
		.text(function(data, i) {
      if(isCountyView) {
        if ( ( compareStr != compareStrOverall ) && ((i+1) % Math.floor(totalBarsArr.length/2) == 0) && prevName !== data.locName ) {
          prevName = data.locName;
          return data.locName;
        }
      }
      else {
        if ( ( compareStr != compareStrOverall ) && ( data[dataCompareColumn] === totalBarsArr[Math.floor(totalBarsArr.length/2)] ) ) {
          return data.locName;
        }
      }

      if ( compareStr === compareStrOverall) { return data.locName }
		})
		//.attr('font-family', 'Lato')
		.attr('text-anchor', 'end')
		.attr('font-size', fontSize)
		.attr('x', spaceLeftForText - paddingTextToChart)
		.attr('y', function(data, index) {
      if ( compareStr !== compareStrOverall ) {
        return spaceAtTop + barMargin * (index + 1) + barThickness * index + barThickness/2 + stateBarMargin * Math.floor(index / totalBarsArr.length)
      }
				return spaceAtTop + barMargin * (index+1) + barThickness * index + barThickness/2 + fontSize/3
		 })
}

export default makeChart;
