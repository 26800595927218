import _ from 'lodash';
import isNumber from 'is-number';

const addItem = (arr, item) => {
  if (isNumber(item)) {
    return _.union(arr, [item]);
  }
  return arr;
};

export default function getUSDataAndMappableLocationCountFromReportDataIds(
  lku,
  reportDataIds,
  countyFlag,
  locationId,
) {
  // console.log('in getUSDataAndMappableLocationCountFromReportDataIds', reportDataIds);
  const results = { MappableLocationCount: 0, FootnoteSymbols: [] };
  const locations = lku.Location;
  const locationIds = {
    US: '59',
    XX: '99',
    State: '-1',
  };

  if (countyFlag === 'Y') {
    locationIds.State = locationId;
  }

  _(reportDataIds).forEach((row) => {
    const locationRow = locations[row.loc];
    switch (row.loc) {
      case locationIds.US:
        results.USDataValue = row.dv;
        results.USDescription = locationRow.name;
        results.USHighCI = row.hci;
        results.USLowCI = row.lci;
        results.USSampleSize = row.ss;
        results.USFootnoteSymbol = row.fs;
        results.FootnoteSymbols = addItem(results.FootnoteSymbols, row.fs);
        break;
      case locationIds.XX:
        results.XXDataValue = row.dv;
        results.XXDescription = locationRow.name;
        results.XXHighCI = row.hci;
        results.XXLowCI = row.lci;
        results.XXSampleSize = row.ss;
        results.XXFootnoteSymbol = row.fs;
        results.FootnoteSymbols = addItem(results.FootnoteSymbols, row.fs);
        break;
      case locationIds.State:
        if (locationRow.name === row.cn) {
          results.USDataValue = row.dv;
          results.USDescription = locationRow.name;
          results.USHighCI = row.hci;
          results.USLowCI = row.lci;
          results.USSampleSize = row.ss;
          results.USFootnoteSymbol = row.fs;
          results.FootnoteSymbols = addItem(results.FootnoteSymbols, row.fs);
        }
        break;
      default:
        if (isNumber(row.dv)) {
          results.MappableLocationCount += 1;
        }
        results.FootnoteSymbols = addItem(results.FootnoteSymbols, row.fs);
    }
  });
  // console.log('getUSDataAndMappableLocationCountFromReportDataIds returns', results);
  return results;
}
