<template>
  <div id="divDashboardHeader" class="container-fluid">
    <div class="d-flex" v-if="!printerFriendly">
      <div class="me-auto">
        <div class="d-flex smallerText pt-1">
          <div>
            <span class="dataSourceText">Data Source Information:&nbsp;</span>
            <a :href="dataSourceInfoURL" target="blank">
              {{ GSGo.dataSource }}
            </a>
          </div>
          <div class="ms-2">
            <span class="dataSourceText">Category Definition:&nbsp;</span>
            <a :href="categoryDefinitionURL" target="blank">
              {{ level3Name }}
            </a>
          </div>
        </div>
      </div>
      <div class="linkheader" v-if="!printerFriendly">
        <link-bucket />
      </div>
    </div>
    <!-- for PDF -->
    <div v-if="printerFriendly" class="pdfHeader">
      <div class="pdfHeaderRow">
        <div class="pdfHeaderCol pdfHeaderCol1">
          <div class="pdfTitle">Vision & Eye Health Surveillance System</div>
          <div>
            <div class="pdfStateIcon">
              <img id="stateIcon" :src="getStateIcon" :alt="this.locationName" />
            </div>
            <div class="pdfSubTitle">
              Indicator: {{ GSGo.level4Name }}
              <br />
              Data Source: {{ GSGo.dataSource }}
            </div>
          </div>
        </div>
        <div class="pdfHeaderCol pdfHeaderCol2">
          <img class="pdfLogo" src="../../assets/Shared.Images.hhs-cdc-logo.png" alt="CDC logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- /////////////////////////// SCRIPT //////////////////////////////// -->
<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import LinkBucket from './LinkBucket.vue';
import helper from '../../helper';

export default {
  components: {
    LinkBucket,
  },
  computed: {
    ...mapState({
      GSGo: (state) => state.LP.GSGo,
      locationId: (state) => state.LP.GSGo.locationId,
      locationName: (state) => (state.LP.GSGo.locationId === '' ? '' : state.LP.GSGo.location),
      nationalId: (state) => state.LP.nationalId,
      footnotes: (state) => state.footnotes,
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      lku: (state) => state.lku,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
      level3Name: (state) => state.LP.GSGo.level3Name,
    }),
    ...mapGetters(['printerFriendly', 'dataSourceTypeName', 'dataSourceName', 'topicName', 'categoryName', 'response']),
    dataSourceInfoURL() {
      const row = _(this.footnotes).find({ type: 'IndicatorResources1', sc: this.dataSourceId });
      if (row) {
        return helper.convertRelativeUrlToWCMSUrl(row.url);
      }
      return process.env.VUE_APP_MAIN_DataSourceTypeInfoUrl;
    },
    categoryDefinitionURL() {
      const qId = helper.getQuestionId(this.indicatorId);
      if (qId !== '') {
        const row = _(this.footnotes).find({ type: 'IndicatorResources2', qc: qId, sc: this.dataSourceId });
        if (row) {
          return helper.convertRelativeUrlToWCMSUrl(row.url);
        }
      }
      return process.env.VUE_APP_MAIN_DataSourceCategoryInfoUrl;
    },
    responseLabel() {
      const dsRow = this.lku.DataSource[this.dataSourceId];
      if (dsRow) {
        const dataSourceTypeId = dsRow.namealias; // used namealias for dataSourceTypeId from query
        return dataSourceTypeId === 'SURVEY' ? 'Response: ' : '';
      }
      return '';
    },
    getStateIcon() {
      try {
        /* eslint-disable global-require */
        switch (this.locationId) {
          case '':
            return '';
          case '59':
            return require('../../assets/StateImage/Shared.Images.Blue.US.png');
          case '01':
            return require('../../assets/StateImage/Shared.Images.Blue.01.png');
          case '02':
            return require('../../assets/StateImage/Shared.Images.Blue.02.png');
          case '04':
            return require('../../assets/StateImage/Shared.Images.Blue.04.png');
          case '05':
            return require('../../assets/StateImage/Shared.Images.Blue.05.png');
          case '06':
            return require('../../assets/StateImage/Shared.Images.Blue.06.png');
          case '08':
            return require('../../assets/StateImage/Shared.Images.Blue.08.png');
          case '09':
            return require('../../assets/StateImage/Shared.Images.Blue.09.png');
          case '10':
            return require('../../assets/StateImage/Shared.Images.Blue.10.png');
          case '11':
            return require('../../assets/StateImage/Shared.Images.Blue.11.png');
          case '12':
            return require('../../assets/StateImage/Shared.Images.Blue.12.png');
          case '13':
            return require('../../assets/StateImage/Shared.Images.Blue.13.png');
          case '15':
            return require('../../assets/StateImage/Shared.Images.Blue.15.png');
          case '16':
            return require('../../assets/StateImage/Shared.Images.Blue.16.png');
          case '17':
            return require('../../assets/StateImage/Shared.Images.Blue.17.png');
          case '18':
            return require('../../assets/StateImage/Shared.Images.Blue.18.png');
          case '19':
            return require('../../assets/StateImage/Shared.Images.Blue.19.png');
          case '20':
            return require('../../assets/StateImage/Shared.Images.Blue.20.png');
          case '21':
            return require('../../assets/StateImage/Shared.Images.Blue.21.png');
          case '22':
            return require('../../assets/StateImage/Shared.Images.Blue.22.png');
          case '23':
            return require('../../assets/StateImage/Shared.Images.Blue.23.png');
          case '24':
            return require('../../assets/StateImage/Shared.Images.Blue.24.png');
          case '25':
            return require('../../assets/StateImage/Shared.Images.Blue.25.png');
          case '26':
            return require('../../assets/StateImage/Shared.Images.Blue.26.png');
          case '27':
            return require('../../assets/StateImage/Shared.Images.Blue.27.png');
          case '28':
            return require('../../assets/StateImage/Shared.Images.Blue.28.png');
          case '29':
            return require('../../assets/StateImage/Shared.Images.Blue.29.png');
          case '30':
            return require('../../assets/StateImage/Shared.Images.Blue.30.png');
          case '31':
            return require('../../assets/StateImage/Shared.Images.Blue.31.png');
          case '32':
            return require('../../assets/StateImage/Shared.Images.Blue.32.png');
          case '33':
            return require('../../assets/StateImage/Shared.Images.Blue.33.png');
          case '34':
            return require('../../assets/StateImage/Shared.Images.Blue.34.png');
          case '35':
            return require('../../assets/StateImage/Shared.Images.Blue.35.png');
          case '36':
            return require('../../assets/StateImage/Shared.Images.Blue.36.png');
          case '37':
            return require('../../assets/StateImage/Shared.Images.Blue.37.png');
          case '38':
            return require('../../assets/StateImage/Shared.Images.Blue.38.png');
          case '39':
            return require('../../assets/StateImage/Shared.Images.Blue.39.png');
          case '40':
            return require('../../assets/StateImage/Shared.Images.Blue.40.png');
          case '41':
            return require('../../assets/StateImage/Shared.Images.Blue.41.png');
          case '42':
            return require('../../assets/StateImage/Shared.Images.Blue.42.png');
          case '44':
            return require('../../assets/StateImage/Shared.Images.Blue.44.png');
          case '45':
            return require('../../assets/StateImage/Shared.Images.Blue.45.png');
          case '46':
            return require('../../assets/StateImage/Shared.Images.Blue.46.png');
          case '47':
            return require('../../assets/StateImage/Shared.Images.Blue.47.png');
          case '48':
            return require('../../assets/StateImage/Shared.Images.Blue.48.png');
          case '49':
            return require('../../assets/StateImage/Shared.Images.Blue.49.png');
          case '50':
            return require('../../assets/StateImage/Shared.Images.Blue.50.png');
          case '51':
            return require('../../assets/StateImage/Shared.Images.Blue.51.png');
          case '53':
            return require('../../assets/StateImage/Shared.Images.Blue.53.png');
          case '54':
            return require('../../assets/StateImage/Shared.Images.Blue.54.png');
          case '55':
            return require('../../assets/StateImage/Shared.Images.Blue.55.png');
          case '56':
            return require('../../assets/StateImage/Shared.Images.Blue.56.png');
          case '60':
            return require('../../assets/StateImage/Shared.Images.Blue.60.png');
          case '66':
            return require('../../assets/StateImage/Shared.Images.Blue.66.png');
          case '68':
            return require('../../assets/StateImage/Shared.Images.Blue.68.png');
          case '69':
            return require('../../assets/StateImage/Shared.Images.Blue.69.png');
          case '70':
            return require('../../assets/StateImage/Shared.Images.Blue.70.png');
          case '72':
            return require('../../assets/StateImage/Shared.Images.Blue.72.png');
          default:
            return require('../../assets/StateImage/Shared.Images.Blue.US.png');
        }
        /* eslint-enable global-require */
      } catch {
        return '';
      }
    },
  },
};
</script>
<style scoped>
.dataSourceText {
  font-weight: 600;
}
.linkheader {
  z-index: 1;
}
.pdfHeader {
  display: table;
  width: 100%;
  margin-bottom: 20px;
  font-family: sans-serif, 'Open Sans', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial;
}

.pdfHeaderRow {
  display: table-row;
}

.pdfHeaderCol {
  display: table-cell;
}

.pdfHeaderCol1 {
  width: 75%;
  vertical-align: top;
}

.pdfHeaderCol2 {
  width: 25%;
  text-align: right;
  vertical-align: top;
}

.pdfLogo {
  /*margin-top: 8px*/
}

.pdfStateIcon {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-left: 5px;
}
.pdfTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.pdfSubTitle {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
