<template>
  <div class="container-fluid site-title d-flex align-items-center">
    <div class="d-inline">
      <a :href="HomeUrl">
        <i class="fa fa-home homeIcon" />
        <span class="visually-hidden">Home</span>
      </a>
    </div>
    <div class="d-inline display-6 text-white fw-500 pb-1 pt-2 pt-md-3">
      <a :href="HomeUrl">
        <h1>Vision and Eye Health Surveillance System (VEHSS)</h1>
      </a>
    </div>
  </div>
</template>

<script>
import { VUE_APP_HomeUrl } from '../Env';

export default {
  data() {
    return {
      HomeUrl: VUE_APP_HomeUrl,
    };
  },
};

</script>

<style scoped>
  h1 {
    display: inline !important;
    font-size: 1.4rem !important;
    line-height: 1.1 !important;
    font-weight: 500!important;
  }
  .homeIcon {
    font-size: 37px;
    text-decoration: none !important;
  }
 .display-6 {
    margin-left: 0.3rem;
    padding-top: 0.55rem!important;
 }

 .site-title {
     background-color:#005eaa;
 }
</style>
