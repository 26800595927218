<template>
  <div style="display: flex">
    <div id="divMapLegend">
      <div id="divLegendTitle">
        {{ dataValueUnit }}
      </div>
      <div id="divLegendDetails">
        <div class="legend-items-container" v-for="item in legendBreaks" v-bind:key="item.index">
          <div class="legend-color-box" :style="{ 'background-color': item.color }">&nbsp;</div>
          <span class="range">{{ item.desc }}</span>
        </div>
      </div>
      <div id="divLegendFooter">
        <div id="divLegendClassificationMethod" class="tc">
          <span class="b">{{ mapClassifier }}</span>
          <div v-if="!printerFriendly" id="divLegendSettings">
            <a ref="legendSettingsLink" href="#" @click.prevent="showLegendModel()">
              {{ labels.legendSettings }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <template>
      <Teleport to="body">
        <Transition name="modal">
          <div v-if="showModal" class="modal">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modalHeader">{{ labels.legendSettings }}</div>
                <div>
                  <input type="button" value="" class="visually-hidden" ref="cancelButton1" />
                </div>
                <div class="legendSettingSelectors">
                  <label for="islClassifierType" class="rdThemeInputCaption">
                    {{ labels.classificationType }}
                  </label>
                  <select
                    id="islClassifierType"
                    ref="islClassifierType"
                    v-model="mapClassifierIdLoc"
                    class="rdThemeInput"
                    @keydown.shift.tab="setFocusToCancelButton"
                  >
                    <option v-for="item in mapClassifiers" :key="item.id" :value="item.id">
                      {{ item.description }}
                    </option>
                  </select>
                </div>
                <div class="legendSettingSelectors">
                  <label for="islClassifierCount" class="rdThemeInputCaption">
                    {{ labels.numberOfClasses }}
                  </label>
                  <select id="islClassifierCount" v-model="mapClassifierCountLoc" class="rdThemeInput">
                    <option v-for="item in mapClassifierCounts" :key="item.id" :value="item">{{ item }}</option>
                  </select>
                </div>
                <div class="modalButtons">
                  <input type="button" class="btn btn-sm btn-blue-p" value="OK" @click="okClicked" />
                  <input
                    type="button"
                    class="btn btn-sm btn-blue-p"
                    value="Cancel"
                    ref="cancelButton"
                    @keydown.tab="tabKeyOnCancelButton"
                    @click="cancelClicked"
                  />
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </Teleport>
    </template>
  </div>
</template>

<script>
/* global triggerOmnitureInteractions */
import _ from 'lodash';
import constants from './constants';

export default {
  name: 'MapLegends',
  props: {
    legendBreaks: {
      value: [],
      default: [],
    },
    classifierType: {
      value: '',
      default: '',
    },
    classesCount: {
      value: 0,
      default: 0,
    },
    printerFriendly: {
      value: true,
      default: true,
    },
    dataValueUnit: {
      value: '%',
      default: '%',
    },
  },
  data() {
    return {
      mapClassifiers: constants.classifiers.types,
      mapClassifierCounts: constants.classifiers.classes,
      mapClassifierIdLoc: this.classifierType,
      mapClassifierCountLoc: this.classesCount,
      labels: {
        numberOfClasses: 'Number of classes',
        classificationType: 'Classification type',
        legendSettings: 'Legend Settings',
      },
      showModal: false,
    };
  },
  computed: {
    mapClassifier() {
      const row = _(constants.classifiers.types).find({ id: this.classifierType });
      return row ? row.description : '';
    },
    legendSettingsCSV() {
      return `${this.classifierType},${this.classesCount}`;
    },
  },
  watch: {
    showModal() {
      if (this.showModal) {
        const self = this;
        this.$nextTick().then(() => {
          self.$refs.islClassifierType.focus();
        });
      } else {
        this.$refs.legendSettingsLink.focus();
      }
    },
  },
  created() {
    this.mapClassifierIdLoc = this.classifierType;
    this.mapClassifierCountLoc = this.classesCount;
  },
  methods: {
    showLegendModel() {
      this.showModal = true;
      // this.$refs.islClassifierType.focus();
    },
    closeLegendModel() {
      this.showModal = false;
      // this.$refs.legendSettingsLink.focus();
    },
    // showModal() {
    //   this.$modal.show('legend-settings');
    // },
    // hideModal() {
    //   this.$modal.hide('legend-settings');
    // },
    okClicked() {
      this.$emit('ok', { classificationMethod: this.mapClassifierIdLoc, classesCount: this.mapClassifierCountLoc });
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} -LEGEND, ${this.mapClassifierIdLoc},${this.mapClassifierCountLoc}`,
      );
      this.closeLegendModel();
    },
    cancelClicked() {
      this.$emit('cancel');
      this.closeLegendModel();
    },
    // modalOpened() {
    //   this.$refs.islClassifierType.focus();
    // },
    // modalClosed() {
    //   this.$refs.legendSettingsLink.focus();
    // },
    setFocusToCancelButton() {
      this.$nextTick(() => {
        this.$refs.cancelButton.focus();
      });
    },
    tabKeyOnCancelButton(event) {
      if (!event.shiftKey) {
        this.$nextTick(() => {
          // this.$refs.islClassifierType.focus();
          this.$refs.cancelButton1.focus();
        });
      }
    },
  },
};
</script>

<style scoped>
#divMapLegend {
  width: 100%;
  border: 1px solid #c0c0c0;
  float: right;
  position: relative;
  margin-right: 1%;
  margin-top: 5%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#divLegendTitle {
  text-align: center;
  font-weight: bold;
  padding: 4px 10px;
}

.tc {
  text-align: center;
}

.legendSettingSelectors label {
  display: block;
  font-weight: bold;
}

.legendSettingSelectors {
  padding: 10px 40px;
}

.modalButtons {
  text-align: center;
  padding: 10px 0;
}

.modalButtons button {
  width: 80px;
  margin: 0 5px;
  height: 37px;
  font-weight: bold;
}

/* Legend Position Style */
.legend {
  position: absolute;
  left: 800px;
  top: 350px;
}

div.legend-color-box {
  fill-opacity: 1;
  border: 1.35px solid #9a9a9a;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

#divLegendDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
}

div.legend-items-container {
  display: flex;
  padding: 2px 5px;
  align-items: center;
}

span.range {
  font-size: 13px;
  font-family: sans-serif;
  white-space: nowrap;
}

div.modalHeader {
  background-color: #075290;
  color: white;
  font-size: 1.2em !important;
  width: 100% !important;
  height: 40px;
}
/*------- modal --------------------------*/
.modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 230px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
