const constants = {
  colorRamp: {
    noDataColor: 'rgb(204, 204, 204)',
    defaultColorScheme: 'Blues',
  },
  classifiers: {
    types: [
      { description: 'Quantile', id: 'quantile' },
      { description: 'Equal Interval', id: 'equal-interval' },
      { description: 'Natural Breaks', id: 'natural-breaks' },
    ],
    classes: [4, 5, 6, 7],
  },
  labels: {
    dataUnavailable: 'Data unavailable',
  },
};

export default constants;
