/* eslint no-shadow: ["error", {"allow": ["getters"]}] */
const getters = {
  getDefaultFromQueryStringParmIfInitialPageLoad:
    (state, getters, rootState) => (defaultValue, queryStringKey) => {
      // console.log('getDefaultFromQueryStringParmIfInitialPageLoad initialPageLoad: %s, ' +
      //   'defaultValue: %s, queryStringKey: %s, qsVal: %s',
      //   rootState.flags.initialPageLoad, defaultValue,
      //   queryStringKey, state.query[queryStringKey]);
      if (rootState.flags.initialPageLoad) {
        return state.query[queryStringKey] || defaultValue;
      }
      return defaultValue;
    },
};

export default {
  getters,
  // namespaced: true,
};
