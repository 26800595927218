<template>
  <div id="divView" class="divView">
    <!-- National -->
    <div class="flex-box" v-if="showCountryButtons">
      <div>
        <span>National Results: </span>
        <button
          id="btnNationalChartTable"
          type="button"
          class="btn btn-default btn-sm btn-custom"
          :class="{ active: view === 'NationalChartTable' }"
          @click="btnClicked('btnNationalChartTable')"
        >
          <img src="./assets/chartIcon.svg" alt="chartIcon" />&nbsp;
          <img src="./assets/tableIcon.svg" alt="tableIcon" />&nbsp;
          <span>Chart / Table</span>
        </button>
      </div>
      <div class="ps-4">
        <span>By State: </span>
        <div class="btn-group btn-group-sm" role="group" aria-label="By State">
          <button
            id="btnNationalMap"
            type="button"
            v-if="showNationalMap"
            class="btn btn-default"
            :class="{ active: view === 'NationalMap' }"
            @click="btnClicked('btnNationalMap')"
          >
            <img src="./assets/mapIcon-blue.svg" alt="mapIcon" />&nbsp;
            <span class="btn-txt-sm">Map</span>
          </button>
          <button
            id="btnNationalChart"
            type="button"
            class="btn btn-default"
            :class="{ active: view === 'NationalChart' }"
            @click="btnClicked('btnNationalChart')"
          >
            <img src="./assets/chartIcon.svg" alt="chartIcon" />&nbsp;
            <span class="btn-txt-sm">Chart</span>
          </button>
          <button
            id="btnNationalTable"
            type="button"
            class="btn btn-default"
            :class="{ active: view === 'NationalTable' }"
            @click="btnClicked('btnNationalTable')"
          >
            <img src="./assets/tableIcon.svg" alt="tableIcon" />&nbsp;
            <span class="btn-txt-sm">Table</span>
          </button>
        </div>
      </div>
      <div class="ps-4" v-if="showNationalMap && showCountryCountyMapIcon">
        <span>By County: </span>
        <button
          id="btnNationalCountyMap"
          type="button"
          class="btn btn-default btn-sm btn-custom"
          :class="{ active: view === 'NationalCountyMap' }"
          @click="btnClicked('btnNationalCountyMap')"
        >
          <img src="./assets/countyIcon.svg" alt="countyIcon" />&nbsp;
          <span>Map</span>
        </button>
      </div>
    </div>
    <!-- One State -->
    <div class="flex-box" v-if="showStateButtons">
      <div>
        <span>By State: </span>
        <button
          id="btnStateChartTable"
          type="button"
          class="btn btn-default btn-sm btn-custom"
          :class="{ active: view === 'StateChartTable' }"
          @click="btnClicked('btnStateChartTable')"
        >
          <img src="./assets/chartIcon.svg" alt="chartIcon" />&nbsp;
          <img src="./assets/tableIcon.svg" alt="tableIcon" />&nbsp;
          <span>Chart / Table</span>
        </button>
      </div>
      <div class="ps-4">
        <span>By County: </span>
        <div class="btn-group btn-group-sm">
          <button
            id="btnStateMap"
            type="button"
            v-if="showStateMap"
            class="btn btn-default"
            :class="{ active: view === 'StateMap' }"
            @click="btnClicked('btnStateMap')"
          >
            <img src="./assets/mapIcon-blue.svg" alt="mapIcon" />&nbsp;
            <span class="btn-txt-sm">Map</span>
          </button>
          <button
            id="btnStateChart"
            type="button"
            class="btn btn-default"
            :class="{ active: view === 'StateChart' }"
            @click="btnClicked('btnStateChart')"
          >
            <img src="./assets/chartIcon.svg" alt="chartIcon" />&nbsp;
            <span class="btn-txt-sm">Chart</span>
          </button>
          <button
            id="btnStateTable"
            type="button"
            class="btn btn-default"
            :class="{ active: view === 'StateTable' }"
            @click="btnClicked('btnStateTable')"
          >
            <img src="./assets/tableIcon.svg" alt="tableIcon" />&nbsp;
            <span class="btn-txt-sm">Table</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global triggerOmnitureInteractions */
import { mapState, mapGetters } from 'vuex';
import helper from '../../helper';

export default {
  name: 'ViewButtons',
  // Note: button icons images are mananged as webpack module dependencies.
  // See http://vuejs-templates.github.io/webpack/static.html
  // for more info.
  data() {
    return {};
  },
  // /////////////////////////// COMPUTED ////////////////////////////////
  computed: {
    ...mapState({
      view: (state) => state.LP.view,
      compareId: (state) => state.LP.stratFilters.compareId,
      compareId2: (state) => state.LP.stratFilters.compareId2,
      GSGo: (state) => state.LP.GSGo,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
      stratFilters: (state) => state.LP.stratFilters,
      hideNationalMapIcon: (state) => state.LP.flags.hideNationalMapIcon,
      hideSateMapIcon: (state) => state.LP.flags.hideStateMapIcon,
      locationId: (state) => state.LP.GSGo.locationId,
      showCountryCountyMapIcon: (state) => state.LP.flags.showCountryCountyMapIcon,
      nationalId: (state) => state.LP.nationalId,
      hideCountryButtons: (state) => state.LP.flags.hideCountryButtons,
    }),
    ...mapGetters(['yearInteractionCode', 'compareViewInteractionCode', 'countyFlag']),

    showCountryButtons() {
      if ((this.locationId === '' || this.locationId === this.nationalId) && !this.hideCountryButtons) {
        return true;
      }
      return false;
    },
    showStateButtons() {
      if (this.locationId !== '' && this.locationId !== this.nationalId) {
        return true;
      }
      return false;
    },
    showNationalMap() {
      const responseId = helper.getResponseId(this.indicatorId);
      if (responseId && !this.hideNationalMapIcon) {
        return true;
      }
      return false;
    },
    showStateMap() {
      const responseId = helper.getResponseId(this.indicatorId);
      if (responseId) {
        return true;
      }
      return false;
    },
  },
  // ////////////////////////// METHOD ////////////////////////////
  methods: {
    btnClicked(btnId) {
      let newView = this.view;
      switch (btnId) {
        case 'btnNationalChartTable':
          newView = 'NationalChartTable';
          break;
        case 'btnNationalMap':
          newView = 'NationalMap';
          break;
        case 'btnNationalChart':
          newView = 'NationalChart';
          break;
        case 'btnNationalTable':
          newView = 'NationalTable';
          break;
        case 'btnNationalCountyMap':
          newView = 'NationalCountyMap';
          break;
        case 'btnStateChartTable':
          newView = 'StateChartTable';
          break;
        case 'btnStateMap':
          newView = 'StateMap';
          break;
        case 'btnStateChart':
          newView = 'StateChart';
          break;
        case 'btnStateTable':
          newView = 'StateTable';
          break;
        default:
          throw new Error(`Unexpected icon id: ${btnId}`);
      }
      //--------------------------------------------------------------------------
      if (newView !== this.view) {
        //-------------------------------------------
        if (newView === 'NationalChartTable') {
          this.$store.commit('setChangingToNationalChartTableView', true);
          // GlobalSelectorStrat -> gsLocationId watch is fired since location changed from '' to 59
          // -> call this.ProcessLocation();
        } else if (
          (newView === 'NationalMap' || newView === 'NationalCountyMap') &&
          this.view === 'NationalChartTable'
        ) {
          this.$store.commit('setChangingFromNationalChartTableToMapView', true);
          // StratFilters: reset compareIds
          // GlobalSelectorStrat -> gsLocationId watch is fired since location changed from 59 to ''
          // -> call this.ProcessLocation();
        } else if ((newView === 'NationalChart' || newView === 'NationalTable') && this.view === 'NationalChartTable') {
          this.$store.commit('setChangingFromNationalChartTableToChartOrTableView', true);
          // StratFilters: reset compareIds
          // GlobalSelectorStrat -> gsLocationId watch is fired since location changed from 59 to ''
          // -> call this.ProcessLocation();
        } else if (
          (newView === 'NationalChart' || newView === 'NationalTable') &&
          (this.view === 'NationalMap' || this.view === 'NationalCountyMap')
        ) {
          this.$store.commit('setChangingFromNationalMapToChartOrTableView', true);
          // StratFilters: reset compareIds
          // StratFilters: call this.applyFilters_Click();
        } else if (
          (newView === 'NationalMap' || newView === 'NationalCountyMap') &&
          (this.view === 'NationalChart' || this.view === 'NationalTable') &&
          this.compareId !== ''
        ) {
          this.$store.commit('setChangingFromNationalChartOrTableToMapView', true);
          // StratFilters: reset compareIds and call this.applyFilters_Click();
        } else if (newView === 'StateChartTable') {
          this.$store.commit('setChangingToStateChartTableView', true);
          // GlobalSelectorStrat -> changingToStateChartTableView watch
          // -> call this.ProcessDataSource();
          // set viewCountyMap = 'N'
        } else if (newView === 'StateMap' && this.view === 'StateChartTable') {
          this.$store.commit('setChangingFromStateChartTableToMapView', true);
          // StratFilters: reset compareIds
          // GlobalSelectorStrat -> changingFromStateChartTableToMapView fired to reset viewCountyMap
          // -> call this.ProcessLocation();
        } else if ((newView === 'StateChart' || newView === 'StateTable') && this.view === 'StateChartTable') {
          this.$store.commit('setChangingFromStateChartTableToChartOrTableView', true);
          // StratFilters: reset compareIds
          // GlobalSelectorStrat -> changingFromStateChartTableToChartOrTableView fired to reset viewCountyMap
          // -> call this.ProcessLocation();
        } else if ((newView === 'StateChart' || newView === 'StateTable') && this.view === 'StateMap') {
          this.$store.commit('setChangingFromStateMapToChartOrTableView', true);
          // StratFilters: reset compareIds
          // StratFilters: call this.applyFilters_Click();
        } else if (
          newView === 'StateMap' &&
          (this.view === 'StateChart' || this.view === 'StateTable') &&
          this.compareId !== ''
        ) {
          this.$store.commit('setChangingFromStateChartOrTableToMapView', true);
          // StratFilters: reset compareIds and call this.applyFilters_Click();
        } else {
          // const responseId = helper.getResponseId(this.indicatorId);
          // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-IV ${helper.getViewForOmniture(newView)},${helper.getLocationIdForOmniture(this.GSGo.locationId)},${this.GSGo.dataSourceId},${this.getCountyIdForOmniture()},${this.indicatorId},${this.compareViewInteractionCode(newView, this.GSGo.locationId, this.stratFilters.compareViewYear, this.stratFilters.compareId, this.stratFilters.compareId2)},${this.yearInteractionCode(this.stratFilters.yearId)},${responseId},${this.stratFilters.ageId},${this.stratFilters.genderId},${this.stratFilters.raceId},${this.stratFilters.riskFactorId},${this.stratFilters.riskFactorResponseId},${this.stratFilters.dataValueTypeId}`);
        }
        //--------------------------------------------
        this.$store.commit('setView', newView);
        this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
        triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier} - VIEW, ${newView}`);
      }
    },
    getCountyIdForOmniture() {
      let countyValue = 'False';
      if (this.GSGo) {
        countyValue = this.GSGo.countyFlag === 'Y' ? 'True' : 'False';
      }
      return countyValue;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1700px) {
  .divView {
    margin: 15px 10px;
  }
}
.divView a {
  display: inline-block;
  height: 29px;
}
.flex-box {
  display: -ms-flexbox !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
  height: 29px;
}
.btn {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgba(121, 121, 121, 1) !important;
  /* padding: 1px 5px 1px 5px !important; */
}
.btn-group > .btn:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.btn-group > .btn:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.btn-custom {
  border-radius: 10px !important;
}
</style>
