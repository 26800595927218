<template>
  <div id="divMapViewContainer" class="mv4">
    <div v-if="apiData.MappableLocationCount === 0 && apiData.USDescription && countyFlag !== 'Y'" class="tc">
      Only {{ apiData.USDescription }} data available for your selections.
    </div>
    <div v-if="apiData.USDescription" class="desc-block">
      <div class="b">
        {{ apiData.USDescription }}:
        <span v-html="USValueUnit" />
      </div>
      <div v-if="USShowCI">
        {{ USConfidenceInterval }}
      </div>
      <div v-if="USShowSampleSize">
        {{ USSampleSize }}
      </div>
    </div>
    <!-- <map-view v-if="apiData.MappableLocationCount > 0 && countyFlag !== 'Y'" /> -->
    <NationalMapView v-if="view === 'NationalMap' && countyFlag !== 'Y' && reportDataIds && reportDataIds.length > 0" />
    <StateMapView v-if="view === 'StateMap' && countyFlag === 'Y' && reportDataIds" />
    <CountryCountyMapView
      v-if="view === 'NationalCountyMap' && countryCountyReportDataIds && countryCountyReportDataIds.length > 0"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isNumber from 'is-number';
import numeral from 'numeral';
// import MapView from './MapView.vue';
import NationalMapView from './NationalMap/NationalMapView.vue';
import StateMapView from './StateMap/StateMapView.vue';
import CountryCountyMapView from './CountryCountyMap/CountryCountyMapView.vue';
import getUSDataAndMappableLocationCountFromReportDataIds from './MapViewContainerUtil';

export default {
  name: 'MapViewContainer',
  components: {
    NationalMapView,
    StateMapView,
    CountryCountyMapView,
  },
  data() {
    return {
      apiData: {},
    };
  },
  computed: {
    ...mapState({
      reportDataIds: (state) => state.LP.reportDataIds,
      lku: (state) => state.lku,
      locationId: (state) => state.LP.GSGo.locationId,
      view: (state) => state.LP.view,
      countryCountyReportDataIds: (state) => state.LP.countryCountyReportDataIds,
    }),
    ...mapGetters([
      'indicator',
      'dataValueType',
      'dataValueUnit',
      'dataValueUnitShort',
      'decimalPlaces',
      'mapSummary',
      'printerFriendly',
      'sampleSizeSymbol',
      'countyFlag',
    ]),
    USValueUnit() {
      if (isNumber(this.apiData.USDataValue)) {
        return `${Number(this.apiData.USDataValue).toFixed(this.decimalPlaces)}${this.dataValueUnitShort}${
          this.USFootnoteSymbol
        }`;
      }
      return this.USFootnoteSymbol;
    },
    USConfidenceInterval() {
      return `95% CI (${Number(this.apiData.USLowCI).toFixed(this.decimalPlaces)} - ${Number(
        this.apiData.USHighCI,
      ).toFixed(this.decimalPlaces)})`;
    },
    USShowCI() {
      return isNumber(this.apiData.USDataValue) && isNumber(this.apiData.USLowCI) && isNumber(this.apiData.USHighCI);
    },
    USSampleSize() {
      return `${this.sampleSizeSymbol} = ${numeral(this.apiData.USSampleSize).format('0,0')}`;
    },
    USShowSampleSize() {
      return isNumber(this.apiData.USDataValue) && isNumber(this.apiData.USSampleSize);
    },
    USFootnoteSymbol() {
      if (isNumber(this.apiData.USDataValue)) {
        if (this.apiData.USFootnoteSymbol) {
          return `<sup>${this.apiData.USFootnoteSymbol}</sup>`;
        }
        return '';
      }
      return this.apiData.USFootnoteSymbol;
    },
  },
  watch: {
    reportDataIds: {
      handler() {
        this.getUSDataAndMappableLocationCount();
      },
      // run handler immediately after the start of observation
      // so apiData is loaded when switching from table/chart to map
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getUSDataAndMappableLocationCount() {
      this.apiData = getUSDataAndMappableLocationCountFromReportDataIds(
        this.lku,
        this.reportDataIds,
        this.countyFlag,
        this.locationId,
      );
    },
  },
};
</script>

<style scoped>
.b {
  font-weight: bold;
}

.desc-block {
  text-align: center;
  line-height: 18px;
}

.tc {
  text-align: center;
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
