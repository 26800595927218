<template>
  <div>
    <div id="chartDiv" />
    <div v-if="!dataHasAtLeastOneDataValue">
      <p class="noData">
        No data to display in chart
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isNumber from 'is-number';
import _ from 'lodash';
import makeChart from './chart';
import makeChartBar from './ChartBar';
import makeChartGroupBar from './ChartGroupBar';
import makeChartLine from './ChartLine';
import makeChartGroupLine from './ChartGroupLine';

function hasAtLeastOneDataValue(reportDataIds) {
  const firstRowWithDataValue = _(reportDataIds).find((row) => isNumber(row.dv));
  return firstRowWithDataValue !== undefined;
}

export default {
  computed: {
    ...mapState({
      reportDataIdsOrg: (state) => state.LP.reportDataIds,
      compareId: (state) => state.LP.stratFilters.compareId,
      lku: (state) => state.lku,
      compareId2: (state) => state.LP.stratFilters.compareId2,
      locationIdOrg: (state) => state.LP.GSGo.locationId,
      location: (state) => state.LP.GSGo.location,
      countyFlag: (state) => state.LP.GSGo.countyFlag,
      view: (state) => state.LP.view,
      nationalId: (state) => state.LP.nationalId,
    }),
    ...mapGetters([
      'compareName',
      'compareName2',
      'dataValueUnit',
      'decimalPlaces',
      'confidenceIntervalLabel',
      'chartSummary',
      'sampleSizeSymbol',
      'dataValueType',
      'dataSourceName',
    ]),
    dataHasAtLeastOneDataValue() {
      return hasAtLeastOneDataValue(this.reportDataIdsOrg);
    },
    reportDataIds() {
      if (this.view === 'NationalChartTable') {
        return _(this.reportDataIdsOrg).filter({ loc: this.nationalId }).value();
      }
      return this.reportDataIdsOrg;
    },
    locationId() {
      if (this.view === 'NationalChartTable') {
        return this.nationalId;
      }
      return this.locationIdOrg;
    },
  },
  watch: {
    reportDataIds() {
      this.makeChart();
    },
  },
  mounted() {
    this.makeChart();
  },
  methods: {
    makeChart() {
      if (this.dataHasAtLeastOneDataValue) {
        const groupMapping = {
          None: '',
          Overall: 'Overall',
          YEAR: 'Year',
          RESPONSE: 'Response',
          AGE: 'AgeGroup',
          GENDER: 'Gender',
          RACE: 'RaceEthnicity',
          RiskFactorResponse: 'RiskFactorResponse',
        };
        const groupColumnMapping = {
          None: '',
          Overall: '',
          YEAR: 'yr',
          RESPONSE: 'rs',
          AGE: 'ag',
          GENDER: 'ge',
          RACE: 're',
          RiskFactorResponse: 'rfr',
        };
        // ----- compareId -------
        let compareIdFix = this.compareId;
        if (compareIdFix === '') {
          compareIdFix = 'Overall';
        } else if (compareIdFix.substring(0, 2) === 'RF') {
          compareIdFix = 'RiskFactorResponse';
        }
        const compareStr = groupMapping[compareIdFix];

        // ----- compareId2 -------
        let compareIdFix2 = this.compareId2;
        if (compareIdFix2 === '' && compareIdFix === 'YEAR') {
          compareIdFix2 = 'Overall';
        } else if (compareIdFix2 === '' && compareIdFix !== 'YEAR') {
          compareIdFix2 = 'None';
        } else if (compareIdFix2.substring(0, 2) === 'RF') {
          compareIdFix2 = 'RiskFactorResponse';
        }
        const compareStr2 = groupMapping[compareIdFix2];

        // -------- settingsObj ----------
        const settingsObj = {
          colorsArrStr: ['#1f78b4', '#e31a1c', '#33a02c', '#6a3d9a', '#ff7f00', '#b15928', '#a6cee3', '#fb9a99', '#b2df8a', '#cab2d6', '#fdbf6f', '#ffff99'],
          colorsArrStrHover: ['#2592da', '#ea484b', '#41c837', '#7349b6', '#ff9933', '#d0672f', '#c5dfed', '#fcb7b6', '#cae9af', '#ddcde5', '#fdd39b', '#ffffcc'],
          chartTitleStr: 'Percent (%)',
          dataCompareColumn: groupColumnMapping[compareIdFix],
          dataCompareColumn2: groupColumnMapping[compareIdFix2],
          legendTitleStr: this.compareName,
          legendTitleStr2: this.compareName2,
          decimalPlaces: this.decimalPlaces,
          confidenceIntervalLabel: this.confidenceIntervalLabel,
          chartSummary: this.chartSummary,
          sampleSizeSymbol: this.sampleSizeSymbol,
          maxTicks: 8,
          txttAngleRotate: -45,
        };
        // use slice to pass a copy of the reportDataIds array to prevent
        // the makeChart function from mutating the original array
        // which causes vuex errors: Error: [vuex] Do not mutate vuex store state outside mutation handlers.
        // console.log('ChartView');
        // console.log('this.locationId', this.locationId);
        // console.log('this.countyFlag', this.countyFlag);
        // console.log('compareStr', compareStr);
        // console.log('compareStr2', compareStr2);
        if (this.locationId && this.countyFlag !== 'Y') {
          if (compareStr2 === '') {
            settingsObj.chartSummary = this.createSummary('chartBar', compareStr);
            makeChartBar(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', this.locationId);
          } else if (compareStr === 'Year' && compareStr2 === 'Overall') {
            if (this.getNumberOfYear() > 2) {
              settingsObj.chartSummary = this.createSummary('charLine');
              makeChartLine(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', this.locationId);
            } else {
              settingsObj.chartSummary = this.createSummary('chartBar');
              makeChartBar(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', this.locationId);
            }
          } else if (compareStr === 'Year' && compareStr2 !== 'Overall') {
            if (this.getNumberOfYear() > 2) {
              settingsObj.chartSummary = this.createSummary('chartGrouLine');
              makeChartGroupLine(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', compareStr2, this.locationId);
            } else {
              settingsObj.chartSummary = this.createSummary('chartGroupBar');
              makeChartGroupBar(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', compareStr2, this.locationId);
            }
          } else {
            settingsObj.chartSummary = this.createSummary('chartGroupBar');
            makeChartGroupBar(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', compareStr2, this.locationId);
          }
        } else if (this.getNumberOfYear() > 2 && this.countyFlag !== 'Y') {
          settingsObj.chartSummary = this.createSummary('chartGrouLine');
          makeChartGroupLine(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', compareStr2, this.locationId);
        } else {
          // makeChart(this.reportDataIds.slice(), this.lku, compareStr, settingsObj, 'chartDiv', this.countyFlag);
          makeChart(_.cloneDeep(this.reportDataIds), this.lku, compareStr, settingsObj, 'chartDiv', this.countyFlag);
        }
      } else {
        document.getElementById('chartDiv').innerHTML = '';
      }
    },
    getNumberOfYear() {
      const oneLocData = this.reportDataIds.filter((item) => item.loc === this.locationId);
      const years = _.uniq(_.map(oneLocData, 'yr')); // put _. in front will return array. Use map or uniqe inside will return NOT valid array
      return years.length;
    },
    createSummary(viewType, compareStr) {
      switch (viewType) {
        case 'chartBar':
          if (compareStr === 'Overall') {
            return `This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with Overall values for ${this.location} on the x-axis.`;
          }
          return `This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with ${this.compareName} for ${this.location} on the x-axis.`;
        case 'chartGroupBar':
          return `This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with [TotalBar] bars for ${this.compareName2} for each ${this.compareName} on the x-axis.`;
        case 'charLine':
          return `This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with Overall values for ${this.location} across all available years on the x-axis.`;
        case 'chartGrouLine':
          return `This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with [TotalBar] lines for ${this.compareName2} across all available years.`;
        default:
          return '';
      }
    },
  },
};

</script>

<style>
  div#chartDiv {
    max-width: 1200px;
    margin: 10px auto;
  }

  div#chartDiv h4 {
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  div#barTooltip {
    position: absolute;
    max-width: 250px;
    height: auto;
    padding: 10px;
    background-color: #EEE;
    border-radius: 2px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.45);
    pointer-events: none;
    font-size: 12px;
    line-height: 150%;
  }

  div#chartDiv svg {
    overflow: hidden;
  }

  div#barTooltip {
    position: absolute;
    max-width: 250px;
    height: auto;
    padding: 10px;
    background-color: #EEE;
    border-radius: 2px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.45);
    pointer-events: none;
    font-size: 12px;
    line-height: 150%;
    text-align: left;
  }

  div#legend {
    font-size: 14px;
    padding: 6px;
    float: right;
    box-sizing: border-box;
    text-align: left;
  }

  div.legendLine {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  }

  div#legendTitle {
    padding-bottom: 3px;
    font-weight: bold;
    font-size: 14px;
  }

  div.legendLine>.legendSquare {
    width: 10%;
    float: left;
    box-sizing: border-box;
    padding-top: 1px;
  }

  div.legendLine>.legendSquareText {
    width: 90%;
    float: left;
    box-sizing: border-box;
  }

  .noData {
    text-align: center;
    font-weight: bold;
  }

  div#chartDiv h4 {
    font-size: large;
    font-weight: bold;
  }

  /* NEW */
  /*--- grid line */
  .grid path {
    stroke-width: 0px; /*this get rid of the left grid line */
  }

  .grid .tick line {
    stroke: #CCC;
  }

  /*--- y axis */
  .yAxis line {
    stroke-width: 0px !important;
  }

  .yAxis path {
    stroke-width: 0px !important;
  }

  .yAxis text {
    font-size: 14px;
  }

  /*--- x axis */
  .xAxis text {
    font-size: 14px;
    font-weight: normal;
  }

  /*--- hiloLine */
  .hiloLine  {
    stroke: #000;
    stroke-width: 1;
  }

  .hiloLineThick {
    stroke: #000;
    stroke-width: 10;
  }

  /*--- chart label; */
  .chartLabel {
    font-size: 14px;
    font-weight: bold;
  }

.legendTitle {
    padding-bottom: 3px;
    font-weight: bold;
    font-size: 14px;
  }

  .legendLabel {
    font-size: 14px;
  }

  .invisibleCircle {
    stroke: transparent;
    stroke-width: 10px;
  }

  .invisibleCircleNone {
    stroke: transparent;
    stroke-width: 0px;
  }

  .point {
    stroke: transparent;
    stroke-width: 10px;
  }

  .pointShadow {
    stroke-opacity: 0.3;
    stroke-width: 10px;
    z-index: 1;
  }

</style>
