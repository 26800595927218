<template>
  <div>
    <fieldset class="form-group">
      <template v-if="!compareAll && items.length > 1">
        <label :for="id" class="col-form-label-sm">{{ label }}</label>
        <select
          :id="id"
          :value="modelValue"
          class="form-select form-select-sm"
          @change="updateValue($event.target.value)"
        >
          <option
            v-for="item in items"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </template>
      <div
        v-if="compareAll || items.length === 1"
        class="col-form-label-sm"
      >
        {{ label }}
      </div>
      <span v-if="!compareAll && items.length === 1">
        {{ items[0].name }}
      </span>
      <span v-if="compareAll">
        Compare All {{ compareAllLabel }}
      </span>
      <span
        v-if="showDebugIds"
        class="debugIds"
      >
        {{ modelValue }}{{ itemIndex }}:{{ items.length }}
      </span>
    </fieldset>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'StratFilter',
  props: {
    compareAll: {
      type: Boolean,
      default: false,
    },
    compareAllLabel: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showDebugIds: {
      type: String,
      default: '',
    },
  },
  computed: {
    itemIndex() {
      return _.findIndex(this.items, { id: this.modelValue }) + 1;
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      // this.$emit('input', value);
      this.$emit('update:modelValue', value);
    },
  },
};

</script>
