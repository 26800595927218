<template>
  <div v-if="!printerFriendly">
    <!-- Global Selector Strat -->
    <div>
      <GlobalSelectorStrat />
    </div>
    <div class="compareSeperator" />
    <!-- Compare -->
    <div v-if="showCompare" class="compareContainer bgBlue">
      <fieldset>
        <legend class="fs-legend">Compare</legend>
        <div>
          <fieldset class="form-group" v-show="showCompare1">
            <legend id="legendOneLocation" class="visually-hidden">View year</legend>
            <div v-show="years.length <= 1">
              <span class="form-check-label view-year-label"> View one year </span>
            </div>
            <div class="form-check" v-show="years.length > 1">
              <input
                id="fmRdoOneYear"
                type="radio"
                name="legendRadio"
                value="1"
                class="form-check-input"
                v-model="CompareGO.compareViewYear"
              />
              <label for="fmRdoOneYear" class="form-check-label view-year-label"> View one year </label>
            </div>
            <div class="form-check" v-show="years.length > 1">
              <input
                id="fmRdoAllYear"
                type="radio"
                name="legendRadio"
                value="0"
                class="form-check-input"
                v-model="CompareGO.compareViewYear"
              />
              <label for="fmRdoAllYear" class="form-check-label view-year-label">View all available years </label>
            </div>
          </fieldset>
          <fieldset class="form-group" v-show="showCompare1">
            <label for="islCompareValue1" class="col-form-label-sm">
              Compare variable <span v-show="locationId !== ''">1</span>
            </label>
            <select
              id="islCompareValue1"
              v-model="CompareGO.compareId"
              class="form-select form-select-sm"
              :disabled="CompareGO.compareViewYear === '0'"
            >
              <option v-for="item in compareList1" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <span v-if="showDebugIds" class="debugIds"> {{ compareId }} count: {{ compareList1.length }} </span>
          </fieldset>
          <fieldset class="form-group" v-show="showCompare2">
            <label for="islCompareValue2" class="col-form-label-sm"> Compare variable 2 </label>
            <select
              id="islCompareValue2"
              v-model="CompareGO.compareId2"
              class="form-select form-select-sm"
              :disabled="CompareGO.compareId === ''"
            >
              <option v-for="item in compareList2" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <span v-if="showDebugIds" class="debugIds"> {{ compareId }} count: {{ compareList2.length }} </span>
          </fieldset>
          <fieldset class="form-group">
            <div id="divCompareBtn">
              <input type="button" value="Compare" class="btn btn-sm btn-blue-p" @click="compare_Click" />
            </div>
          </fieldset>
        </div>
      </fieldset>
    </div>
    <div v-if="showCompare" class="compareSeperator" />
    <!-- Filter -->
    <div class="stratContainer">
      <fieldset>
        <legend class="fs-legend">Filters</legend>
        <!-- Buttons -->
        <fieldset class="form-group">
          <div class="d-flex flex-wrap">
            <div id="divApplyFilter" class="me-auto">
              <input
                type="button"
                value="Apply Filters"
                class="btn btn-sm btn-blue-p"
                @click="applyFilters_Click('AF')"
              />
            </div>
            <div>
              <a href="#" @click.prevent="resetFilters"> Reset </a>
            </div>
          </div>
        </fieldset>
        <!-- Year -->
        <strat-filter
          id="islYear"
          v-model="yearId"
          :compare-all="compareId === 'YEAR'"
          :items="years"
          :show-debug-ids="showDebugIds"
          label="Year"
        />
        <!-- Age -->
        <strat-filter
          id="islAge"
          v-model="ageId"
          :compare-all="compareId === 'AGE' || compareId2 === 'AGE'"
          :items="ages"
          :show-debug-ids="showDebugIds"
          label="Age Group"
        />
        <!-- Gender -->
        <strat-filter
          id="islGender"
          v-model="genderId"
          :compare-all="compareId === 'GENDER' || compareId2 === 'GENDER'"
          :items="genders"
          :show-debug-ids="showDebugIds"
          label="Gender"
        />
        <!-- Race -->
        <strat-filter
          id="islRace"
          v-model="raceId"
          :compare-all="compareId === 'RACE' || compareId2 === 'RACE'"
          :items="races"
          :show-debug-ids="showDebugIds"
          label="Race/Ethnicity"
        />
        <!-- Risk Factor -->
        <strat-filter
          id="islRiskFactor"
          v-model="riskFactorId"
          :compare-all="compareId.substring(0, 2) === 'RF' || compareId2.substring(0, 2) === 'RF'"
          :items="riskFactors"
          :compare-all-label="compare"
          :show-debug-ids="showDebugIds"
          label="Risk Factor"
        />
        <!-- Risk factor response -->
        <strat-filter
          v-if="riskFactorResponseId"
          id="islRiskFactorResponse"
          v-model="riskFactorResponseId"
          :compare-all="false"
          :items="riskFactorResponses"
          :show-debug-ids="showDebugIds"
          label="Risk factor response"
        />

        <!-- Data Type -->
        <strat-filter
          id="islDVT"
          v-model="dataValueTypeId"
          :compare-all="false"
          :items="dataValueTypes"
          :show-debug-ids="showDebugIds"
          label="Data Type"
        />
        <!-- Buttons -->
        <fieldset class="form-group">
          <div class="d-flex flex-wrap">
            <div id="divApplyFilter1" class="me-auto">
              <input
                type="button"
                value="Apply Filters"
                class="btn btn-sm btn-blue-p"
                @click="applyFilters_Click('AF')"
              />
            </div>
            <div>
              <a href="#" @click.prevent="resetFilters"> Reset </a>
            </div>
          </div>
        </fieldset>
        <!-- Debug -->
        <template v-if="showDebugIds">
          <div>
            <a :href="`?${queryStringForSelectedView}`"> Link to this view </a>
            <a :href="`?${queryStringForSelectedView}&showDebugIds=xx`"> Debug view </a>
          </div>
          <div>
            <a :href="`?${queryStringForSelectedView}&showMode=export`"> Printer friendly view </a>
          </div>
        </template>
      </fieldset>
    </div>
  </div>
</template>

<script>
/* global triggerOmnitureInteractions */
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import StratFilter from './StratFilter.vue';
import GlobalSelectorStrat from './GlobalSelectorStrat.vue';
import helper from '../../helper';

export default {
  components: {
    StratFilter,
    GlobalSelectorStrat,
  },
  data() {
    return {
      viewFromUrl: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'View'),
      compareId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'CompareId'),
      compareId2: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'CompareId2'),
      yearId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'YearId'),
      ageId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'AgeId'),
      genderId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'GenderId'),
      raceId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'RaceId'),
      riskFactorId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'RiskFactorId'),
      riskFactorResponseId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'RiskFactorResponseId'),
      dataValueTypeId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'DataValueTypeId'),
      // set useCurrentIdIfValid true before loading dropdowns to set the dropdown's state to the
      // current value if the value is valid after the load
      // set to false to force the dropdown state to be the first item in
      // the dropdown after it loads
      // the last dropdown's load will reset this back to true
      useCurrentIdIfValid: true,
      suppressSecondInteractionAfterResetFilters: false,
      dataChangingToCompareForSpecificRiskFactor: false,
      changedFilters: [],
      // new
      CompareGO: {
        compareViewYear: this.getDefaultFromQueryStringParmIfInitialPageLoad('1', 'CompareViewYear'),
        compareId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'CompareId'),
        compareId2: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'CompareId2'),
      },
    };
  },
  computed: {
    ...mapState({
      indicatorAllIds: (state) => state.LP.indicatorAllIds,
      showDebugIds: (state) => state.flags.showDebugIds,
      GSGo: (state) => state.LP.GSGo,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
      showFootnotes: (state) => state.LP.flags.showFootnotes,
      view: (state) => state.LP.view,
      mapClassifierId: (state) => state.LP.mapClassifierId,
      mapClassifierCount: (state) => state.LP.mapClassifierCount,
      initialPageLoad: (state) => state.flags.initialPageLoad,
      indicatorChanged: (state) => state.LP.flags.indicatorChanged,
      changingFromNationalChartTableToMapView: (state) => state.LP.flags.changingFromNationalChartTableToMapView,
      // prettier-ignore
      changingFromNationalChartTableToChartOrTableView: (state) => state.LP.flags.changingFromNationalChartTableToChartOrTableView,
      changingFromNationalChartOrTableToMapView: (state) => state.LP.flags.changingFromNationalChartOrTableToMapView,
      changingFromStateChartTableToMapView: (state) => state.LP.flags.changingFromStateChartTableToMapView,
      // prettier-ignore
      changingFromStateChartTableToChartOrTableView: (state) => state.LP.flags.changingFromStateChartTableToChartOrTableView,
      changingFromStateChartOrTableToMapView: (state) => state.LP.flags.changingFromStateChartOrTableToMapView,
      changingFromNationalMapToChartOrTableView: (state) => state.LP.flags.changingFromNationalMapToChartOrTableView,
      changingFromStateMapToChartOrTableView: (state) => state.LP.flags.changingFromStateMapToChartOrTableView,
      changingToCompareForSpecificRiskFactor: (state) => state.LP.flags.changingToCompareForSpecificRiskFactor,
      lku: (state) => state.lku,
      stratFilters: (state) => state.LP.stratFilters,
      loadingIndicatorAllIds: (state) => state.LP.flags.loadingIndicatorAllIds,
      goClicked: (state) => state.LP.flags.goClicked,
      nationalId: (state) => state.LP.nationalId,
      countyFlag: (state) => state.LP.GSGo.countyFlag,
      reSelectLevel4: (state) => state.LP.flags.reSelectLevel4,
      globalSelectorChanged: (state) => state.LP.flags.globalSelectorChanged,
      lastCompareValue1: (state) => state.LP.lastCompareValue1,
      lastCompareValue2: (state) => state.LP.lastCompareValue2,
    }),
    ...mapGetters([
      'printerFriendly',
      'compareList',
      'compare',
      'responseLabel',
      'responseLabelPlural',
      'queryStringForSelectedView',
      'compareInteractionCode',
      'viewInteractionCode',
      'yearInteractionCode',
      'compareListRF',
      'compareViewInteractionCode',
    ]),
    locationId() {
      // The actual current locaion '' is stil keeping the sawme
      if (this.GSGo.locationId === '' && this.view === 'NationalChartTable') {
        return this.nationalId;
      }
      return this.GSGo.locationId;
    },
    showCompare() {
      if (
        this.view !== 'NationalMap' &&
        this.view !== 'StateMap' &&
        this.view !== 'NationalCountyMap' &&
        (this.showCompare1 || this.showCompare2)
      ) {
        return true;
      }
      return false;
    },
    showCompare1() {
      const rId = helper.getResponseId(this.indicatorId);
      if (rId !== '') {
        return true;
      }
      return false;
    },
    showCompare2() {
      // if (this.locationId === '' || this.GSGo.countyFlag === 'Y') {
      if (this.locationId !== '' && this.countyFlag === 'N') {
        return true;
      }
      return false;
    },
    responseId() {
      const rId = helper.getResponseId(this.indicatorId);
      // if (this.compareId === 'RESPONSE' || this.compareId2 === 'RESPONSE') {
      //   rId = '';
      // }
      return rId;
    },
    years() {
      return this.years1();
    },
    ages() {
      return this.ages1();
    },
    genders() {
      return this.genders1();
    },
    races() {
      return this.races1();
    },
    riskFactors() {
      return this.riskFactors1();
    },
    riskFactorResponses() {
      return this.riskFactorResponses1();
    },
    dataValueTypes() {
      return this.dataValueTypes1();
    },
    stratFilterObj() {
      return {
        compareViewYear: this.compareViewYear,
        compareId: this.compareId,
        compareId2: this.compareId2,
        yearId: this.yearId,
        responseId: this.responseId,
        ageId: this.ageId,
        genderId: this.genderId,
        raceId: this.raceId,
        riskFactorId: this.riskFactorId,
        riskFactorResponseId: this.riskFactorResponseId,
        dataValueTypeId: this.dataValueTypeId,
      };
    },
    stratFilterObjForCountryCouty() {
      return {
        compareViewYear: '1',
        compareId: '',
        compareId2: '',
        yearId: this.yearId === '' ? helper.defaultOrFirstId(this.years, this.yearId, false) : this.yearId,
        responseId: this.responseId,
        ageId: this.ageId === '' ? helper.defaultOrFirstId(this.ages, this.ageId, false) : this.ageId,
        genderId: this.genderId === '' ? helper.defaultOrFirstId(this.genders, this.genderId, false) : this.genderId,
        raceId: this.raceId === '' ? helper.defaultOrFirstId(this.races, this.raceId, false) : this.raceId,
        riskFactorId:
          this.riskFactorId === ''
            ? helper.defaultOrFirstId(this.riskFactors, this.riskFactorId, false)
            : this.riskFactorId,
        riskFactorResponseId:
          this.riskFactorResponseId === ''
            ? helper.defaultOrFirstId(this.riskFactorResponses, this.riskFactorResponseId, false)
            : this.riskFactorResponseId,
        dataValueTypeId:
          this.dataValueTypeId === ''
            ? helper.defaultOrFirstId(this.dataValueTypes, this.dataValueTypeId, false)
            : this.dataValueTypeId,
      };
    },
    isChrome() {
      // browser detection code from http://browserhacks.com/
      return !!window.chrome && !!window.chrome.webstore;
    },
    compareList1() {
      // this.compareList is from getters, thus it is populated immediately when indicatorAllIds is refreshed
      // thus, no need to make compareList1 as a method to force it refresh
      const clist = this.compareList;
      if (this.CompareGO.compareViewYear === '1') {
        const itemIndex = _(clist).findIndex({ id: 'YEAR' });
        if (itemIndex > -1) {
          clist.splice(itemIndex, 1);
        }
      } else {
        const itemIndex = _(clist).findIndex({ id: 'YEAR' });
        if (itemIndex === -1) {
          clist.push({ id: 'YEAR', name: 'Years' });
        }
      }
      return clist;
    },
    compareList2() {
      // this.compareList is from getters, thus it is populated immediately when indicatorAllIds is refreshed
      // thus, no need to make compareList1 as a method to force it refresh
      const clist = this.compareList;
      const resultList = [];
      // add all items in the compareList to the result
      for (let i = 0; i < clist.length; i += 1) {
        resultList.push(clist[i]);
      }
      // remove Year item
      const yearIndex = _(resultList).findIndex({ id: 'YEAR' });
      if (yearIndex > -1) {
        resultList.splice(yearIndex, 1);
      }
      // remove Overall
      const overallIndex = _(resultList).findIndex({ id: '', name: 'Overall' });
      if (overallIndex > -1) {
        resultList.splice(overallIndex, 1);
      }
      // remove item which is selected in compareList1
      if (this.CompareGO.compareId !== '') {
        const selectedCompare1Index = _(resultList).findIndex({ id: this.CompareGO.compareId });
        if (selectedCompare1Index > -1) {
          resultList.splice(selectedCompare1Index, 1);
        }
      }
      // Add Overall or None back
      if (this.CompareGO.compareId === 'YEAR') {
        resultList.unshift({ id: '', name: 'Overall' });
      } else {
        resultList.unshift({ id: '', name: 'None' });
      }
      // Remove Risk Factor items in compareList2 if compareList1 select risk factor
      const compareIdItem = _(this.compareListRF).find({ id: this.CompareGO.compareId });

      if (typeof compareIdItem !== 'undefined') {
        // let removeItes = _(resultList).remove(item => { //use this to catch the return value and use it immediatelyl to take affect
        // use _.remove to dierect remove
        _.remove(resultList, (item) => {
          if (typeof _(this.compareListRF).find(item) !== 'undefined') {
            return item;
          }
          return undefined;
        });
      }
      return resultList;
    },
    compareViewYear() {
      return this.CompareGO.compareViewYear;
    },
    goConpareId() {
      return this.CompareGO.compareId;
    },
  },
  watch: {
    compareList1: {
      handler() {
        this.setCompareId();
      },
      deep: true,
    },
    compareList2: {
      handler() {
        this.setCompareId2();
      },
      deep: true,
    },
    view() {
      // console.log('watch view');
      if (this.view === 'NationalMap' || this.view === 'NationalCountyMap' || this.view === 'StateMap') {
        this.compareId = '';
        this.compareId2 = '';
      }
    },
    years: {
      handler() {
        this.setYear();
      },
      deep: true,
    },
    ages: {
      handler() {
        this.setAgeId();
      },
      deep: true,
    },
    genders: {
      handler() {
        this.setGenderId();
      },
      deep: true,
    },
    races: {
      handler() {
        this.setRaceId();
      },
      deep: true,
    },
    riskFactors: {
      handler() {
        this.setRiskFactorId();
      },
      deep: true,
    },
    riskFactorResponses: {
      handler() {
        this.setRiskFactorResponseId();
      },
      deep: true,
    },
    dataValueTypes: {
      handler() {
        this.dataValueTypeId = helper.defaultOrFirstId(
          this.dataValueTypes,
          this.dataValueTypeId,
          this.useCurrentIdIfValid,
        );
      },
      deep: true,
    },
    loadingIndicatorAllIds: {
      handler() {
        if (this.goClicked && !this.loadingIndicatorAllIds) {
          /// //////////////////
          this.CompareGO.compareViewYear = '1';
          // reset compare value
          if (
            this.showCompare1 === true &&
            this.view !== 'NationalMap' &&
            this.view !== 'NationalCountyMap' &&
            this.view !== 'StateMap'
          ) {
            // restore compair values
            this.restoreLastCompareValues();
            // this.CompareGO.compareId = '';
          } else {
            this.setCompareId();
          }
          // this.CompareGO.compareId2 = '';

          // Specic to Detailed Age Groups
          if (
            helper.getDetailedAgeGroupIndicators().includes(this.indicatorId) &&
            (this.view === 'NationalChartTable' || this.view === 'StateChartTable')
          ) {
            if (this.CompareGO.compareId === '') {
              this.CompareGO.compareId = 'AGE';
            }
            // this.CompareGO.compareId2 = '';
          }
          this.compareId = this.CompareGO.compareId;
          this.compareId2 = this.CompareGO.compareId2;

          if (this.indicatorChanged && !this.initialPageLoad) {
            this.useCurrentIdIfValid = false; // it means reset all filters
          }
          this.years1();
          this.setYear();
          this.ages1();
          this.setAgeId();
          this.genders1();
          this.setGenderId();
          this.races1();
          this.setRaceId();
          this.riskFactors1();
          this.setRiskFactorId();
          this.riskFactorResponses1();
          this.setRiskFactorResponseId();
          this.dataValueTypes1();
          this.setDataValueType();
          /// ///////////////////////////////
          // if RiskFactor Response has more than one value
          // this.setSpecificCompare();
          // && (this.CompareGO.compareId === '')
          let didCompareClicked = false;
          if (!(this.initialPageLoad && this.viewFromUrl !== '')) {
            if (this.riskFactorResponses1() && this.riskFactorResponses1().length > 1) {
              if (this.reSelectLevel4) {
                if (this.CompareGO.compareId === '') {
                  if (this.GSGo.locationId === '' || this.GSGo.locationId === this.nationalId) {
                    this.$store.commit('setView', 'NationalChartTable');
                  } else {
                    this.$store.commit('setView', 'StateChartTable');
                  }
                  this.CompareGO.compareId = helper.defaultOrFirstId(this.compareList1, this.riskFactorId, true);
                  this.compare_Click();
                  didCompareClicked = true;
                }
                // since view is changed to NationalChartTable, the location is changed to 59
                // -> it call loadingDSLinkLocationApiData again in GlobalSelectorStrat
                // so, not call this.applyFilters_Click() here
              } else if (
                (this.view === 'NationalChartTable' || this.view === 'StateChartTable') &&
                this.CompareGO.compareId === ''
              ) {
                this.CompareGO.compareId = helper.defaultOrFirstId(this.compareList1, this.riskFactorId, true);
                this.compare_Click();
                didCompareClicked = true;
              }
            }
          }
          /// //////////////////
          if (didCompareClicked === false) {
            this.applyFilters_Click();
          }
          // reset changedFilters fpr Omniture
          this.changedFilters = [];
        }
      },
      deep: true,
    },
    compareViewYear() {
      if (this.CompareGO.compareViewYear === '1') {
        const itemIndex = _(this.compareList1).findIndex({ id: 'YEAR' });
        if (itemIndex > -1) {
          this.compareList1.splice(itemIndex, 1);
          this.CompareGO.compareId = '';
        }
        this.CompareGO.compareId = '';
      } else {
        const itemIndex = _(this.compareList1).findIndex({ id: 'YEAR' });
        if (itemIndex === -1) {
          this.compareList1.push({ id: 'YEAR', name: 'Years' });
        }
        this.CompareGO.compareId = 'YEAR';
      }
    },
    changingFromNationalChartTableToMapView() {
      // console.log('change map');
      if (
        this.changingFromNationalChartTableToMapView &&
        (this.view === 'NationalMap' || this.view === 'NationalCountyMap')
      ) {
        this.CompareGO.compareViewYear = '1';
        // retain compair values
        this.keepLastCompareValues();
        // reset compare value
        this.CompareGO.compareId = '';
        this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();
        // Let it refresh in the gsLocationId watch in the GlobalSelectorStrat
      }
    },
    changingFromNationalChartTableToChartOrTableView() {
      if (this.changingFromNationalChartTableToChartOrTableView) {
        this.CompareGO.compareViewYear = '1';
        // // retain compair values
        // this.keepLastCompareValues();
        // // reset compare value
        // if (this.showCompare1 === true) {
        //   this.CompareGO.compareId = ''; // RESPONSE' is the Indcidator Level 4 Compare All
        // }
        // this.CompareGO.compareId2 = '';
        this.restoreLastCompareValues();
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();
        // Let it refresh in the gsLocationId watch in the GlobalSelectorStrat
      }
    },
    changingFromNationalMapToChartOrTableView() {
      if (this.changingFromNationalMapToChartOrTableView) {
        this.CompareGO.compareViewYear = '1';
        // restore compair values
        this.restoreLastCompareValues();
        // // reset compare value
        // this.CompareGO.compareId = '';
        // this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        if (this.CompareGO.compareId !== '') {
          // this.useCurrentIdIfValid = true;
          this.setYear();
          this.setAgeId();
          this.setGenderId();
          this.setRaceId();
          this.setRiskFactorId();
          this.setRiskFactorResponseId();
          this.setDataValueType();
          this.setResponseId();

          this.applyFilters_Click();
        }
      }
    },
    changingFromNationalChartOrTableToMapView() {
      if (this.changingFromNationalChartOrTableToMapView && this.CompareGO.compareId !== '') {
        this.CompareGO.compareViewYear = '1';
        // retain compair values
        this.keepLastCompareValues();
        // reset compare value
        this.CompareGO.compareId = '';
        this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();

        this.applyFilters_Click();
      }
    },
    changingFromStateChartTableToMapView() {
      if (this.changingFromStateChartTableToMapView && this.view === 'StateMap') {
        this.CompareGO.compareViewYear = '1';
        // retain compair values
        this.keepLastCompareValues();
        // reset compare value
        this.CompareGO.compareId = '';
        this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();
      }
    },
    changingFromStateChartTableToChartOrTableView() {
      if (this.changingFromStateChartTableToChartOrTableView) {
        this.CompareGO.compareViewYear = '1';
        // // retain compair values
        // this.keepLastCompareValues();
        // // reset compare value
        // if (this.showCompare1 === true) {
        //   this.CompareGO.compareId = ''; // RESPONSE' is the Indcidator Level 4 Compare All
        // }
        // this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();
        // Let it refresh in the GlobalSelectorStrat
      }
    },
    changingFromStateMapToChartOrTableView() {
      if (this.changingFromStateMapToChartOrTableView) {
        this.CompareGO.compareViewYear = '1';
        // restore compair values
        this.restoreLastCompareValues();
        // // reset compare value
        // this.CompareGO.compareId = '';
        // this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();

        this.applyFilters_Click();
      }
    },
    changingFromStateChartOrTableToMapView() {
      if (this.changingFromStateChartOrTableToMapView && this.CompareGO.compareId !== '') {
        this.CompareGO.compareViewYear = '1';
        // retain compair values
        this.keepLastCompareValues();
        // reset compare value
        this.CompareGO.compareId = '';
        this.CompareGO.compareId2 = '';
        this.compareId = this.CompareGO.compareId;
        this.compareId2 = this.CompareGO.compareId2;

        // this.useCurrentIdIfValid = true;
        this.setYear();
        this.setAgeId();
        this.setGenderId();
        this.setRaceId();
        this.setRiskFactorId();
        this.setRiskFactorResponseId();
        this.setDataValueType();
        this.setResponseId();

        this.applyFilters_Click();
      }
    },
    goConpareId() {
      if (this.CompareGO.compareId === '') {
        this.CompareGO.compareId2 = '';
      }
      this.setResponseId();
    },
    yearId(newValue, oldValue) {
      // `${process.env.VUE_APP_InteractionIdentifier} - FILTER, ${this.yearId},${this.ageId},${this.genderId},${this.raceId},${this.riskFactorId},${this.riskFactorResponseId},${this.dataValueTypeId}`,
      if (oldValue !== '') {
        this.changedFilters.push(`${process.env.VUE_APP_InteractionIdentifier} - FILTER, YEAR, ${this.yearId}`);
      }
    },
    ageId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(`${process.env.VUE_APP_InteractionIdentifier} - FILTER, AGE GROUP, ${this.ageId}`);
      }
    },
    genderId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(`${process.env.VUE_APP_InteractionIdentifier} - FILTER, GENDER, ${this.genderId}`);
      }
    },
    raceId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(`${process.env.VUE_APP_InteractionIdentifier} - FILTER, RACE, ${this.raceId}`);
      }
    },
    riskFactorId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(
          `${process.env.VUE_APP_InteractionIdentifier} - FILTER, RISK FACTOR, ${this.riskFactorId}`,
        );
      }
    },
    riskFactorResponseId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(
          `${process.env.VUE_APP_InteractionIdentifier} - FILTER, RISK FACTOR RESPONSE, ${this.riskFactorResponseId}`,
        );
      }
    },
    dataValueTypeId(newValue, oldValue) {
      if (oldValue !== '') {
        this.changedFilters.push(
          `${process.env.VUE_APP_InteractionIdentifier} - FILTER, DATA TYPE, ${this.dataValueTypeId}`,
        );
      }
    },
  },
  methods: {
    getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey) {
      return this.$store.getters.getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey);
    },
    getReportDataIdsAndSetStratFilterIdsThenSetBrowserUrlBar() {
      if (!this.printerFriendly) {
        // get ReportDataIds
        this.$store
          .dispatch('getReportDataIdsAndSetStratFilterIds', {
            filterObj: this.stratFilterObj,
            locationId: this.locationId,
          })
          .then(() => {
            this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
            window.location.hash = '#divLinkbucket';
          });
        // get CountryCountyReportDataIds
        const responseId = helper.getResponseId(this.indicatorId);
        if (responseId && this.locationId === '') {
          this.getCountryCountyReportDataIds();
        }
        window.location.hash = '#divLinkbucket';
      } else if (this.view === 'NationalCountyMap') {
        this.getCountryCountyReportDataIds();
      } else {
        this.$store.dispatch('getReportDataIdsAndSetStratFilterIds', {
          filterObj: this.stratFilterObj,
          locationId: this.locationId,
        });
      }
      window.location.hash = '#divLinkbucket';
    },
    getCountryCountyReportDataIds() {
      this.$store.dispatch('getCountryCountyReportDataIdsAndSetStratFilterIds', {
        filterObj: this.stratFilterObjForCountryCouty,
      });
    },
    getCountyIdForOmniture() {
      let countyValue = 'False';
      if (this.GSGo) {
        countyValue = this.GSGo.countyFlag === 'Y' ? 'True' : 'False';
      }
      return countyValue;
    },
    resetFilters() {
      // (`${process.env.VUE_APP_InteractionIdentifier}-IF ${helper.getLocationIdForOmniture(this.GSGo.locationId)},${this.GSGo.dataSourceId},${this.getCountyIdForOmniture()},${this.indicatorId},${helper.getViewForOmniture(this.view)},${this.compareViewInteractionCode(this.view, this.GSGo.locationId, this.stratFilters.compareViewYear, this.stratFilters.compareId, this.stratFilters.compareId2)},${this.yearInteractionCode(this.yearId)},${this.responseId},${this.ageId},${this.genderId},${this.raceId},${this.riskFactorId},${this.riskFactorResponseId},${this.dataValueTypeId}`);

      this.useCurrentIdIfValid = false;
      this.setYear();
      this.setResponseId();
      this.setAgeId();
      this.setGenderId();
      this.setRaceId();
      this.setRiskFactorId();
      this.setRiskFactorResponseId();
      this.setDataValueType();
      this.suppressSecondInteractionAfterResetFilters = true;
      // refresh the view
      this.applyFilters_Click();
    },
    setCompareViewYear() {
      this.CompareGO.compareViewYear = '1';
      this.CompareGO.compareId = '';
      this.CompareGO.compareId2 = '';
      this.compareId = this.CompareGO.compareId;
      this.compareId2 = this.CompareGO.compareId2;
    },
    setCompareId() {
      const rId = helper.getResponseId(this.indicatorId);
      if (rId === '') {
        this.compareId = 'RESPONSE';
        this.CompareGO.compareId = 'RESPONSE';
      } else if (this.CompareGO.compareViewYear === '0') {
        this.CompareGO.compareId = 'YEAR';
      } else {
        this.CompareGO.compareId = helper.defaultOrFirstId(
          this.compareList1,
          this.CompareGO.compareId,
          this.useCurrentIdIfValid,
        );
      }
      // else if (this.CompareGO.compareId !== '' && this.initialPageLoad) {
      //   if (this.compareList1 && this.compareList1.length > 1) {
      //     this.CompareGO.compareId = helper.defaultOrFirstId(
      //       this.compareList1,
      //       this.CompareGO.compareId,
      //       this.useCurrentIdIfValid,
      //     );
      //   }
      // }
    },
    setCompareId2() {
      this.CompareGO.compareId2 = helper.defaultOrFirstId(
        this.compareList2,
        this.CompareGO.compareId2,
        this.useCurrentIdIfValid,
      );
    },
    setYear() {
      // only compareId has year
      this.yearId =
        this.compareId === 'YEAR' ? '' : helper.defaultOrFirstId(this.years, this.yearId, this.useCurrentIdIfValid);
    },
    setResponseId() {
      // THIS IS NOT NECCSESSARY -> WILL DELETE LATER
      if (this.compareId === 'RESPONSE' || this.compareId2 === 'RESPONSE') {
        this.$store.commit('setCompareAllIndicator', true);
      } else {
        this.$store.commit('setCompareAllIndicator', false);
      }
    },
    setAgeId() {
      if (this.compareId === 'AGE' || this.compareId2 === 'AGE') {
        this.ageId = '';
      } else if (
        this.indicatorChanged &&
        (this.indicatorId === 'QVTAMDM~' || this.indicatorId === 'QAMDM~R3_ALL') &&
        _.filter(this.ages, { id: 'AGE40PLUS' }).length
      ) {
        // Level1=Age-related Macular Degeneration (AMD)
        // Level2=AMD Prevalence
        // Level3=VEHSS Composite Prevalence Estimate: Age-related Macular Degeneration (AMD)
        // Level4=Prevalence of AMD
        // OR
        // Level4=Prevalence of AMD, by vision threatening status
        this.ageId = 'AGE40PLUS';
      } else {
        this.ageId = helper.defaultOrFirstId(this.ages, this.ageId, this.useCurrentIdIfValid);
      }
    },
    setGenderId() {
      this.genderId =
        this.compareId === 'GENDER' || this.compareId2 === 'GENDER'
          ? ''
          : helper.defaultOrFirstId(this.genders, this.genderId, this.useCurrentIdIfValid);
    },
    setRaceId() {
      this.raceId =
        this.compareId === 'RACE' || this.compareId2 === 'RACE'
          ? ''
          : helper.defaultOrFirstId(this.races, this.raceId, this.useCurrentIdIfValid);
    },
    setRiskFactorId() {
      if (this.compareId.substring(0, 2) === 'RF' || this.compareId2.substring(0, 2) === 'RF') {
        if (this.compareId.substring(0, 2) === 'RF') {
          this.riskFactorId = this.compareId;
        } else {
          this.riskFactorId = this.compareId2;
        }
      } else if (
        !(this.initialPageLoad && this.riskFactorId !== '') &&
        (this.globalSelectorChanged || this.reSelectLevel4) &&
        this.GSGo.riskFactorSubCatId !== ''
      ) {
        // this.initialPageLoad && this.riskFactorId !== '' => page load form boodmark
        let riskFactorName = this.GSGo.riskFactorSubCatId;
        if (riskFactorName === 'AMD') {
          riskFactorName = 'Age-related macular degeneration';
        }
        this.riskFactorId = helper.defaultOrFirstName(this.riskFactors, riskFactorName, true);
      } else {
        this.riskFactorId = helper.defaultOrFirstId(this.riskFactors, this.riskFactorId, this.useCurrentIdIfValid);
      }
    },
    setRiskFactorResponseId() {
      this.riskFactorResponseId =
        this.compareId.substring(0, 2) === 'RF' || this.compareId2.substring(0, 2) === 'RF'
          ? ''
          : helper.defaultOrFirstId(this.riskFactorResponses, this.riskFactorResponseId, this.useCurrentIdIfValid);
    },
    setDataValueType() {
      this.dataValueTypeId = helper.defaultOrFirstId(
        this.dataValueTypes,
        this.dataValueTypeId,
        this.useCurrentIdIfValid,
      );
    },
    compare_Click() {
      this.compareId = this.CompareGO.compareId;
      this.compareId2 = this.CompareGO.compareId2;
      if (!this.compareId) {
        const rId = helper.getResponseId(this.indicatorId);
        if (rId === '') {
          this.compareId = 'RESPONSE';
          this.CompareGO.compareId = 'RESPONSE';
        }
      }
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - COMPARE, ${this.compareId},${this.compareId2}`,
      );
      // when the user changes the compare dropdown,
      // reset the strat filters to their default values
      // i.e. the first item in each dropdown
      // this.useCurrentIdIfValid = true; // changed False to True -> it should keep the current value, except for the compare Value
      this.setYear();
      this.setResponseId();
      this.setAgeId();
      this.setGenderId();
      this.setRaceId();
      this.setRiskFactorId();
      this.setRiskFactorResponseId();
      // Refresh the view
      // this.applyFilters_Click('IC');
      this.applyFilters_Click();
    },
    applyFilters_Click(clickedButton) {
      // trigger reportDataIds api call after the indicatorAllIds api call
      // has completed so the strat filters are up to date
      if (!this.loadingIndicatorAllIds) {
        this.getReportDataIdsAndSetStratFilterIdsThenSetBrowserUrlBar();

        // Set the useCurrentIdIfValid
        // flag to true so previously selected filter values are preserved when a filter dropdown
        // is changed and subsequent filter dropdown values are still valid.
        this.useCurrentIdIfValid = true;
        if (this.initialPageLoad) this.$store.commit('setInitialPageLoad', false);
        if (this.globalSelectorChanged) this.$store.commit('setGlobalSelectorChanged', false);
        if (this.reSelectLevel4) this.$store.commit('setReSelectLevel4', false);
        this.$store.commit('setIndicatorChanged', false);
        this.$store.commit('setGoClicked', false);
        // Omniture
        if (clickedButton === 'AF') {
          // triggerOmnitureInteractions(
          //   `${process.env.VUE_APP_InteractionIdentifier} - FILTER, ${this.yearId},${this.ageId},${this.genderId},${this.raceId},${this.riskFactorId},${this.riskFactorResponseId},${this.dataValueTypeId}`,
          // );
          if (this.changedFilters && this.changedFilters.length > 0) {
            this.changedFilters.forEach((e) => {
              triggerOmnitureInteractions(e);
            });
          }
        }
      }
      this.changedFilters = [];
    },
    /// ///////////////////////////////////////
    years1() {
      const filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      return _(this.indicatorAllIds)
        .filter(filter)
        .map('yr')
        .uniq()
        .map((x) => this.lku.Year[x])
        .orderBy('sort', 'desc')
        .value();
    },
    ages1() {
      // console.log('computed ages - ');
      let filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      return _(this.indicatorAllIds)
        .filter(filter)
        .map('ag')
        .uniq()
        .map((x) => this.lku.AgeGroup[x])
        .orderBy('sort', 'asc')
        .value();
    },
    genders1() {
      // console.log(`computed genders - this.ageId : ${this.ageId }`);
      let filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      if (this.compareId !== 'AGE' && this.compareId2 !== 'AGE') filter = _.assign(filter, { ag: this.ageId });
      return _(this.indicatorAllIds)
        .filter(filter)
        .map('ge')
        .uniq()
        .map((x) => this.lku.Gender[x])
        .orderBy('sort', 'asc')
        .value();
    },
    races1() {
      // console.log('computed races');
      let filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      if (this.compareId !== 'AGE' && this.compareId2 !== 'AGE') filter = _.assign(filter, { ag: this.ageId });
      if (this.compareId !== 'GENDER' && this.compareId2 !== 'GENDER') filter = _.assign(filter, { ge: this.genderId });
      return _(this.indicatorAllIds)
        .filter(filter)
        .map('re')
        .uniq()
        .map((x) => this.lku.RaceEthnicity[x])
        .orderBy('sort', 'asc')
        .value();
    },
    riskFactors1() {
      // console.log('computed riskFactors');
      let filter = {};
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      if (this.compareId !== 'AGE' && this.compareId2 !== 'AGE') filter = _.assign(filter, { ag: this.ageId });
      if (this.compareId !== 'GENDER' && this.compareId2 !== 'GENDER') filter = _.assign(filter, { ge: this.genderId });
      if (this.compareId !== 'RACE' && this.compareId2 !== 'RACE') filter = _.assign(filter, { re: this.raceId });

      return _(this.indicatorAllIds)
        .filter(filter)
        .map('rf')
        .uniq()
        .map((x) => this.lku.RiskFactor[x])
        .orderBy('sort', 'asc')
        .value();
    },
    riskFactorResponses1() {
      // console.log(`computed riskFactorResponses -  this.compareId1: ${this.compareId}`);
      // console.log(`computed riskFactorResponses -  this.compareId2: ${this.compareId2}`);
      // console.log(`computed riskFactorResponses - this.riskFactorId: ${this.riskFactorId}`);
      let filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      if (this.compareId !== 'AGE' && this.compareId2 !== 'AGE') filter = _.assign(filter, { ag: this.ageId });
      if (this.compareId !== 'GENDER' && this.compareId2 !== 'GENDER') filter = _.assign(filter, { ge: this.genderId });
      if (this.compareId !== 'RACE' && this.compareId2 !== 'RACE') filter = _.assign(filter, { re: this.raceId });
      filter = _.assign(filter, { rf: this.riskFactorId });
      const result = _(this.indicatorAllIds)
        .filter(filter)
        .map('rfr')
        .uniq()
        .map((x) => this.lku.RiskFactorResponse[x])
        .orderBy('sort', 'asc')
        .value();
      return result;
    },
    dataValueTypes1() {
      // console.log('computed dataValueTypes');
      let filter = {};
      if (this.compareId.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId }); // OR
      if (this.compareId2.substring(0, 2) === 'RF') _.assign(filter, { rf: this.compareId2 });
      if (this.compareId !== 'YEAR') filter = _.assign(filter, { yr: this.yearId });
      // if (this.compareId !== 'RESPONSE' && this.compareId2 !== 'RESPONSE') filter = _.assign(filter, { rs: this.responseId });
      if (this.responseId !== '') filter = _.assign(filter, { rs: this.responseId });
      if (this.compareId !== 'AGE' && this.compareId2 !== 'AGE') filter = _.assign(filter, { ag: this.ageId });
      if (this.compareId !== 'GENDER' && this.compareId2 !== 'GENDER') filter = _.assign(filter, { ge: this.genderId });
      if (this.compareId !== 'RACE' && this.compareId2 !== 'RACE') filter = _.assign(filter, { re: this.raceId });
      filter = _.assign(filter, { rf: this.riskFactorId });
      if (this.compareId.substring(0, 2) !== 'RF' && this.compareId2.substring(0, 2) !== 'RF') {
        filter = _.assign(filter, { rfr: this.riskFactorResponseId });
      }

      // const t = _(this.indicatorAllIds).filter(filter).map('dv').uniq();
      return _(this.indicatorAllIds)
        .filter(filter)
        .map('dv')
        .uniq()
        .map((x) => this.lku.DataValueType[x])
        .orderBy('sort', 'asc')
        .value();
    },
    isMapView() {
      if (this.view === 'NationalMap' || this.view === 'StateMap' || this.view === 'NationalCountyMap') {
        return true;
      }
      return false;
    },
    keepLastCompareValues() {
      if (this.showCompare1) {
        this.$store.commit('setLastCompareValue1', this.CompareGO.compareId);
      }
      if (this.showCompare2) {
        this.$store.commit('setLastCompareValue2', this.CompareGO.compareId2);
      }
    },
    restoreLastCompareValues() {
      if (this.showCompare1 && this.CompareGO.compareId === '') {
        this.CompareGO.compareId = this.lastCompareValue1;
      }
      if (this.showCompare2 && this.CompareGO.compareId2 === '') {
        this.CompareGO.compareId2 = this.lastCompareValue2;
      }
    },
    getChangedFilters() {
      let result = '';
      if (this.changedFilters && this.changedFilters.length > 0) {
        this.changedFilters.forEach((e) => {
          if (result === '') {
            result = e;
          } else {
            result = `${result}&${e}`;
          }
        });
      }
      return result;
    },
  },
};
</script>

<style scoped>
.compareContainer {
  padding: 0.5rem 0.5rem 0.1rem 0.5rem;
}

.stratContainer {
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.compareSeperator {
  height: 15px;
  background-color: white;
}
.view-year-label {
  font-weight: 400;
}
</style>
