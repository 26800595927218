import _ from 'lodash';
import * as d3 from 'd3';
import isNumber from 'is-number';

/* eslint-disable */
function makeChartGroupLine(dataObj, lku, compareStr, settingsObj, chartMountNodeIdStr, compareStr2, locationId) {

  let dataCompareColumn = settingsObj.dataCompareColumn; //"ag"
  let dataCompareColumn2 = settingsObj.dataCompareColumn2; //"ge"
  let chartTitleStr = settingsObj.chartTitleStr; //"Percent (%)"
  let legendTitleStr = settingsObj.legendTitleStr2;
  let decimalPlaces = settingsObj.decimalPlaces; //2
  let chartSummary = settingsObj.chartSummary; //"This chart gives Crude Prevalence data from the Managed Vision Care database for the selected topic and category with Overall values for each available location on the y-axis. For an accessible view of the data, choose display table."
  let sampleSizeSymbol = settingsObj.sampleSizeSymbol; //"N"
  let colorsArrStr = settingsObj.colorsArrStr; //["#1f78b4", "#e31a1c", "#33a02c", "#6a3d9a", "#ff7f00", "#b15928", "#a6cee3", "#fb9a99", "#b2df8a", "#cab2d6", "#fdbf6f", "#ffff99"]
  let confidenceIntervalLabel = settingsObj.confidenceIntervalLabel; //"95% CI"
  let maxTicks = settingsObj.maxTicks;
  let txttAngleRotate = settingsObj.txttAngleRotate;
  //compareStr and compareStr2 are lookup group

  //compareStr: AgeGroup
  //settingsObj
  //chartSummary: "This chart gives Crude Prevalence data from the Managed Vision Care database for the selected topic and category with Overall values for each available location on the y-axis. For an accessible view of the data, choose display table."
  //chartTitleStr: "Percent (%)"
  //colorsArrStr: (12)["#1f78b4", "#e31a1c", "#33a02c", "#6a3d9a", "#ff7f00", "#b15928", "#a6cee3", "#fb9a99", "#b2df8a", "#cab2d6", "#fdbf6f", "#ffff99"]
  //confidenceIntervalLabel: "95% CI"
  //dataCompareColumn: "ag"
  //decimalPlaces: 2
  //legendTitleStr: ""
  //sampleSizeSymbol: "N"

  let dataObjSorted = [];
  let colorsObj = {};
  let groupIds = [];
  let groupNames = [];
  let subGroupIds = [];
  let subGroupNames = [];
  let groupTypes = {};
  let subGroupTypes = {};
  let totalGroupIds = 0;
  let totalSubGroupIds = 0;

  //--1--Filter out one location
  let oneLocData = dataObj.filter(item => item.loc == locationId);

  // adding location name
  oneLocData = _.forEach(oneLocData, function (o) {
    o.locName = lku.Location[o.loc].name;
    return o;
  })

  //1.-------------- ADD additional column into each row: subGroupName, sortGroup, subGroupName, subSortGroup ------
  // adding type name and type sort
  groupTypes = lku[compareStr];
  subGroupTypes = lku[compareStr2];

  oneLocData.forEach(function (o) {
    o.groupName = groupTypes[o[dataCompareColumn]].name;
    o.groupId = groupTypes[o[dataCompareColumn]].id;
    o.sortGroup = groupTypes[o[dataCompareColumn]].sort;
    if (!$.isEmptyObject(subGroupTypes)) {
      o.subGroupName = subGroupTypes[o[dataCompareColumn2]].name;
      o.subGroupId = subGroupTypes[o[dataCompareColumn2]].id;
      o.subSortGroup = subGroupTypes[o[dataCompareColumn2]].sort;
    }
    else {
      o.subGroupName = '';
      o.subGroupId = '';
      o.subSortGroup = 1;
    }
    return o
  });
  // console.log(`oneLocData: ${JSON.stringify(oneLocData)}`);

  //2.-------------- CALCULATE TOTAL GROUPS ------
  //-GROUP: total number of bars, sorted by group sort order
  let allGroups = _.map(oneLocData, dataCompareColumn);
  groupIds = _.sortBy(_.uniq(allGroups), [function (o) {
    return groupTypes[o].sort;
  }])
  totalGroupIds = groupIds.length;
  //console.log(`groupIds: ${JSON.stringify(groupIds)}`);
  //allGroups: ["AGE1839","AGE6584","AGE4064","AGEALL"...]
  //groupIds: ["AGEALL","AGE017","AGE1839","AGE4064","AGE6584","AGE85PLUS"]

  //-SUB-GROUP: total number of bars, sorted by group sort order
  let allSubGroups = _.map(oneLocData, dataCompareColumn2);
  subGroupIds = _.sortBy(_.uniq(allSubGroups), [function (o) {
    return subGroupTypes[o].sort;
  }])
  totalSubGroupIds = subGroupIds.length;

  //3.------------- remove all rows  which doesn't have dv value
  dataObjSorted = oneLocData.filter((d) => !isNaN(d.dv));

  //4.--------------SORT ------
  // sorting by location and group
  dataObjSorted = _.sortBy(dataObjSorted, ['sortGroup', 'subSortGroup'])
  //console.log(`oneLocData: ${JSON.stringify(oneLocData)}`);

  //5.--------------CREATE GROUP  & SUB_GROUP NAMES ARRAY ------
  groupNames = _.uniq(dataObjSorted.map((r) => r.groupName));
  subGroupNames = _.uniq(dataObjSorted.map((r) => r.subGroupName));

  //6.--------------COLOR ------
  // extending colors if not enough
  while (settingsObj.colorsArrStr.length < subGroupNames.length) {
    settingsObj.colorsArrStr.push(settingsObj.colorsArrStr[0])
  }
  // making bars color object
  for (let i = 0; i < subGroupNames.length; i++) {
    colorsObj[subGroupNames[i]] = settingsObj.colorsArrStr[i];
  }

  //-------------------------------------- Create Chart ------------------------------------------

  //---chart with 1000 pixels width and 600 pixels height.
  let svgAreaWidth = 1200;
  let svgAreaHeight = 500;
  let catPadding = 0.2;
  let textRotate = "0";
  let textAnchor = "middle";
  let spaceForTiltLabel = 0;
  var legendSpace = subGroupNames.length * 20 + 60;

 //calculate svgAreaWidth
  if (totalGroupIds > 2) {
    svgAreaWidth = totalGroupIds * 200;
  }
  else {
    svgAreaWidth = totalGroupIds * 300;
  }
  if (svgAreaWidth > 1200) {
    svgAreaWidth = 1200;
  }

  let margin = { top: 10, right: 20, bottom: 50, left: 60 };
  let chartWidth = svgAreaWidth - margin.left - margin.right;
  let chartHeight = svgAreaHeight - margin.top - margin.bottom;

  //////////////////////////////////////////////////////////////////---create X SCALE --------------------------------------------
   //create X SCALE - GROUP
  const xScale = d3.scalePoint()
    .range([0, chartWidth])
    .domain(groupNames)
    .padding(catPadding);

  //adjustlabel display,  margin bottom, and chart height
  var longestGroupName = groupNames.sort(function (a, b) { return b.length - a.length; })[0];
  var longestGroupNameInPixel = getStringLengthInPixel(longestGroupName);

  if (xScale.step() < longestGroupNameInPixel - 10) {
    textRotate = txttAngleRotate;
    spaceForTiltLabel = Math.max(50, longestGroupNameInPixel - 120);
    textAnchor = "end";
  }
  margin.bottom = margin.bottom + spaceForTiltLabel + legendSpace;;
  svgAreaHeight = svgAreaHeight + margin.bottom;
  chartHeight = svgAreaHeight - margin.top - margin.bottom;

  ///////////////////////////////////////////////////////////////////---create Y SCALE---------------------------------------------
  let maxHci = d3.max(dataObjSorted, function (d) { try { return parseFloat(d.hci); } catch (err) { return 0; } });
  let maxDv = d3.max(dataObjSorted, function (d) { try { return parseFloat(d.dv); } catch (err) { return 0; } });
  let maxValue = maxDv; // Math.max(maxHci, maxDv);

  if (maxValue === 0) {
    maxValue = 0.09;
  }

  const yScale = d3.scaleLinear()
    .range([chartHeight, 0])
    .domain([0, maxValue]).nice();

  //add extra tick to Y axis
  let ticks = yScale.ticks();
  let lastTick = ticks[ticks.length - 1];
  let newLastTick = lastTick + (ticks[1] - ticks[0]);
  if (lastTick < yScale.domain()[1]) {
    ticks.push(newLastTick);
  }
  yScale.domain([yScale.domain()[0], newLastTick]).nice(); //<-- adjust domain for further value

  //define yAxis with ticks
  let yAxis = d3.axisLeft(yScale);
  let tickLength = yScale.ticks().length;
  tickLength = getTickLength(tickLength);
  yAxis = yAxis.ticks(tickLength);

  //Add another extra tick
  const ticks1 = yScale.ticks();
  const lastTick1 = ticks1[ticks1.length - 1];
  const newLastTick1 = lastTick1 + (ticks1[1] - ticks1[0]);

  if (lastTick1 === newLastTick && newLastTick1 > newLastTick) {
    yScale.domain([yScale.domain()[0], newLastTick1]).nice; //<-- adjust domain for further value
    yAxis = d3.axisLeft(yScale);
    yAxis = yAxis.ticks(tickLength);
  }

  ///////////////////////////////////////////////////
  //---create COLOR scale
  let color = d3.scaleOrdinal()
    .range(colorsArrStr);

  //---CLEAR out the chart div for a new chart
  const chartMountNode = document.getElementById(chartMountNodeIdStr);
  while (chartMountNode.firstChild) {
    chartMountNode.removeChild(chartMountNode.firstChild)
  }

  //---create SVG area ------------------
  const svgArea = d3.select('#' + chartMountNodeIdStr).append("svg")
    .attr("width", svgAreaWidth)
    .attr("height", svgAreaHeight)
    .attr('id', 'svgArea');
  //.attr("style", "outline: thin solid red;");

  //---CHART summary ------------------
  svgArea.append('desc')
    .text(chartSummary.replace('[TotalBar]', subGroupNames.length));

  //---create CHART STRATING POINT -----------------
  const svgChart = svgArea.append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  //---add y axis to svgChart ----------
  svgChart.append('g')
    .attr("class", "yAxis")
    .call(yAxis);

  //---add x axis to svgChart
  svgChart.append('g')
    .attr("class", "xAxis")
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(d3.axisBottom(xScale))
    .selectAll("text")
    .style("text-anchor", `${textAnchor}`)
    .attr("transform", `rotate(${textRotate})`)
    .attr("dy", "1em");

  //--- create GRID LINE----------------------
  //create grid line first so it lays under Bar layer
  svgChart.append('g')
    .attr('class', 'grid')
    .call(yAxis
      .tickSize(-chartWidth, 0, 0)
      .tickFormat(''))

  //Create line function
  let line = d3.line()
    .x((d) => xScale(d.groupName))
    .y((d) => yScale(d.dv));

  ///////////////////////// DRAW CHART ////////////////////////////
  //console.log(`dataObjSorted: ${JSON.stringify(dataObjSorted)}`);
  //console.log(`subGroupNames.length: ${subGroupNames.length}`);
  //console.log(`subGroupNames: ${subGroupNames}`);
  for (let i = 0; i < subGroupNames.length; i++) {
    let subGroupData = _.filter(dataObjSorted, (o) => o.subGroupName == subGroupNames[i]);
    subGroupData = _.sortBy(subGroupData, (o) => o.subSortGroup);

    // Individual points
    svgChart.selectAll('.point.subgroup' + i)
      .data(subGroupData)
      .enter()
      .append('path')
      .attr("class", 'point')
      .attr('fill', color(i))
      .attr('d', d3.symbol().type(d3.symbolCircle).size(100))
      .attr("transform", (d) => `translate(${xScale(d.groupName)}, ${yScale(isNaN(d.dv) ? 0 : d.dv)})`)
      .on('mouseover', function (event, d) {
        OnMouseOver(this, color(i), d, event);
      })
      .on('mouseout', function (event, d) {
        OnMouseOut(this, d, event);
      });

    //Connect the points with lines
    svgChart.append("path")
      .datum(subGroupData)
      .attr("fill", "none")
      .attr("stroke", color(i))
      .attr("stroke-width", "2")
      .attr("d", line);
  }

  /////////////////////////////////////////////////////////////////////////
  //--- create LABEL for Y-AXIS----------------
  svgArea.append('text')
    .attr('class', 'chartLabel')
    .attr('x', -(chartHeight / 2) - margin.top)
    .attr('y', margin.left / 6)
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .text(chartTitleStr);

  /////////////////////////////////////////////////////////////////
  //--- create ToolTip ----------------
  let barTooltip = d3.select('#' + chartMountNodeIdStr)
    .append('div')
    .style('opacity', 0)
    .attr('id', 'barTooltip')

  function getTooltipStr(d) {
    let tooltipStr = '<strong>' + d.groupName + '</strong><br>';
    tooltipStr += '<strong>' + d.subGroupName + '</strong><br>';
    tooltipStr += '<strong>' + Number(d.dv).toFixed(decimalPlaces) + d.dvu + '</strong>';
    if (isNumber(d.lci) && isNumber(d.hci)) {
      tooltipStr += '<br>' + confidenceIntervalLabel + ' (' + Number(d.lci).toFixed(decimalPlaces)
        + ' - ' + Number(d.hci).toFixed(decimalPlaces) + ')';
    }
    if (isNumber(d.ss)) {
      tooltipStr += '<br>' + sampleSizeSymbol + ' = ' + Number(d.ss).toLocaleString();
    }
    return tooltipStr;
  }

  function OnMouseOver(thisCircle, color, d, event) {
    barTooltip.transition()
      .duration(450)
      .style('opacity', 1);
    barTooltip.html(getTooltipStr(d))
      .style('left', (event.pageX + 15) + 'px')
      .style('top', (event.pageY - 20) + 'px');
    d3.select(thisCircle)
      .attr('d', d3.symbol().type(d3.symbolCircle).size(200))
      .attr('class', 'pointShadow')
      .style('stroke', color)
  }

  function OnMouseOut(thisCircle, d, event) {
    barTooltip.transition()
      .duration(250)
      .style('opacity', 0)
    d3.select(thisCircle)
      .attr('d', d3.symbol().type(d3.symbolCircle).size(100))
      .attr('class', 'point')
      .style("stroke", "transparent")
  }

  /////////////////////////////////////////////
  ////////// LEGEND --------------------------
  var longestSubGroupName = subGroupNames.sort(function (a, b) { return b.length - a.length; })[0];
  var longestSubGroupNameInPixel = getStringLengthInPixel(longestSubGroupName);

  let legendPositionX = svgAreaWidth / 2 - longestSubGroupNameInPixel / 2 + 20;
  let legendPositionY = svgAreaHeight - legendSpace + 20;

  //the subGroupNames.sort above cuases the sort order of SubGroupNames out of sync.
  //Retrieve it again
  subGroupNames = _.uniq(dataObjSorted.map((r) => r.subGroupName));

  svgArea.append('text')
    .attr('class', 'legendTitle')
    .attr('x', legendPositionX - 10)
    .attr('y', legendPositionY)
    .attr('text-anchor', 'start')
    .text(legendTitleStr);

  var legend = svgArea.selectAll(".legend")
    .data(subGroupNames)
    .enter().append("g")
    .attr("class", "legendLabel")

  legend.append("path")
    .attr("fill", (d, i) => color(i))
    .attr("stroke", (d, i) => color(i))
    .attr('d', d3.symbol().type(d3.symbolCircle).size(100))
    .attr("transform", (d, i) => `translate(${legendPositionX}, ${legendPositionY + 20 + i * 20})`);

  legend.append("line")
    .attr("stroke", (d, i) => color(i))
    .attr("stroke-width", "2")
    .attr("x1", legendPositionX - 15)
    .attr("y1", (d, i) => legendPositionY + 20 + i * 20)
    .attr("x2", legendPositionX + 15)
    .attr("y2", (d, i) => legendPositionY + 20 + i * 20);

  legend.append("text")
    .attr("x", legendPositionX + 25)
    .attr("y", (d, i) => legendPositionY + 20 + i * 20)
    .attr("dy", ".35em")
    .style("text-anchor", "start")
    .text(function (d) { return d; });

  /////////////////////////////////////////////
  //--- Get stirng length in pixel ----------------
  function getStringLengthInPixel(strInput) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.font = "14px";
    var width = ctx.measureText(strInput).width;
    return width;
  }

  function getTickLength(tickLength) {
    while (tickLength > maxTicks) {
      tickLength = tickLength / 2;
    }
    return tickLength;
  }
}

export default makeChartGroupLine;
