<template>
  <div>
    <!-- Filter -->
    <div class="stratContainer">
      <!--Indicator -->
      <!-- <fieldset class="form-group" v-if="compareAllIndicator">
          <label class="col-form-label-sm" >Indicator</label>
          <div class="col-form-label-sm font-normal">Compare All</div>
        </fieldset> -->
      <fieldset>
        <legend class="fs-legend">Data Selections</legend>
        <fieldset class="form-group">
          <label for="gsIndicator" class="col-form-label-sm">Indicator</label>
          <select
            id="gsIndicator"
            v-model="GS.indicatorId"
            class="form-select form-select-sm"
            @change="indicatorChanged()"
          >
            <option v-for="item in indicators" :key="item.indicatorId" :value="item.indicatorId">
              {{ item.name }}
            </option>
          </select>
        </fieldset>
        <!-- Location -->
        <fieldset class="form-group">
          <label for="gsLocation" class="col-form-label-sm">Location</label>
          <select
            id="gsLocation"
            v-model="GS.locationId"
            class="form-select form-select-sm"
            @change="locationChanged()"
          >
            <option v-for="item in locations" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </fieldset>
        <!-- Data Source -->
        <fieldset class="form-group">
          <label for="gsDataSource" class="col-form-label-sm">Data Source</label>
          <select
            id="gsDataSource"
            v-model="GS.dataSourceId"
            class="form-select form-select-sm"
            @change="datasourceChanged()"
          >
            <option v-for="item in datasources" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <!-- <div class="smallerText pt-1">
            <a
              :href="dataSourceInfoURL"
              target="blank"
              @click="dataSourceInfoLinkClicked">
              Data Source Information
            </a>
          </div> -->
        </fieldset>
      </fieldset>
    </div>
  </div>
</template>
<!-- /////////////////////////// SCRIPT //////////////////////////////// -->
<script>
/* global triggerOmnitureInteractions */
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import helper from '../../helper';

export default {
  data() {
    return {
      GS: {
        view: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'View'),
        indicatorId: '',
        locationId: '',
        dataSourceId: '',
        locations: [],
        datasources: [],
        indicatorName: '',
      },
      useCurrentIdIfValid: true,
    };
  },
  // /////////////////////////// COMPUTED ////////////////////////////////
  computed: {
    ...mapState({
      GSGo: (state) => state.LP.GSGo,
      lku: (state) => state.lku,
      dsLink: (state) => state.dsLink,
      footnotes: (state) => state.footnotes,
      dsLocationLink: (state) => state.dsLocationLink,
      dsQuestionLocationLink: (state) => state.dsQuestionLocationLink,
      level4Name: (state) => state.LP.GSGo.level4Name,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
      locationId: (state) => state.LP.GSGo.locationId,
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      initialPageLoad: (state) => state.flags.initialPageLoad,
      loadingDSLinkLocationApiData: (state) => state.flags.loadingDSLinkLocationApiData,
      loadingQuestionLocationApiData: (state) => state.flags.loadingQuestionLocationApiData,
      changingToNationalChartTableView: (state) => state.LP.flags.changingToNationalChartTableView,
      changingToStateChartTableView: (state) => state.LP.flags.changingToStateChartTableView,
      changingFromNationalChartTableToMapView: (state) => state.LP.flags.changingFromNationalChartTableToMapView,
      // prettier-ignore
      changingFromNationalChartTableToChartOrTableView: (state) => state.LP.flags.changingFromNationalChartTableToChartOrTableView,
      changingFromStateChartTableToMapView: (state) => state.LP.flags.changingFromStateChartTableToMapView,
      // prettier-ignore
      changingFromStateChartTableToChartOrTableView: (state) => state.LP.flags.changingFromStateChartTableToChartOrTableView,
      view: (state) => state.LP.view,
      nationalId: (state) => state.LP.nationalId,
      globalSelectorChanged: (state) => state.LP.flags.globalSelectorChanged,
      reSelectLevel4: (state) => state.LP.flags.reSelectLevel4,
      reportDataIds: (state) => state.LP.reportDataIds,
      singleLocation: (state) => state.LP.singleLocation,
      defaultDsId: (state) => state.defaultDataSourceId,
    }),
    ...mapGetters(['printerFriendly']),
    indicators() {
      return this.GSGo.level4Names;
    },
    locations() {
      return this.GS.locations;
    },
    datasources() {
      return this.GS.datasources;
    },
    gsIndicatorId() {
      return this.GS.indicatorId;
    },
    gsDataSourceId() {
      return this.GS.dataSourceId;
    },
    gsLocationId() {
      if (this.GS.locationId === '' && this.view === 'NationalChartTable') {
        return this.nationalId;
      }
      return this.GS.locationId;
    },
    dataSourceInfoURL() {
      const row = _(this.footnotes).find({ type: 'IndicatorResources1', sc: this.GS.dataSourceId });
      if (row) {
        return helper.convertRelativeUrlToWCMSUrl(row.url);
      }
      return process.env.VUE_APP_MAIN_DataSourceTypeInfoUrl;
    },
  },
  // ////////////////////////// CREATED ////////////////////////////
  // This load one time only when the page load
  created() {
    this.GS.indicatorId = this.indicatorId;
    this.GS.locationId = this.locationId;
    this.GS.dataSourceId = this.dataSourceId;
  },
  // //////////////////////////// WATCH ///////////////////////////
  watch: {
    indicators: {
      handler() {
        if (this.initialPageLoad || this.globalSelectorChanged) {
          this.useCurrentIdIfValid = true;
        } else if (!this.initialPageLoad && this.reSelectLevel4) {
          this.useCurrentIdIfValid = true;
        }
        this.setIndicatorId();
      },
      deep: true,
    },
    locations: {
      handler() {
        if (this.initialPageLoad || this.globalSelectorChanged) {
          this.useCurrentIdIfValid = true;
        } else if (!this.initialPageLoad && this.reSelectLevel4) {
          this.useCurrentIdIfValid = true;
        }
        this.setLocationId();
      },
      deep: true,
    },
    datasources: {
      handler() {
        if (this.initialPageLoad || this.globalSelectorChanged) {
          this.useCurrentIdIfValid = true;
        } else if (!this.initialPageLoad && this.reSelectLevel4) {
          this.useCurrentIdIfValid = true;
        }
        this.setDataSourceId();
      },
      deep: true,
    },
    level4Name() {
      this.GS.indicatorId = this.indicatorId;
    },
    loadingQuestionLocationApiData() {
      if (this.loadingQuestionLocationApiData === false) {
        this.ProcessIndicator();
      }
    },
    loadingDSLinkLocationApiData() {
      if (this.loadingDSLinkLocationApiData === false) {
        this.GS.datasources = this.getDatasources(this.GS.indicatorId, this.gsLocationId);
        if (this.GS.datasources && this.GS.datasources.length > 0) {
          this.ProcessDataSource();
        }
      }
    },
    gsIndicatorId() {
      if (!this.initialPageLoad && !this.reSelectLevel4) {
        // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-INDICATOR,${this.gsIndicatorId}`);
        this.$store.commit('setIndicatorChanged', true);
        this.ProcessIndicator();
      }
    },
    gsLocationId() {
      // ChangingToNationalChartTableView: location changed from '' to 59
      // ChangingFromNationalChartTableToMapView: location changed from 59 to ''
      // ChangingFromNationalChartTableToChartOrTableView: location changed from 59 to ''
      if (!this.initialPageLoad && !this.reSelectLevel4) {
        // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-LOCATION,${this.gsLocationId}`);
        this.ProcessLocation();
      }
    },
    gsDataSourceId() {
      if (!this.initialPageLoad && !this.reSelectLevel4 && this.datasources.length > 0) {
        // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-SOURCE,${this.gsDataSourceId}`);
        this.ProcessDataSource();
      }
    },
    changingToStateChartTableView() {
      if (this.changingToStateChartTableView === true) {
        this.ProcessDataSource();
      }
    },
    changingFromStateChartTableToChartOrTableView() {
      if (this.changingFromStateChartTableToChartOrTableView === true) {
        this.ProcessDataSource();
      }
    },
    changingFromStateChartTableToMapView() {
      if (this.changingFromStateChartTableToMapView === true) {
        this.ProcessDataSource();
      }
    },
    indicatorId() {
      this.GS.indicatorId = this.indicatorId;
    },
  },
  // ////////////////////////// METHOD ////////////////////////////
  methods: {
    ...mapActions(['getDSLinkLocationApiData', 'getIndicatorAllIdsAndSetIndicatorId']),

    getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey) {
      return this.$store.getters.getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey);
    },
    currentOrDefaultIndicatorId(list, currentId) {
      if (list.length === 0) {
        return '';
      }
      if (currentId) {
        if (_.filter(list, { indicatorId: currentId }).length > 0) {
          return currentId;
        }
      }
      // Select one plus more than one item, default to Select one
      return list[0].id;
    },
    currentOrDefaultId(list, currentId) {
      if (list.length === 0) {
        return '';
      }
      if (currentId) {
        if (_.filter(list, { id: currentId }).length > 0) {
          return currentId;
        }
      }
      // Select one plus more than one item, default to Select one
      return list[0].id;
    },
    ProcessIndicator() {
      const selectedIndicator = this.indicators.find((e) => e.indicatorId === this.GS.indicatorId);
      if (selectedIndicator && this.dsQuestionLocationLink && this.dsQuestionLocationLink.length > 0) {
        // 1.Init.
        // const responseId = helper.getResponseId(this.GS.indicatorId);
        // if (responseId === '') {
        //   this.$store.commit('setView', 'NationalChart');
        // }
        // const { indicatorId } = selectedIndicator;
        this.GS.indicatorName = selectedIndicator.name;
        // this.GS.locations = [];
        // this.GS.datasources = [];
        // 2.process location
        this.GS.locations = this.getLocations(this.GS.indicatorId);
        if (this.gsLocationId !== '' && !this.isQuestionLocationExist(this.gsLocationId)) {
          this.GS.locationId = '';
        }
        this.ProcessLocation();
      }
    },
    ProcessLocation() {
      if (this.gsLocationId) {
        this.apiRefreshIDSLinkLocationApiData(this.GS.indicatorId, this.gsLocationId);
        // call this.ProcessDataSource() in loadingDSLinkLocationApiData
      } else {
        this.GS.datasources = this.getDatasources(this.GS.indicatorId, this.gsLocationId);
        this.ProcessDataSource();
      }
    },
    ProcessDataSource() {
      // validate datasourceId
      // Make sure IndicatorId, LocationId, and DatasourceId values are set
      // this.setIndicatorId(); // no need to set here since it is already having value above
      // this.setLocationId(); // no need to set here since it is already having value above
      /* eslint-disable */
      this.useCurrentIdIfValid = true;
      this.setDataSourceId();

      const foundDs = _(this.GS.datasources).filter({ id: this.GS.dataSourceId }).map('id').uniq().value();
      if (foundDs && foundDs.length > 0) {
        // 1.update selection to the store
        const payload = {};
        Object.assign(payload, this.GSGo);
        payload.indicatorId = this.GS.indicatorId;
        payload.level4Name = this.GS.indicatorName;
        payload.locationId = this.GS.locationId; // not set for specific locaiton 59
        payload.location = helper.getLocationName(this.GS.locationId, this.locations);
        payload.dataSourceId = this.GS.dataSourceId;
        payload.dataSource = this.lku.DataSource[this.GS.dataSourceId].name;
        payload.countyFlag = this.viewAscountyMap();
        payload.countyExist = this.countyExist();
        this.$store.commit('setGSGo', payload);
        this.$store.commit('setGoClicked', true);

        // Updata view
        this.updateView();
        this.checkLocations();

        // 3.refresh data
        this.apiRefreshIndicatorAllIds(); // this.GS.indicatorId, this.gsLocationId

        // 4.Omniture
        // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-IS ${helper.getLocationIdForOmniture(this.gsLocationId)},${this.GS.level1Name}>${this.GS.level2Name},${this.GS.level3Name},${this.GS.indicatorName}>${this.GS.indicatorId}`);
        if (this.initialPageLoad || this.reSelectLevel4) {
          triggerOmnitureInteractions(
            `${process.env.VUE_APP_InteractionIdentifier} - ONLOAD, INDICATOR, ${payload.indicatorId}`,
          );
          triggerOmnitureInteractions(
            `${process.env.VUE_APP_InteractionIdentifier} - ONLOAD, LOCATION, ${this.getLocaitonIdForOmniture(
              payload.locationId,
            )}`,
          );
          triggerOmnitureInteractions(
            `${process.env.VUE_APP_InteractionIdentifier} - ONLOAD, SOURCE, ${payload.dataSourceId}`,
          );
        }

        // 5. Update URL
        // if (!this.initialPageLoad) {
        //   this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
        // }
      } else {
        // the datasourceId will default to the first one, and this.ProcessDataSource() will be called.
        // so this.ProcessDataSource() will bot be called twice that causes the page emtpy if the old databaseId is not found
      }
    },
    updateView() {
      const responseId = helper.getResponseId(this.GS.indicatorId);
      let viewType = 'NationalMap';
      // const gsViewType = this.GS.view;
      // viewType is emtpy when first load
      // viewType has value when URL passed in.
      // becuase this.GS.view is set to empty at the end of the function,
      // viewType will be empty again
      // //////////////////////////////
      if (this.view === '') {
        if (responseId === '' && this.gsLocationId === '') {
          // NationalChart - NationalTable
          viewType = 'NationalChart';
        }
        if (responseId === '' && this.gsLocationId !== '') {
          // 1. if there are counties:
          //    - StateChart - StateTable
          //    - Comparision 2 NOT displayed (because View As Counties always checked)
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - No butons displayed (display Chart + Table)
          //    - Comparision 2 is displayed
          viewType = 'StateChart';
        }
        if (responseId !== '' && this.gsLocationId === '') {
          // 1. if there are counties:
          //    - NationalMap - NationalCountyMap - NationalChart - NationalTable
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - NationalMap - NationalChart - NationalTable
          viewType = 'NationalMap';
        }
        if (responseId !== '' && this.gsLocationId !== '') {
          // StateMap - StateChart - StateTable
          // 1. if there are counties:
          //    - StateMap - StateChart - StateTable
          //    - Comparision 1 is displayed
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - No butons displayed (display Chart + Table)
          //    - Comparision 1 AND 2 are displayed
          viewType = 'StateMap';
        }
      }
      // //////////////////////////
      if (this.view !== '') {
        viewType = this.view;

        if (responseId === '' && this.gsLocationId === '') {
          // [NationalChart,NationalTable]
          if (['NationalChartTable', 'NationalChart', 'NationalTable'].includes(viewType) === false) {
            viewType = 'NationalChart';
          }
        }
        if (responseId === '' && this.gsLocationId === this.nationalId) {
          // 1. if there are counties:
          //    - NationalChartTable)
          //    - Comparision 1 NOT displayed (because this is Compare All already)
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - No butons displayed (display Chart + Table)
          //    - Comparision 2 is displayed
          if (['NationalChartTable'].includes(viewType) === false) {
            viewType = 'StateChart';
          }
        }
        if (responseId === '' && this.gsLocationId !== '' && this.gsLocationId !== this.nationalId) {
          // 1. if there are counties:
          //    - NationalChartTable, StateChart - StateTable (NationalChartTable for location 59)
          //    - Comparision 2 NOT displayed (because View As Counties always checked)
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - No butons displayed (display Chart + Table)
          //    - Comparision 2 is displayed
          if (['StateChartTable', 'StateChart', 'StateTable'].includes(viewType) === false) {
            viewType = 'StateChart';
          }
        }
        if (responseId !== '' && this.gsLocationId === '') {
          // 1. if there are counties:
          //    - NationalMap - NationalChart - NationalTable - NationalCountyMap
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - NationalMap - NationalChart - NationalTable
          if (['NationalMap', 'NationalChart', 'NationalTable', 'NationalCountyMap'].includes(viewType) === false) {
            viewType = 'NationalMap';
          }
        }
        if (responseId !== '' && this.gsLocationId === this.nationalId) {
          // 1. if there are counties:
          //    - NationalChartTable
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - NationalMap - NationalChart - NationalTable
          if (['NationalChartTable'].includes(viewType) === false) {
            viewType = 'NationalChartTable';
          }
        }
        if (responseId !== '' && this.gsLocationId !== '' && this.gsLocationId !== this.nationalId) {
          // StateMap - StateChart - StateTable
          // 1. if there are counties:
          //    - StateMap - StateChart - StateTable
          //    - Comparision 1 is displayed
          // 2. if there are NO counties (e.g. like National or Iris datasource):
          //    - No butons displayed (display Chart + Table)
          //    - Comparision 1 AND 2 are displayed
          if (['StateChartTable', 'StateMap', 'StateChart', 'StateTable'].includes(viewType) === false) {
            viewType = 'StateMap';
          }
        }
      }
      /////////////////////////////////////////
      //special: check for age group
      if (this.isDetailedAgeGroupIndicatorSelected()) {
        if (this.initialPageLoad && this.GS.view !== '') {
        } else {
          if (this.GS.locationId === '') {
            viewType = 'NationalChartTable';
          } else {
            viewType = 'StateChartTable';
          }
        }
      }
      // //////////////////////////////////////
      this.$store.commit('setView', viewType);
      this.GS.view = ''; // reset this becuase it is intented to use when URL passed in for the first time only
    },
    getLocations(indicatorId) {
      // 1. get locations of the question
      const dsQL = this.getQuestionResponseLocations(indicatorId);
      // 2.get all locations
      const flattened = _(this.lku.Location).reduce((lo, val) => lo.concat([val]), []);
      // filter all locations with locatins of this question
      const result = [];
      _.forEach(flattened, (n) => {
        _.forEach(dsQL, (n2) => {
          if (n.id === n2) {
            // Not add National to the list
            if (n.name !== 'National' && n.name !== 'national') {
              result.push(n);
            }
          }
        });
      });
      // 4.return result
      // return helper.addAllLocation(_(flattened).orderBy('sort', 'asc').value());
      // return helper.addAllLocation(_(result).orderBy('sort', 'asc').value());
      // if (this.GS.view === 'NationalChartTable' || this.view === 'NationalChartTable') {
      //   return helper.addNationalLocationWithId(_(result).orderBy('sort', 'asc').value(), null, this.nationalId);
      // }
      return helper.addNationalLocation(_(result).orderBy('sort', 'asc').value());
    },
    getDatasources(indicatorId, locationId) {
      const questionId = helper.getQuestionId(indicatorId);
      const responseId = helper.getResponseId(indicatorId);
      // console.log('dsLink');
      // console.log(this.dsLink);
      // console.log('dsLocationLink');
      // console.log(this.dsLocationLink);
      // console.log('lku.DataSource');
      // console.log(this.lku.DataSource);
      if (questionId) {
        let ds = [];
        if (locationId === '') {
          if (responseId === '') {
            ds = _(this.dsLink)
              .filter({ qid: questionId })
              .map('dsid')
              .uniq()
              .map((id) => this.lku.DataSource[id])
              .orderBy('sort', 'asc')
              .value();
          } else {
            ds = _(this.dsLink)
              .filter({ qid: questionId, rid: responseId })
              .map('dsid')
              .uniq()
              .map((id) => this.lku.DataSource[id])
              .orderBy('sort', 'asc')
              .value();
          }
        } else if (responseId === '') {
          ds = _(this.dsLocationLink)
            .filter({ loc: locationId, qid: questionId })
            .map('dsid')
            .uniq()
            .map((id) => this.lku.DataSource[id])
            .orderBy('sort', 'asc')
            .value();
        } else {
          ds = _(this.dsLocationLink)
            .filter({ loc: locationId, qid: questionId, rid: responseId })
            .map('dsid')
            .uniq()
            .map((id) => this.lku.DataSource[id])
            .orderBy('sort', 'asc')
            .value();
        }
        return ds;
      }
      return [];
    },
    apiRefreshIndicatorAllIds() {
      // this set indicator's compare values
      this.getIndicatorAllIdsAndSetIndicatorId({ indicatorId: this.GS.indicatorId, locationId: this.gsLocationId });
    },
    apiRefreshIDSLinkLocationApiData(indicatorId, locationId) {
      const questionId = helper.getQuestionId(indicatorId);
      const responseId = helper.getResponseId(indicatorId);
      this.getDSLinkLocationApiData({ locationId, questionId, responseId });
    },
    viewAscountyMap() {
      const countyDataSetRecord = _(this.dsLocationLink).find({
        dsid: this.GS.dataSourceId,
        dataSetType: 'C',
        loc: this.gsLocationId,
      });
      let viewCountyMap = 'N';
      if (countyDataSetRecord !== undefined) {
        viewCountyMap = 'Y';
        // this.GS.view is from ULR which render only one time when loaded
        // thus, it is safe to check it here
        if (this.view === 'StateChartTable' || this.GS.view === 'StateChartTable') {
          viewCountyMap = 'N';
        } else if (this.isDetailedAgeGroupIndicatorSelected() && this.GS.locationId !== '') {
          if (!(this.initialPageLoad && this.GS.view !== '')) {
            viewCountyMap = 'N'; //viewType = 'StateChartTable';
          }
        }
      }
      return viewCountyMap;
      // return (countyDataSetRecord !== undefined);
    },
    isDetailedAgeGroupIndicatorSelected() {
      if (helper.getDetailedAgeGroupIndicators().includes(this.GS.indicatorId)) {
        if (
          !this.changingFromNationalChartTableToMapView &&
          !this.changingFromNationalChartTableToChartOrTableView &&
          !this.changingFromStateChartTableToMapView &&
          !this.changingFromStateChartTableToChartOrTableView
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    countyExist() {
      const countyDataSetRecord = _(this.dsLocationLink).find({
        dsid: this.GS.dataSourceId,
        dataSetType: 'C',
        loc: this.gsLocationId,
      });
      let isCountyExist = 'N';
      if (countyDataSetRecord !== undefined) {
        isCountyExist = 'Y';
      }
      return isCountyExist;
    },
    getQuestionResponseLocations(indicatorId) {
      const responseId = helper.getResponseId(indicatorId);
      let dsQL = [];
      if (responseId) {
        dsQL = _(this.dsQuestionLocationLink).filter({ rid: responseId }).map('loc').uniq().value();
      } else {
        dsQL = _(this.dsQuestionLocationLink).map('loc').uniq().values().value();
      }
      return dsQL;
    },
    isQuestionLocationExist(locationId) {
      const dsQL = this.getQuestionResponseLocations();
      if (dsQL.includes(locationId)) {
        return true;
      }
      return false;
    },
    dataSourceInfoLinkClicked() {
      // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-IB DS ${helper.getLocationIdForOmniture(this.gsLocationId)},${this.GS.level1Name}>${this.GS.level2Name},${this.GS.level3Name},${this.GS.indicatorName}>${this.GS.indicatorId}>${this.GS.dataSourceId}`);
    },
    setIndicatorId() {
      this.GS.indicatorId = helper.defaultOrFirstId(this.indicators, this.GS.indicatorId, this.useCurrentIdIfValid);
    },
    setLocationId() {
      this.GS.locationId = helper.defaultOrFirstId(this.locations, this.GS.locationId, this.useCurrentIdIfValid);
    },
    setDataSourceId() {
      if (this.reSelectLevel4) {
        let defaultDsId = this.GSGo.dataSourceId === '' ? this.defaultDsId : this.GSGo.dataSourceId;
        this.GS.dataSourceId = helper.defaultOrFirstId(this.datasources, defaultDsId, this.useCurrentIdIfValid);
      }
      this.GS.dataSourceId = helper.defaultOrFirstId(this.datasources, this.GS.dataSourceId, this.useCurrentIdIfValid);
    },
    checkLocations() {
      if (this.locations.length === 1) {
        this.$store.commit('setSingleLocation', true);
        const thisShowMode = this.$route.query.showMode;
        if (
          this.view === 'NationalMap' ||
          this.view === 'NationalCountyMap' ||
          this.view === 'NationalChart' ||
          this.view === 'NationalTable'
        ) {
          //thisShowMode !== 'export'
          this.$store.commit('setView', 'NationalChartTable');
          this.$store.commit('setHideCountryButtons', true);
        }
        if (this.view === 'StateMap' || this.view === 'StateChart' || this.view === 'StateTable') {
          //|| thisShowMode !== 'export'
          this.$store.commit('setView', 'StateChartTable');
        }
        // this.$store.commit('setGeneratingPdf', false);
      } else {
        // this.$store.commit('setHideNationalMapIcon', false);
        this.$store.commit('setSingleLocation', false);
      }
    },
    indicatorChanged() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - VIZFILTER, INDICATOR, ${this.gsIndicatorId}`,
      );
    },
    locationChanged() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - VIZFILTER, LOCATION, ${this.getLocaitonIdForOmniture(
          this.gsLocationId,
        )}`,
      );
    },
    datasourceChanged() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - VIZFILTER, SOURCE, ${this.gsDataSourceId}`,
      );
    },
    getLocaitonIdForOmniture(currentLocationId) {
      if (currentLocationId === '') {
        return 'Nat';
      }
      return currentLocationId;
    },
  },
};
</script>
<!-- // ////////////////////////// STYLE ///////////////////////////////// -->
<style scoped>
.stratContainer {
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.font-normal {
  font-weight: normal !important;
}
</style>
