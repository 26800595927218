<template>
  <div>
    <fieldset class="form-group">
      <div class="no-wrap">
        <div class="displayInline">
          <label for="gsDataSource" class="col-form-label-sm">Data Source</label>
        </div>
        <div class="displayInline ms-1">
          <select
            id="gsDataSource"
            :value="modelValue"
            class="form-select form-select-sm"
            @change="updateValue($event.target.value)"
          >
            <option key="" value="" class="dropdown-multi-level">
              <span>All Data Sources</span>
            </option>
            <optgroup
              :label="dt.abbr"
              v-for="dt in dataSourceTypes"
              :key="dt.id"
              :value="dt.id"
              class="dropdown-multi-level"
            >
              <option v-for="ds in getDataSources(dt.id)" :key="ds.id" :value="ds.id">
                {{ ds.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<!-- ////////////////////////////////////////////////////////////////////////////////// -->
<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'GlobalSelectorDataSource',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      lku: (state) => state.lku,
    }),
    dataSourceTypes() {
      const ds = _(this.lku.DataSourceType).orderBy('sort', 'asc').value();
      return ds;
      // const emptyDs = { id: '', abbr: 'All Data Sources' };
      // return [emptyDs, ...ds];
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    getDataSources(dataSourceType) {
      const ds = _(this.lku.DataSource).filter({ namealias: dataSourceType }).orderBy('sort', 'asc').value();
      return ds;
    },
  },
};
</script>
