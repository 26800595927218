const mutations = {
  setShowHeadingsAndFilters(state, val) {
    state.flags.showHeadingsAndFilters = val;
  },
  setGoClicked(state, val) {
    state.flags.goClicked = val;
  },
  setLoadingIndicatorAllIds(state, val) {
    state.flags.loadingIndicatorAllIds = val;
  },
  setLoadingReportDataIds(state, val) {
    state.flags.loadingReportDataIds = val;
  },
  setGlobalSelectorChanged(state, val) {
    state.flags.globalSelectorChanged = val;
  },
  setReSelectLevel4(state, val) {
    state.flags.reSelectLevel4 = val;
  },
  setIndicatorChanged(state, val) {
    state.flags.indicatorChanged = val;
  },
  setShowFootnotes(state, val) {
    state.flags.showFootnotes = val;
  },
  setGeneratingPdf(state, val) {
    state.flags.generatingPdf = val;
  },
  setGSGo(state, obj) {
    state.GSGo = Object.assign(state.GSGo, {
      level1Name: obj.level1Name,
      level2Name: obj.level2Name,
      level3Name: obj.level3Name,
      level4Name: obj.level4Name,
      level4Names: obj.level4Names,
      indicatorId: obj.indicatorId,
      dataSourceId: obj.dataSourceId,
      dataSource: obj.dataSource,
      locationId: obj.locationId,
      location: obj.location,
      gsDataSourceId: obj.gsDataSourceId,
      gsLocationId: obj.gsLocationId,
      riskFactorSubCatId: obj.riskFactorSubCatId,
      countyFlag: obj.countyFlag,
      countyExist: obj.countyExist,
    });
    // state.flags.goClicked = true;
  },
  setIndicatorAllIds(state, arr) {
    state.indicatorAllIds = arr;
  },
  setStratFilters(state, obj) {
    state.stratFilters.compareId = obj.compareId;
    state.stratFilters.yearId = obj.yearId;
    state.stratFilters.responseId = obj.responseId;
    state.stratFilters.ageId = obj.ageId;
    state.stratFilters.genderId = obj.genderId;
    state.stratFilters.raceId = obj.raceId;
    state.stratFilters.riskFactorId = obj.riskFactorId;
    state.stratFilters.riskFactorResponseId = obj.riskFactorResponseId;
    state.stratFilters.dataValueTypeId = obj.dataValueTypeId;
    state.stratFilters.compareViewYear = obj.compareViewYear;
    state.stratFilters.compareId2 = obj.compareId2;
  },
  setView(state, val) {
    state.view = val;
  },
  setReportDataIds(state, arr) {
    state.reportDataIds = arr;
  },
  setMapClassifierId(state, val) {
    state.mapClassifierId = val;
  },
  setMapClassifierCount(state, val) {
    state.mapClassifierCount = val;
  },
  setHideCountryButtons(state, val) {
    state.flags.hideCountryButtons = val;
  },
  setHideNationalMapIcon(state, val) {
    state.flags.hideNationalMapIcon = val;
  },
  setHideStateMapIcon(state, val) {
    state.flags.hideStateMapIcon = val;
  },
  setCompareAllIndicator(state, val) {
    state.compareAllIndicator = val;
  },
  /// ///////////////////////////////////////////////
  setChangingFromNationalChartTableToMapView(state, val) {
    state.flags.changingFromNationalChartTableToMapView = val;
  },
  setCountryCountyLoadingReportDataIds(state, val) {
    state.flags.loadingCountryCountyReportDataIds = val;
  },
  setCountryCountyReportDataIds(state, arr) {
    state.countryCountyReportDataIds = arr;
  },
  setShowCountryCountyMapIcon(state, val) {
    state.flags.showCountryCountyMapIcon = val;
  },
  setChangingToNationalChartTableView(state, val) {
    state.flags.changingToNationalChartTableView = val;
  },
  setChangingToStateChartTableView(state, val) {
    state.flags.changingToStateChartTableView = val;
  },
  setChangingFromStateChartTableToChartOrTableView(state, val) {
    state.flags.changingFromStateChartTableToChartOrTableView = val;
  },
  setChangingFromNationalChartTableToChartOrTableView(state, val) {
    state.flags.changingFromNationalChartTableToChartOrTableView = val;
  },
  setChangingFromNationalChartOrTableToMapView(state, val) {
    state.flags.changingFromNationalChartOrTableToMapView = val;
  },
  setChangingFromStateChartTableToMapView(state, val) {
    state.flags.changingFromStateChartTableToMapView = val;
  },
  setChangingFromStateChartOrTableToMapView(state, val) {
    state.flags.changingFromStateChartOrTableToMapView = val;
  },
  setChangingFromNationalMapToChartOrTableView(state, val) {
    state.flags.changingFromNationalMapToChartOrTableView = val;
  },
  setChangingFromStateMapToChartOrTableView(state, val) {
    state.flags.changingFromStateMapToChartOrTableView = val;
  },
  setSingleLocation(state, val) {
    state.singleLocation = val;
  },
  setLastCompareValue1(state, val) {
    state.lastCompareValue1 = val;
  },
  setLastCompareValue2(state, val) {
    state.lastCompareValue2 = val;
  },
  setLastGetIndicatorAllIdsUrl(state, val) {
    state.lastGetIndicatorAllIdsUrl = val;
  },
  setLastGetReportDataIdsUrl(state, val) {
    state.lastGetReportDataIdsUrl = val;
  },
  setLastGetCountryCountyReportDataIdsIdsUrl(state, val) {
    state.lastGetCountryCountyReportDataIdsIdsUrl = val;
  },
};

export default mutations;
