import classifyData from './ClassifyDataNT';
import getColorInRange from './ColorScale';

function getClassificationType(classificationType) {
  let mapClassificationType = 1;
  switch (classificationType) {
    case 'quantile':
      mapClassificationType = 1;
      break;

    case 'natural-breaks':
      mapClassificationType = 2;
      break;

    case 'equal-interval':
      mapClassificationType = 3;
      break;

    default:
      mapClassificationType = 1;
      break;
  }

  return mapClassificationType;
}

const dataClassification = {
  classify: classifyData,
  getColorInRange,
  getClassificationType,
};

export default dataClassification;
