/* global triggerOmnitureInteractions */
import { mapState, mapGetters, mapActions } from 'vuex';
import FileSaver from 'file-saver';
import GlobalSelector from './GlobalSelector.vue';
import DashboardHeader from './DashboardHeader.vue';
import ViewButtons from './ViewButtons.vue';
import StratFilters from './StratFilters.vue';
import VisualizationTitle from './VisualizationTitle.vue';
import TableView from './TableView.vue';
import MapViewContainer from './Maps/MapViewContainer.vue';
import ChartView from './ChartView.vue';
import PanelFootnotes from './PanelFootnotes.vue';
import PageFootnotes from './PageFootnotes.vue';
import api from '../../api';
// import helper from '../../helper';

export default {
  name: 'LargePanel',
  components: {
    GlobalSelector,
    DashboardHeader,
    ViewButtons,
    StratFilters,
    VisualizationTitle,
    TableView,
    MapViewContainer,
    ChartView,
    PanelFootnotes,
    PageFootnotes,
  },
  data() {
    return {
      noDataForAllLocation: false,
      lastIndicatorSelection: '',
    };
  },
  created() {
    this.setStateFromQueryStringParms();
    // this.getInitialApiData(this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'LocationId'));
    this.getInitialApiData();
  },
  computed: {
    ...mapState({
      showHeadingsAndFilters: (state) => state.LP.flags.showHeadingsAndFilters,
      showFootnotes: (state) => state.LP.flags.showFootnotes,
      view: (state) => state.LP.view,
      changingFromNationalChartTableToMapView: (state) => state.LP.flags.changingFromNationalChartTableToMapView,
      GSGo: (state) => state.LP.GSGo,
      indicatorId: (state) => state.LP.GSGo.indicatorId,
      stratFilters: (state) => state.LP.stratFilters,
      apiErrors: (state) => state.apiErrors,
      dataErrors: (state) => state.dataErrors,
      generatingPdf: (state) => state.LP.flags.generatingPdf,
      reportDataIds: (state) => state.LP.reportDataIds,
      locationId: (state) => state.LP.GSGo.locationId,
      countryCountyReportDataIds: (state) => state.LP.countryCountyReportDataIds,
      nationalId: (state) => state.LP.nationalId,
      changingFromStateChartTableToMapView: (state) => state.LP.flags.changingFromStateChartTableToMapView,
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      singleLocation: (state) => state.LP.singleLocation,
    }),
    ...mapGetters([
      'printerFriendly',
      'indicator',
      'showWaitSpinner',
      'queryStringForExportToCsv',
      'queryStringForSelectedView',
      'yearInteractionCode',
      'compareViewInteractionCode',
      'countyFlag',
    ]),
    showPdfButton() {
      return false;
      // temporarily hide rhe PDF button for now because Country County map return empty on PDF
      // temporarily comment out the code below
      // if (this.locationId === '' || this.countyFlag === 'Y') {
      //   if (this.GSGo.dataSourceId === 'IRIS' && (this.view === 'NationalMap' || this.view === 'NationalCountyMap'
      //       || this.view === 'StateMap')) return true;
      //   if (this.GSGo.dataSourceId !== 'IRIS' && (this.view !== 'NationalChart' && this.view !== 'StateChart')) return true;
      //   if (this.view === 'NationalChartTable') return true; // for National location 59
      //   return false;
      // }

      // if (this.view === 'NationalChart' || this.view === 'StateChart'
      //   || this.view === 'NationalTable' || this.view === 'StateTable') return true;
      // return false;
    },
    showCsvButton() {
      if (this.GSGo.dataSourceId === 'IRIS') return false;
      return true;
    },
    showNationalMapIcon() {
      const dataLength = this.reportDataIds.length;
      // hide Map icon if data has National data only
      // if (dataLength < 1 || (dataLength === 1 && this.reportDataIds[0].loc === this.nationalId)) {
      // if (dataLength === 1 && this.reportDataIds[0].loc === this.nationalId && this.view !== 'NationalChartTable') {
      if (dataLength === 1) {
        return false;
      }
      return true;
    },
    showMapViewContainer() {
      if (
        (this.view === 'NationalMap' || this.view === 'NationalCountyMap') &&
        this.locationId === '' &&
        !this.changingFromNationalChartTableToMapView
      ) {
        return true;
      }
      if (this.view === 'StateMap' && this.countyFlag === 'Y' && !this.changingFromStateChartTableToMapView) {
        return true;
      }
      return false;
    },
    showChartViewContainer() {
      if (
        this.view === 'NationalChart' ||
        this.view === 'StateChart' ||
        this.view === 'NationalChartTable' ||
        (this.locationId !== '' && this.countyFlag !== 'Y')
      ) {
        return true;
      }
      return false;
    },
    showTableViewContainer() {
      if (
        this.view === 'NationalTable' ||
        this.view === 'StateTable' ||
        this.view === 'NationalChartTable' ||
        (this.locationId !== '' && this.countyFlag !== 'Y')
      ) {
        return true;
      }
      return false;
    },
    currentIndicatorSelection() {
      return `${this.indicatorId}_${this.locationId}_${this.dataSourceId}`;
    },
  },
  methods: {
    getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey) {
      return this.$store.getters.getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey);
    },
    setStateFromQueryStringParms() {
      this.$store.commit('setShowDebugIds', this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'showDebugIds'));
      this.$store.commit('setShowMode', this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'showMode'));
      this.$store.commit('setView', this.getDefaultFromQueryStringParmIfInitialPageLoad('NationalMap', 'View'));
      this.$store.commit(
        'setShowFootnotes',
        this.getDefaultFromQueryStringParmIfInitialPageLoad('true', 'ShowFootnotes') === 'true',
      );
      this.$store.commit(
        'setMapClassifierId',
        this.getDefaultFromQueryStringParmIfInitialPageLoad('quantile', 'MapClassifierId'),
      );
      this.$store.commit(
        'setMapClassifierCount',
        this.getDefaultFromQueryStringParmIfInitialPageLoad('7', 'MapClassifierCount'),
      );
    },
    ...mapActions(['getInitialApiData']),
    getCountyIdForOmniture() {
      let countyValue = 'False';
      if (this.GSGo) {
        countyValue = this.GSGo.countyFlag === 'Y' ? 'True' : 'False';
      }
      return countyValue;
    },
    exportToPdf() {
      if (!this.generatingPdf) {
        // triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier}-IE PDF,${helper.getLocationIdForOmniture(this.GSGo.locationId)},${this.GSGo.dataSourceId},${this.getCountyIdForOmniture()},${this.indicatorId},${helper.getViewForOmniture(this.view)},${this.compareViewInteractionCode(this.view, this.GSGo.locationId, this.stratFilters.compareViewYear, this.stratFilters.compareId, this.stratFilters.compareId2)},${this.yearInteractionCode(this.stratFilters.yearId)},${this.stratFilters.responseId},${this.stratFilters.ageId},${this.stratFilters.genderId},${this.stratFilters.raceId},${this.stratFilters.riskFactorId},${this.stratFilters.riskFactorResponseId},${this.stratFilters.dataValueTypeId}`);
        this.$store.commit('setGeneratingPdf', true);
        const delayTime = 45;
        // if (this.view === 'NationalMap' || this.view === 'NationalCountyMap') {
        //   delayTime = 40;
        // }
        api
          .post(
            'ExportToPDF',
            {
              exportFormat: 1, // 1: PDF, 2: PDFLandscape
              myVirtualDirectoryOnDev: 'https://vehss-dev.apps.ecpaas-dev.cdc.gov/',
              urlToRender: `MyVirtualDirectory/LP?${this.queryStringForSelectedView}&showMode=export`,
              exportFileName: 'Export.pdf',
              conversionDelaySec: delayTime,
            },
            {
              responseType: 'blob',
            },
          )
          .then((response) => {
            FileSaver.saveAs(response.data, 'Export.pdf');
            this.$store.commit('setGeneratingPdf', false);
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              title: 'Export error',
              text: `Export to PDF failed: ${err.response.status} ${err.response.statusText}`,
            });
            this.$store.commit('setGeneratingPdf', false);
          });
      }
    },
    exportToCsv() {
      triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier} - EXPORT, CSV`);
      const csv = this.$store.getters.csvExport;
      const blob = new Blob([csv], { type: 'text/html;charset=utf-8' });
      FileSaver.saveAs(blob, 'Export.csv');
    },
  },
  watch: {
    apiErrors: {
      handler() {
        if (this.apiErrors.length > 0) {
          this.apiErrors.forEach((err) => {
            this.$notify({
              type: 'error',
              title: 'API error',
              text: `${err.request.responseURL} ${err.message}`,
            });
          });
        }
      },
      deep: true,
    },
    dataErrors: {
      handler() {
        if (this.dataErrors.length > 0) {
          this.dataErrors.forEach((error) => {
            this.$notify({
              type: 'error',
              title: 'Data error',
              text: error,
            });
          });
        }
      },
      deep: true,
    },
    reportDataIds: {
      handler() {
        if (this.reportDataIds.length === 1) {
          // this is for locationId = 59 only (National)
          // const thisShowMode = this.$route.query.showMode;
          // if (this.reportDataIds[0].loc === this.nationalId
          //    && (this.view === 'NationalMap' || this.view === 'NationalCountyMap' || thisShowMode !== 'export')) {
          //   this.$store.commit('setView', 'NationalChartTable');
          //   this.$store.commit('setHideNationalMapIcon', true);
          //   this.$store.commit('setHideCountryButtons', true);
          // }
          if (this.currentIndicatorSelection !== this.lastIndicatorSelection) {
            this.$store.commit('setHideCountryButtons', false);
            this.lastIndicatorSelection = this.currentIndicatorSelection;
          }
          // if (this.reportDataIds[0].loc === this.nationalId
          //   && (this.locationId === '' || this.locationId === this.nationalId)
          //   && this.view !== 'NationalChartTable') {
          //   this.$store.commit('setView', 'NationalChartTable');
          //   this.$store.commit('setHideCountryButtons', true);
          // } else if (this.locationId !== '' && this.view !== 'StateChartTable') {
          //   this.$store.commit('setView', 'StateChartTable');
          // }
          if (this.view !== 'NationalChartTable' || this.singleLocation === true) {
            this.$store.commit('setHideCountryButtons', true);
          }
          if (
            this.view === 'NationalMap' ||
            this.view === 'NationalCountyMap' ||
            this.view === 'NationalChart' ||
            this.view === 'NationalTable'
          ) {
            // || thisShowMode !== 'export'
            this.$store.commit('setView', 'NationalChartTable');
            this.$store.commit('setHideCountryButtons', true);
            // this.noDataForAllLocation = true;
          }
          if (this.view === 'StateMap' || this.view === 'StateChart' || this.view === 'StateTable') {
            // || thisShowMode !== 'export'
            this.$store.commit('setView', 'StateChartTable');
          }
          // this.$store.commit('setGeneratingPdf', false);
          if (!this.printerFriendly) {
            this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
          }
        } else if (this.singleLocation === true) {
          // this.$store.commit('setHideNationalMapIcon', true);
          this.$store.commit('setHideCountryButtons', true);
        } else if (this.reportDataIds.length > 1) {
          // this.$store.commit('setHideNationalMapIcon', false);
          this.$store.commit('setHideCountryButtons', false);
        }
      },
      deep: true,
    },
    countryCountyReportDataIds: {
      handler() {
        if (this.locationId === '' && this.countryCountyReportDataIds && this.countryCountyReportDataIds.length > 0) {
          this.$store.commit('setShowCountryCountyMapIcon', true);
        } else {
          this.$store.commit('setShowCountryCountyMapIcon', false);
          if (this.view === 'NationalCountyMap' && this.countyFlag === 'Y') {
            this.$store.commit('setView', 'NationalMap');
          } else if (this.view === '') {
            // this.$store.commit('setView', 'NationalChart');
          }
        }
      },
      deep: true,
    },
  },
};
