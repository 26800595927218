<template>
  <div v-if="!printerFriendly" id="divLogiSelector1" class="container-fluid bgBlue">
    <!-- Spinner -->
    <div v-if="showWaitSpinner" class="divWaitSpinner">
      <div class="spinnerBackground">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <!-- Display -->
    <div class="ms-4 me-2">
      <div class="mt-3 blue-box pb-3">
        <div>
          <img src="./assets/filter-outline.svg" alt="filter" />
          <span class="col-form-label-sm"><strong>Optional: Search & Filter Indicators</strong></span>
        </div>
        <!-- Filter -->
        <div class="d-flex flex-wrap mt-2">
          <div class="d-flex flex-wrap">
            <div class="me-4">
              <GlobalSelectorDataSource v-model="GS.gsDataSourceId" />
            </div>
            <!-- Location -->
            <div class="me-4">
              <GlobalSelectorLocation v-model="GS.gsLocationId" />
            </div>
            <!-- Risk Factor -->
            <div class="me-4">
              <GlobalSelectorRiskFactor v-model="GS.riskFactorSubCatId" />
            </div>
            <!-- </fieldset> -->
          </div>
          <div>
            <a href="#" @click.prevent="resetFilters"> Reset </a>
          </div>
        </div>
        <!-- Search -->
        <div class="search-dll-box">
          <div class="displayInline col-form-label-sm">Search</div>
          <div class="displayInline ms-1">
            <!-- <v-select
              id="searchLevel"
              v-model="GS.searchText"
              :options="categorylevels"
              label="levelName"
              class="search-ddl levelSearch"
              :placeholder="verbiage.searchLevel"
              :aria-label="verbiage.searchLevel"
              @update:modelValue="btnSearchClicked"
            /> -->
            <multiselect
              id="searchLevel"
              v-model="GS.searchText"
              :options="categorylevels"
              :placeholder="verbiage.searchLevel"
              class="search-ddl levelSearch"
              :aria-label="verbiage.searchLevel"
              @update:modelValue="btnSearchClicked"
            />
          </div>
        </div>
      </div>
      <!-- Instruction Text -->
      <div class="mt-3 col-form-label-sm text-bold">
        <div>Select a VEHSS indicator by clicking a button below and then continue moving right.</div>
        <div>Clicking a button in the 4th column will load the data.</div>
      </div>
      <!-- Levels -->
      <div id="divLevel" class="d-flex pt-3 pb-3">
        <!-- Level 1 -->
        <div class="visually-hidden">Level 1</div>
        <div id="divLevel1" class="pe-2 border-right">
          <ul v-for="(level1Name, index) in level1Names" :key="`level1${index}`">
            <li>
              <input
                :id="`level1${index}`"
                type="button"
                :value="`${level1Name} >`"
                class="btn btn-sm btn-blue-p"
                :class="{
                  'level-button-selected': level1Name === GS.level1Name,
                }"
                @click="level1Clicked(level1Name)"
              />
            </li>
          </ul>
        </div>
        <!-- Level 2 -->
        <div class="visually-hidden">Level 2</div>
        <div id="divLevel2" class="ps-2 pe-2 border-right" v-show="GS.level2Names.length > 0">
          <ul v-for="(level2Name, index) in GS.level2Names" :key="`level2${index}`">
            <li>
              <input
                :id="`level2${index}`"
                type="button"
                :value="`${level2Name} >`"
                class="btn btn-sm btn-blue-p"
                :class="{
                  'level-button-selected': level2Name === GS.level2Name,
                }"
                @click="level2Clicked(level2Name)"
              />
            </li>
          </ul>
        </div>
        <!-- Level 3 -->
        <div class="visually-hidden">Level 3</div>
        <div id="divLevel3" class="ps-2 pe-2 border-right div-max-width" v-show="GS.level3Names.length > 0">
          <ul v-for="(level3Name, index) in GS.level3Names" :key="`level3${index}`">
            <li>
              <input
                :id="`level3${index}`"
                type="button"
                :value="`${level3Name} >`"
                class="btn btn-sm btn-blue-p btn-wrap-text"
                :class="{
                  'level-button-selected': level3Name === GS.level3Name,
                  'level-button-bordered': isCPE(level3Name),
                }"
                @click="level3Clicked(level3Name)"
              />
            </li>
          </ul>
        </div>
        <!-- Level 4 -->
        <div class="visually-hidden">Level 4</div>
        <div id="divLevel4" class="ps-2">
          <ul v-for="(level4Name, index) in GS.level4Names" :key="`level4${index}`">
            <li>
              <input
                :id="`level4${index}`"
                type="button"
                :value="level4Name.name"
                class="btn btn-sm btn-blue-p btn-wrap-text"
                :class="{
                  'level-button-selected': level4Name.name === GS.level4Name,
                }"
                @click="level4Clicked(level4Name.name)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// ////////////////////////////////////////////////////////////////////
/* global triggerOmnitureInteractions */
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Multiselect from 'vue-multiselect';
import helper from '../../helper';
import GlobalSelectorDataSource from './GlobalSelectorDataSource.vue';
import GlobalSelectorLocation from './GlobalSelectorLocation.vue';
import GlobalSelectorRiskFactor from './GlobalSelectorRiskFactor.vue';

export default {
  components: {
    Multiselect,
    GlobalSelectorDataSource,
    GlobalSelectorLocation,
    GlobalSelectorRiskFactor,
  },
  data() {
    return {
      GS: {
        gsDataSourceId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'GSDataSourceId'),
        gsLocationId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'GSLocationId'),
        riskFactorSubCatId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'RiskFactorSubCatId'),
        level1Name: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level1'),
        level2Name: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level2'),
        level3Name: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level3'),
        level4Name: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level4'),
        level2Names: [],
        level3Names: [],
        level4Names: [],
        searchText: '',
        dataSourceId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'DataSourceId'),
        locationId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'LocationId'),
        view: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'View'),
        // dsRiskFactorChanged: false,
      },
      PDF: {
        locationId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'LocationId'),
        dataSourceId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'DataSourceId'),
        indicatorId: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'IndicatorId'),
        viewAsCountyMap: this.getDefaultFromQueryStringParmIfInitialPageLoad('N', 'CountyFlag'),
        showMode: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'showMode'),
        view: this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'View'),
      },
      verbiage: {
        searchLevel: 'Search',
      },
      removedSearchTerms: [
        'Prevalence',
        'Receipt of Services',
        'Patients Receiving Service',
        'Diagnosed Prevalence',
        'Diagnosed Prevalence, by Type',
        'Self-Reported Receipt of Service',
        'Any Eye Disorder',
        'Even When Wearing Glasses, Because of Their Eyesight How Often Adults Have Difficulty With',
        'Patients Receiving Service, by Service Type',
      ],
    };
  },
  created() {
    // this.apiGetCatetoryLevels();
  },
  /* -------------- computed -----------------------------*/
  computed: {
    ...mapState(['dsCatLevel']),
    ...mapState({
      initialPageLoad: (state) => state.flags.initialPageLoad,
      showDebugIds: (state) => state.flags.showDebugIds,
      level4Name: (state) => state.LP.GSGo.level4Name,
      locationId: (state) => state.LP.GSGo.locationId,
      stratFilters: (state) => state.LP.stratFilters,
      nationalId: (state) => state.LP.nationalId,
      lku: (state) => state.lku,
      loadingInitialApiData: (state) => state.flags.loadingInitialApiData,
      // dsRiskFactor: (state) => state.dsRiskFactor,
    }),
    ...mapGetters(['printerFriendly']),
    categorylevels() {
      const allLevels = [];
      // level 1
      if (this.dsCatLevel) {
        this.dsCatLevel.forEach((level1) => {
          // not include Level1
          // allLevels.push(level1.name);
          // level 2
          const { level2s } = level1;
          if (level2s) {
            level2s.forEach((level2) => {
              if (this.isSearchTermAllowed(level2.name)) {
                allLevels.push(level2.name);
              }
              // level 3
              const { level3s } = level2;
              if (level3s) {
                level3s.forEach((level3) => {
                  if (this.isSearchTermAllowed(level3.name)) {
                    allLevels.push(level3.name);
                  }
                  // level 4
                  const { level4s } = level3;
                  if (level4s) {
                    level4s.forEach((level4) => {
                      if (this.isSearchTermAllowed(level4.name)) {
                        allLevels.push(level4.name);
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
      const distinctAllLevels = [...new Set(allLevels.map((e) => e))];
      distinctAllLevels.sort();
      return distinctAllLevels;
    },
    level1Names() {
      const level1Names = [];
      if (this.dsCatLevel) {
        this.dsCatLevel.forEach((l1) => {
          level1Names.push(l1.name);
        });
      }
      return level1Names;
    },
    gsLevel4Name() {
      return this.GS.level4Name;
    },
    locations() {
      const flattened = _(this.lku.Location).reduce((lo, val) => lo.concat([val]), []);
      const result = [];
      _.forEach(flattened, (n) => {
        // remove National
        if (n.name !== 'National' && n.name !== 'national') {
          result.push(n);
        }
      });
      return helper.addNationalLocation(_(flattened).orderBy('sort', 'asc').value());
    },
    gsDataSourceId() {
      return this.GS.gsDataSourceId;
    },
    gsLocationId() {
      return this.GS.gsLocationId;
    },
    gsRiskFactorSubCatId() {
      return this.GS.riskFactorSubCatId;
    },
    searchText() {
      return this.GS.searchText;
    },
  },
  /* -------------- Watch -----------------------------*/
  watch: {
    loadingInitialApiData() {
      if (this.loadingInitialApiData === false) {
        this.apiGetCatetoryLevels();
      }
    },
    gsDataSourceId() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - INDFILTER, SOURCE, ${this.GS.gsDataSourceId}`,
      );
      this.GS.searchText = '';
      this.apiGetCatetoryLevels();
    },
    gsLocationId() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - INDFILTER, LOCATION, ${this.getLocaitonIdForOmniture(
          this.GS.gsLocationId,
        )}`,
      );
      this.GS.searchText = '';
      this.apiGetCatetoryLevels();
    },
    gsRiskFactorSubCatId() {
      triggerOmnitureInteractions(
        `${process.env.VUE_APP_InteractionIdentifier} - INDFILTER, RISKFACTOR, ${this.GS.riskFactorSubCatId}`,
      );
      this.GS.searchText = '';
      this.apiGetCatetoryLevels();
      this.apiGetRiskFactorDefaultDS();
    },
    gsLevel4Name() {
      if (this.GS.level4Name === '') {
        this.$store.commit('setShowHeadingsAndFilters', false);
      }
    },
    level1Names: {
      handler() {
        // if user pass in URL with all the parameters
        // level1Names are reset in resetCategoryLevels, so reGet them here
        this.GS.level1Name = this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level1');
        this.GS.level2Name = this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level2');
        this.GS.level3Name = this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level3');
        this.GS.level4Name = this.getDefaultFromQueryStringParmIfInitialPageLoad('', 'Level4');

        if (this.initialPageLoad) {
          if (this.GS.level1Name && this.isLevelNameExist(this.GS.level1Name, this.level1Names, false)) {
            this.level1Clicked(this.GS.level1Name, true); // set fromSearch = true because levelName exists in the URL
          }
          if (this.GS.level2Name && this.isLevelNameExist(this.GS.level2Name, this.GS.level2Names, false)) {
            this.level2Clicked(this.GS.level2Name, true); // set fromSearch = true because levelName exists in the URL
          }
          if (this.GS.level3Name && this.isLevelNameExist(this.GS.level3Name, this.GS.level3Names, false)) {
            this.level3Clicked(this.GS.level3Name, true); // set fromSearch = true because levelName exists in the URL
          }
          if (this.GS.level4Name && this.isLevelNameExist(this.GS.level4Name, this.GS.level4Names, true)) {
            this.level4Clicked(this.GS.level4Name);
          }
        }
      },
      deep: true,
    },
    level4Name() {
      this.GS.level4Name = this.level4Name;
    },
  },
  /* -------------- methods -----------------------------*/
  methods: {
    ...mapActions([
      'getQuestionLocationApiData',
      'getIndicatorAllIdsAndSetIndicatorId',
      'getCategoryLevels',
      'getRiskFactors',
      'getRiskFactorDefaultDS',
    ]),

    getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey) {
      return this.$store.getters.getDefaultFromQueryStringParmIfInitialPageLoad(defaultValue, queryStringKey);
    },
    apiRefreshIndicatorAllIds() {
      // this set indicator's compare values
      this.getIndicatorAllIdsAndSetIndicatorId({
        indicatorId: this.PDF.indicatorId,
        locationId: this.PDF.locationId,
      });
    },
    apiGetCatetoryLevels() {
      this.$store.commit('setGlobalSelectorChanged', true);
      this.resetCategoryLevels();
      let riskFactorCatId = '';
      let riskFactorSubCatId = '';
      if (this.GS.riskFactorSubCatId !== '') {
        const ds = _.find(this.lku.RiskFactorSubcategoryType, {
          id: this.GS.riskFactorSubCatId,
        });
        if (ds) {
          riskFactorCatId = ds.namealias;
          riskFactorSubCatId = this.GS.riskFactorSubCatId;
        }
      }
      // if (this.initialPageLoad) {
      //   console.log('apiGetCatetoryLevels');
      //   console.log(`ds:${this.GS.gsDataSourceId}`);
      //   console.log(`loc: ${this.GS.gsLocationId}`);
      // }

      this.getCategoryLevels({
        dataSourceId: this.GS.gsDataSourceId,
        locationId: this.GS.gsLocationId,
        riskFactorCatId,
        riskFactorSubCatId,
      });
    },
    apiGetRiskFactorDefaultDS() {
      let riskFactorCatId = '';
      let riskFactorSubCatId = '';
      if (this.GS.riskFactorSubCatId !== '') {
        const ds = _.find(this.lku.RiskFactorSubcategoryType, {
          id: this.GS.riskFactorSubCatId,
        });
        if (ds) {
          riskFactorCatId = ds.namealias;
          riskFactorSubCatId = this.GS.riskFactorSubCatId;
        }
        this.getRiskFactorDefaultDS({
          riskFactorCatId,
          riskFactorSubCatId,
        });
      }
    },
    // apiGetRiskFactors() {
    //   this.getRiskFactors({
    //     dataSourceId: this.GS.gsDataSourceId,
    //     locationId: this.GS.gsLocationId,
    //   });
    // },
    /* -------------- SEARCH -----------------------------*/
    btnSearchClicked() {
      // 1.Reset all
      this.resetSearch();
      // 2.Init
      const { searchText } = this.GS;
      if (searchText.trim() === '') {
        return;
      }
      let isFound = false;
      // 3. Search
      if (this.dsCatLevel) {
        // Search Level 1
        this.dsCatLevel.every((l1) => {
          if (isFound === true) {
            return false;
          }
          this.GS.level1Name = l1.name;
          if (l1.name === searchText) {
            isFound = true;
          } else {
            // Search Level 2
            const { level2s } = this.dsCatLevel.filter((e) => e.name === this.GS.level1Name)[0]; // 0 because only one record returned
            if (level2s) {
              level2s.every((l2) => {
                if (isFound === true) {
                  return false;
                }
                this.GS.level2Name = l2.name;
                if (l2.name === searchText) {
                  isFound = true;
                } else {
                  // Search Level 3
                  const { level3s } = level2s.filter((e) => e.name === this.GS.level2Name)[0]; // 0 because only one record returned
                  if (level3s) {
                    level3s.every((l3) => {
                      if (isFound === true) {
                        return false;
                      }
                      this.GS.level3Name = l3.name;
                      if (l3.name === searchText) {
                        isFound = true;
                      } else {
                        // Search Level 4
                        const { level4s } = level3s.filter((e) => e.name === this.GS.level3Name)[0]; // 0 because only one record returned
                        if (level4s) {
                          level4s.every((l4) => {
                            if (isFound === true) {
                              return false;
                            }
                            this.GS.level4Name = l4.name;
                            if (l4.name === searchText) {
                              isFound = true;
                            }
                            return true; // level4
                          });
                        }
                      }
                      return true; // level3
                    });
                  }
                }
                return true; // level2
              });
            }
          }
          return true; // level1
        });
      }
      // ///////////////////////
      if (this.GS.level1Name) {
        this.level1Clicked(this.GS.level1Name, true);
      }
      if (this.GS.level2Name) {
        this.level2Clicked(this.GS.level2Name, true);
      }
      if (this.GS.level3Name) {
        this.level3Clicked(this.GS.level3Name, true);
      }
      if (this.GS.level4Name) {
        this.level4Clicked(this.GS.level4Name);
      }
      // 4. Omniture
      if (searchText) {
        triggerOmnitureInteractions(`${process.env.VUE_APP_InteractionIdentifier} - SEARCH`);
      }
    },
    resetFilters() {
      this.GS.searchText = '';
      this.resetAll();
    },
    /* -------------- LEVELs -----------------------------*/
    // level 1 clicked
    level1Clicked(level1Name, fromSearch) {
      // 1.Init names
      this.GS.level1Name = level1Name;
      if (fromSearch !== true) {
        this.GS.level2Name = '';
        this.GS.level3Name = '';
        this.GS.level4Name = '';
      }
      this.GS.level2Names = [];
      this.GS.level3Names = [];
      this.GS.level4Names = [];
      // 2.Retrieve level2s
      const { level2s } = this.dsCatLevel.find((e) => e.name === this.GS.level1Name);
      if (level2s) {
        level2s.forEach((l2) => {
          this.GS.level2Names.push(l2.name);
        });
      }
    },
    // level 2 clicked
    level2Clicked(level2Name, fromSearch) {
      // 1.Init
      this.GS.level2Name = level2Name;
      if (fromSearch !== true) {
        this.GS.level3Name = '';
        this.GS.level4Name = '';
      }
      this.GS.level3Names = [];
      this.GS.level4Names = [];
      // 2.Retrieve children
      const { level2s } = this.dsCatLevel.find((e) => e.name === this.GS.level1Name);
      const { level3s } = level2s.find((e) => e.name === this.GS.level2Name);
      if (level3s) {
        level3s.forEach((l3) => {
          this.GS.level3Names.push(l3.name);
        });
      }
    },
    // level 3 clicked
    level3Clicked(level3Name, fromSearch) {
      // 1.Init
      this.GS.level3Name = level3Name;
      if (fromSearch !== true) {
        this.GS.level4Name = '';
      }
      this.GS.level4Names = [];
      // 2.Retrieve children
      const { level2s } = this.dsCatLevel.find((e) => e.name === this.GS.level1Name);
      const { level3s } = level2s.find((e) => e.name === this.GS.level2Name);
      let { level4s } = level3s.find((e) => e.name === this.GS.level3Name);
      //   .sort((a, b) => a.displayOrder - b.displayOrder);
      level4s = _(level4s).orderBy('displayOrder', 'asc').value();

      if (level4s && level4s.length > 0) {
        // // add CompareAll only when there are more than one item in level4s
        // if (level4s.length > 1) {
        //   const compareAllId = `${helper.getQuestionId(level4s[0].indicatorId)}~`;
        //   this.GS.level4Names.push({ name: 'Compare All', indicatorId: compareAllId });
        // }
        // add the rest
        level4s.forEach((l4) => {
          this.GS.level4Names.push({
            name: l4.name,
            indicatorId: l4.indicatorId,
          });
        });
      }
    },
    // level 4 clicked
    level4Clicked(level4Name) {
      if (this.GS.level4Names && this.GS.level4Names.length > 0) {
        const objLevel4 = this.GS.level4Names.find((e) => e.name === level4Name);
        if (objLevel4) {
          this.GS.level4Name = level4Name;
          if (this.PDF.showMode === 'export') {
            this.level4ProcessPDF(objLevel4);
          } else {
            this.level4Process(objLevel4);
          }
        }
      }
    },
    level4Process(objLevel4) {
      // Initialise
      this.$store.commit('setReportDataIds', []);
      const payload = this.GS; // capture current global selector state into state.LP.GlobalSelector
      payload.indicatorId = objLevel4.indicatorId;
      // this.$store.commit('setInitialPageLoad', true);
      this.$store.commit('setReSelectLevel4', true); // changed in global only
      this.$store.commit('setIndicatorChanged', true); // set this change from Global and the dropdown in strat
      // this.$store.commit('setHideCountryButtons', false);
      payload.gsDataSourceId = this.GS.gsDataSourceId;
      payload.gsLocationId = this.GS.gsLocationId;
      payload.riskFactorSubCatId = this.GS.riskFactorSubCatId;
      if (this.initialPageLoad) {
        payload.dataSourceId = this.GS.dataSourceId;
        payload.locationId = this.GS.locationId;
        payload.location = helper.getLocationName(payload.locationId, this.locations);
      } else {
        payload.dataSourceId = this.GS.gsDataSourceId;
        payload.locationId = this.GS.gsLocationId;
        payload.location = helper.getLocationName(payload.locationId, this.locations);
      }
      this.$store.commit('setGSGo', payload);
      // get questionLocationLink
      const questionId = helper.getQuestionId(objLevel4.indicatorId);
      this.getQuestionLocationApiData(questionId);

      // re-set URL because the location change from OneLocation to All Location but other selected values not changed.
      // 6. Update URL
      // No need -> comment out ---
      // if (!this.initialPageLoad) {
      //   this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
      // }
      // Scroll to result
      this.$store.commit('setShowHeadingsAndFilters', true);
      // window.location.hash = '#divLinkbucket';
    },
    level4ProcessPDF(objLevel4) {
      const payload = this.GS; // capture current global selector state into state.LP.GlobalSelector
      payload.indicatorId = objLevel4.indicatorId;
      payload.level4Name = this.GS.level4Name;
      payload.locationId = this.GS.locationId;
      payload.location = helper.getLocationName(payload.locationId, this.locations);
      payload.dataSourceId = this.PDF.dataSourceId;
      payload.dataSource = this.lku.DataSource[payload.dataSourceId].name;
      if (this.PDF.viewAsCountyMap && this.PDF.viewAsCountyMap.toLowerCase() === 'y') {
        payload.countyFlag = 'Y';
      } else {
        payload.countyFlag = 'N';
      }
      this.$store.commit('setGSGo', payload);
      this.$store.commit('setView', this.PDF.view);
      this.$store.commit('setShowHeadingsAndFilters', true);
      // Execute function in GlobalSelectorStrat
      this.apiRefreshIndicatorAllIds(); // this.GS.indicatorId, this.GS.locationId
    },
    isLevelNameExist(name, levels, isLevel4) {
      if (levels && levels.length > 0) {
        if (isLevel4) {
          const found = levels.find((e) => e.name === name);
          if (found) {
            return true;
          }
        } else {
          const found = levels.find((e) => e === name);
          if (found) {
            return true;
          }
        }
      }
      return false;
    },
    getLocationId() {
      if (this.initialPageLoad) {
        // if(this.GS.view === 'NationalChartTable' || (this.GS.view === 'NationalChartTable') {
        //   return this.nationalId;
        // }
        return this.GS.locationId;
      }
      return this.GS.locationId; // this.locationId;
    },
    // Reset all fields
    resetAll() {
      this.GS.level1Name = '';
      this.GS.level2Name = '';
      this.GS.level3Name = '';
      this.GS.level4Name = '';
      this.GS.level2Names = [];
      this.GS.level3Names = [];
      this.GS.level4Names = [];
      this.GS.gsDataSourceId = '';
      this.GS.gsLocationId = '';
      this.GS.riskFactorSubCatId = '';
    },
    resetSearch() {
      this.GS.level1Name = '';
      this.GS.level2Name = '';
      this.GS.level3Name = '';
      this.GS.level4Name = '';
      this.GS.level2Names = [];
      this.GS.level3Names = [];
      this.GS.level4Names = [];
    },
    resetCategoryLevels() {
      this.GS.level1Name = '';
      this.GS.level2Name = '';
      this.GS.level3Name = '';
      this.GS.level4Name = '';
      this.GS.level2Names = [];
      this.GS.level3Names = [];
      this.GS.level4Names = [];
    },
    isSearchTermAllowed(searchTerm) {
      const foundIndex = this.removedSearchTerms.findIndex((e) => e.toLowerCase() === searchTerm.toLowerCase());
      if (foundIndex === -1) {
        return true;
      }
      return false;
    },
    getLocationName() {
      let locationName = 'National';
      const locationId = this.getLocationId();
      if (locationId) {
        locationName = helper.getLocationName(locationId, this.locations);
      }
      return locationName;
    },
    getLocaitonIdForOmniture(currentLocationId) {
      if (currentLocationId === '') {
        return 'Nat';
      }
      return currentLocationId;
    },
    isCPE(level3Name) {
      const cpeName = 'Composite Prevalence Estimate';
      const cpeName1 = 'VEHSS Prevalence Estimate';
      if (level3Name.indexOf(cpeName) >= 0 || level3Name.indexOf(cpeName1) >= 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.search-dll-box {
  margin-left: 35px;
}

.levelSearch {
  width: 484px;
}
.level-button-selected {
  background-color: green !important;
  color: #fff !important;
}
.level-button-bordered {
  border: 3px solid rgb(255, 174, 0);
}
.level-button-bordered:hover {
  border: 3px solid rgb(255, 174, 0);
}
.border-right {
  border-right: 2px solid #bdbdbd !important;
}
.btn-wrap-text {
  white-space: normal;
  text-align: left;
}
.div-max-width {
  max-width: 30%;
}
ul {
  display: block;
  list-style-type: none !important;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0px;
}
li {
  display: list-item !important;
  margin: 0 !important;
}
.stratContainer {
  padding: 0 1rem; /* 0.5rem 0.5rem 0 0.5rem; */
  /* background-color: #dfdfdf; */
}
</style>
