import _ from 'lodash';

function logInfo(title, value) {
  console.log('%c%s\t%o', 'background-color:Aqua;', title, value);
}

function logWarning(title, value) {
  console.log('%c%s\t%o', 'background-color:DarkOrange;', title, value);
}

function logError(title, value) {
  console.log('%c%s\t%o', 'background-color:Maroon;color:white', title, value);
}

function padLeftZeros(number) {
  return number ? _.padStart(number, 5, '00000') : null;
  // _.padStart(d.id, 5, '00000')
}

export {
  logInfo, logWarning, logError, padLeftZeros,
};
