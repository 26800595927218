<template>
  <div id="country-county-map-view">
    <img
      id="loadingImg"
      src="_SupportFiles/Shared.Images.ajax-loader.gif"
      alt="Image that displays when the map is loading."
    />
    <div id="divD3MapContainer" :class="{ printerFriendly }" class="ThemeAlignTop">
      <div id="stateMapContextData" />
      <CountryCountyMap
        v-if="metadataLoaded === true"
        v-bind:stateCode="locationId"
        :getColorRamp="getColorRamp"
        :onHover="onHover"
        :onHoverEnd="onHoverEnd"
        :onLoaded="onMapLoaded"
        ref="countryCountyMap"
      />
    </div>
    <div class="legends-container" v-if="mapLoaded === true">
      <!-- legends & county search -->
      <CountyTypeahead v-if="!printerFriendly" :suggestions="counties" @countySelected="countySelected" />
      <MapLegends
        :legendBreaks="legendBreaks"
        :classifierType="mapClassifierIdLoc"
        :classesCount="mapClassifierCountLoc"
        :printerFriendly="printerFriendly"
        :dataValueUnit="dataValueUnit"
        @ok="mapSettingsModified"
        @cancel="cancelClicked"
      />
    </div>
  </div>
</template>

<script>
// /* global triggerOmnitureInteractions */
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import * as d3 from 'd3';
import helper from '../../../../helper';
import CountryCountyMap from './CountryCountyMap.vue';
import CountyTypeahead from '../StateMap/CountyTypeahead.vue';
import MapLegends from '../MapLegends.vue';
import dataClassification from '../Classification';
import constants from '../constants';
import { padLeftZeros } from '../../utils';
import geostats from '../Classification/geostats';

export default {
  name: 'CountryCountyMapView',
  props: {},
  components: {
    CountryCountyMap,
    MapLegends,
    CountyTypeahead,
  },
  data() {
    return {
      mapClassifiers: constants.classifiers.types,
      mapClassifierCounts: constants.classifiers.classes,
      mapClassifierIdLoc: '',
      mapClassifierCountLoc: 0,
      classifyData: null,
      legendBreaks: [],
      counties: [],
      countyValue: '',
      previousCountyId: '',
      metadataLoaded: false,
      mapLoaded: false,
      simpleCountryCountyReportDataIds: {},
    };
  },
  computed: {
    ...mapState({
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      indicatorId: (state) => state.LP.GSGo.indicatorId, // Omniture Only
      yearId: (state) => state.LP.stratFilters.yearId,
      // responseId: state => state.LP.stratFilters.responseId,
      ageId: (state) => state.LP.stratFilters.ageId,
      genderId: (state) => state.LP.stratFilters.genderId,
      raceId: (state) => state.LP.stratFilters.raceId,
      riskFactorId: (state) => state.LP.stratFilters.riskFactorId,
      riskFactorResponseId: (state) => state.LP.stratFilters.riskFactorResponseId,
      dataValueTypeId: (state) => state.LP.stratFilters.dataValueTypeId,
      mapClassifierId: (state) => state.LP.mapClassifierId,
      mapClassifierCount: (state) => state.LP.mapClassifierCount,
      countryCountyReportDataIds: (state) => state.LP.countryCountyReportDataIds,
      dataSourceTypeId: (state) => state.LP.GSGo.dataSourceTypeId,
      topicId: (state) => state.LP.GSGo.topicId,
      categoryId: (state) => state.LP.GSGo.categoryId,
      locationId: (state) => state.LP.GSGo.locationId,
      view: (state) => state.LP.view,
      compareViewYear: (state) => state.LP.stratFilters.compareViewYear,
      compareId: (state) => state.LP.stratFilters.compareId,
      compareId2: (state) => state.LP.stratFilters.compareId2,
    }),
    ...mapGetters([
      'indicator',
      'dataValueType',
      'dataValueUnit',
      'sampleSizeSymbol',
      'dataValueUnitShort',
      'decimalPlaces',
      'mapSummary',
      'printerFriendly',
      'confidenceIntervalLabel',
      'compareInteractionCode',
      'viewInteractionCode',
      'yearInteractionCode',
    ]),
    mapClassifier() {
      const row = _(this.mapClassifiers).find({ id: this.mapClassifierId });
      return row ? row.description : '';
    },
    legendSettingsCSV() {
      return `${this.mapClassifierId},${this.mapClassifierCount}`;
    },
    responseId() {
      const rId = helper.getResponseId(this.indicatorId);
      return rId;
    },
  },
  created() {
    this.mapClassifierIdLoc = this.mapClassifierId;
    this.mapClassifierCountLoc = this.mapClassifierCount;
  },
  watch: {
    countryCountyReportDataIds() {
      this.mapRefresh();
    },
  },
  mounted() {
    // this.getCountryCountyReportDataIds();
    document.getElementById('loadingImg').style.display = 'block';
    if (this.countryCountyReportDataIds && this.countryCountyReportDataIds.length > 0) {
      this.mapRefresh();
    }
  },
  beforeUnmount() {
    // console.log('CountryCountyMapView: beforeDestroy');
  },
  methods: {
    setClassifications() {
      const breaks = [];
      let dataUnavailable = false;
      const dataClassificationPoints = _.map(
        _.filter(this.countryCountyReportDataIds, (o) => o.ord === 2 && o.lci != null && o.hci != null),
        (val) => (val.dv === null ? 0 : val.dv),
      );

      dataUnavailable = _.map(_.filter(this.countryCountyReportDataIds, (o) => o.dv == null)).length > 0;
      // eslint-disable-next-line
      const gs = new geostats(dataClassificationPoints);
      gs.setPrecision(4);
      switch (this.mapClassifierId) {
        case 'quantile':
          gs.getClassQuantile(this.mapClassifierCount);
          break;

        case 'natural-breaks':
          gs.getJenks2(this.mapClassifierCount);
          break;

        case 'equal-interval':
          gs.getEqInterval(this.mapClassifierCount);
          break;

        default:
          gs.getClassQuantile(this.mapClassifierCount);
          break;
      }

      const classificationRanges = gs.ranges.map((o, i) => ({
        c: i,
        min: parseFloat(o.split('-')[0]),
        max: parseFloat(o.split('-')[1]),
      }));

      this.classifyData = {
        legend: classificationRanges,
      };

      const lengendRanges = this.classifyData.legend;
      for (let i = 0; i < lengendRanges.length; i += 1) {
        let minNumber = 0;
        let maxNumber = 0;
        if (lengendRanges[i].min) {
          if (i > 0) {
            lengendRanges[i].min += 0.01;
          }
          minNumber = lengendRanges[i].min.toFixed(2);
          maxNumber = lengendRanges[i].max.toFixed(2);
          if (Number(minNumber) <= Number(maxNumber)) {
            breaks.push({
              index: i,
              min: minNumber,
              max: maxNumber,
              color: dataClassification.getColorInRange(maxNumber, lengendRanges),
              desc: `${padLeftZeros(minNumber)} - ${padLeftZeros(maxNumber)}`,
              // desc: `${padLeftZeros(lengendRanges[i].min.toFixed(2))} - ${padLeftZeros(lengendRanges[i].max.toFixed(2))}`,
            });
          }
        } else {
          dataUnavailable = true;
          // The range may start with 0, that means there is no data county
          // however, the range that starts with 0 may include having-data counties
          // thus, set the ramge min + 0.01 instead of ignore the whole range
          if (lengendRanges[i].min === 0 && lengendRanges[i].max > 0) {
            lengendRanges[i].min += 0.01;
            minNumber = lengendRanges[i].min.toFixed(2);
            maxNumber = lengendRanges[i].max.toFixed(2);
            if (Number(minNumber) < Number(maxNumber)) {
              breaks.push({
                index: i,
                min: minNumber,
                max: maxNumber,
                color: dataClassification.getColorInRange(maxNumber, lengendRanges),
                desc: `${padLeftZeros(minNumber)} - ${padLeftZeros(maxNumber)}`,
                // desc: `${padLeftZeros(lengendRanges[i].min.toFixed(2))} - ${padLeftZeros(lengendRanges[i].max.toFixed(2))}`,
              });
            }
          }
        }
      }

      if (dataUnavailable) {
        breaks.push(this.noDataColorForLegend());
      }
      this.legendBreaks = breaks;
    },
    noDataColorForLegend() {
      return {
        index: 0,
        min: 0,
        max: 0,
        color: constants.colorRamp.noDataColor,
        desc: constants.labels.dataUnavailable,
      };
    },
    noDataColorForLegendExists() {
      if (
        this.legendBreaks.length > 0 &&
        this.legendBreaks[this.legendBreaks.length - 1].color === constants.colorRamp.noDataColor
      ) {
        return true;
      }
      return false;
    },
    getColor(dataPoint) {
      if (this.classifyData != null) {
        return dataClassification.getColorInRange(dataPoint, this.classifyData.legend);
      }
      return constants.colorRamp.noDataColor;
    },
    onHover(mapDatum, eventData) {
      this.clearPreviousCountySelection();
      this.onMapSelect(mapDatum, eventData);
    },
    onMapLoaded() {
      this.buildCountyList();
      const svgEl = document.getElementById('country-county-map');
      if (svgEl) {
        const svgHeight = svgEl.getBoundingClientRect().height;
        document.getElementById('country-county-map-view').style.height = `${svgHeight}px`;
      }
      this.mapLoaded = true;
    },
    onHoverEnd() {
      this.clearToolTip();
    },
    onMapSelect(mapDatum, eventData) {
      const mapToolTip = d3.select('#stateMapContextData');
      const selectedCounty = _.find(this.countryCountyReportDataIds, (ct) => parseInt(ct.cf, 10) === mapDatum.id);

      let toolTipCoordinates = {
        x: eventData.offsetX,
        y: eventData.offsetY,
      };

      if (!!(window.MSInputMethodContext && document.documentMode) || /MSIE 10/.test(navigator.userAgent)) {
        toolTipCoordinates = {
          x: eventData.x,
          y: eventData.y,
        };
      }

      if (selectedCounty) {
        mapToolTip.transition().duration(200).attr('class', 'tooltip').style('opacity', 0.9);

        mapToolTip
          .html(this.buildToolTipContent(selectedCounty))
          .style('left', `${toolTipCoordinates.x}px`)
          .style('top', `${toolTipCoordinates.y - 28}px`);
      }
    },
    buildToolTipContent(data) {
      return `<div class="cn">${data.cn}</div>
        <div class="contentPane">
          <strong>${data.cn}: ${data.dv}%</strong>
          <div>95% CI (${Number(data.lci || 0).toFixed(2)} - ${Number(data.hci || 0).toFixed(2)})</div>
          <div>People = ${Number(data.ss).toLocaleString()}</div>
        </div>`;
    },
    getColorRamp(countyFip) {
      const countyToMatch = countyFip.toString();
      const matchingCounty = _.find(this.countryCountyReportDataIds, (dt) => dt.cf === countyToMatch);
      if (matchingCounty) {
        if (matchingCounty.dv) {
          return this.getColor(matchingCounty.dv);
        }
        return constants.colorRamp.noDataColor;
      }
      if (!this.noDataColorForLegendExists()) {
        this.legendBreaks.push(this.noDataColorForLegend());
      }
      return constants.colorRamp.noDataColor;
    },
    mapRefresh() {
      this.metadataLoaded = false;
      this.clearPreviousCountySelection();
      this.buildSimpleCountryCountyReportDataIds();
      if (_.find(this.countryCountyReportDataIds, (o) => o.cn)) {
        this.metadataLoaded = true;
        this.setClassifications();
        this.buildCountyList();
        if (this.$refs.countryCountyMap) {
          this.$refs.countryCountyMap.refresh();
        }
      }
    },
    buildCountyList() {
      const counties = _.map(this.countryCountyReportDataIds, (item, i) => ({
        name: item.cn,
        cf: item.cf,
        index: i,
      }));

      this.counties = counties;
    },
    mapSettingsModified(legendSettings) {
      this.mapClassifierIdLoc = legendSettings.classificationMethod;
      this.mapClassifierCountLoc = legendSettings.classesCount;

      const idChanged = this.mapClassifierIdLoc !== this.mapClassifierId;
      const countChanged = this.mapClassifierCountLoc !== this.mapClassifierCount;
      if (idChanged) {
        this.$store.commit('setMapClassifierId', this.mapClassifierIdLoc);
      }
      if (countChanged) {
        this.$store.commit('setMapClassifierCount', this.mapClassifierCountLoc);
      }

      if (idChanged || countChanged) {
        this.mapRefresh();
        // const responseId = helper.getResponseId(this.indicatorId);
        // triggerOmnitureInteractions(
        //   `${
        //     process.env.VUE_APP_InteractionIdentifier
        //   }-IML ${this.mapClassifierIdLoc.substr(0, 3)},${
        //     this.mapClassifierCountLoc
        //   },${this.dataValueTypeId},${helper.getLocationIdForOmniture(
        //     this.locationId,
        //   )},${this.dataSourceId},True,${this.indicatorId},${
        //     this.viewInteractionCode
        //   },${this.compareInteractionCode('')},${this.yearInteractionCode(
        //     this.yearId,
        //   )},${responseId},${this.ageId},${this.genderId},${this.raceId},${
        //     this.riskFactorId
        //   },${this.riskFactorResponseId}`,
        // );
        this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
      }
    },
    cancelClicked() {},
    countySelected(county) {
      if (!county) {
        this.clearPreviousCountySelection();
        return;
      }

      const elId = `county-${county.id}`;
      const countySvgElement = document.getElementById(`county-${county.id}`);
      const countySvgElementBounds = countySvgElement.getBoundingClientRect();
      const mapContainerElementBounds = document.getElementById('mapContainer').getBoundingClientRect();
      this.clearPreviousCountySelection();
      // eslint-disable-next-line
      $(`#${elId}`).addClass('county-selected');
      this.previousCountyId = `county-${county.id}`;
      this.onMapSelect(
        { id: parseInt(county.id, 10) },
        {
          offsetX: countySvgElementBounds.left - mapContainerElementBounds.left + countySvgElementBounds.width / 2,
          x: countySvgElementBounds.left - mapContainerElementBounds.left + countySvgElementBounds.width / 2,
          offsetY: countySvgElementBounds.top - mapContainerElementBounds.top + countySvgElementBounds.height / 2 + 20,
          y: countySvgElementBounds.top - mapContainerElementBounds.top + countySvgElementBounds.height / 2 + 20,
        },
      );
    },
    clearPreviousCountySelection() {
      if (this.$refs.countySearch) {
        this.$refs.countySearch.clearSelection(true);
      }
      if (this.previousCountyId !== '') {
        // eslint-disable-next-line
        const previousCountySvgElement = $(`#${this.previousCountyId}`);
        if (previousCountySvgElement) {
          previousCountySvgElement.removeClass('county-selected');
        }
      }
      this.clearToolTip();
    },
    clearToolTip() {
      const mapToolTip = d3.select('#stateMapContextData');

      mapToolTip.transition().duration(500).style('opacity', 0);
    },
    decimalPointNumber(inputNumber) {
      if (inputNumber && inputNumber.toString().indexOf('.') > -1) {
        return inputNumber.toString().split('.')[1].length;
      }
      return 0;
    },
    buildSimpleCountryCountyReportDataIds() {
      if (this.countryCountyReportDataIds && this.countryCountyReportDataIds.length > 0) {
        // TODO: implement later
        // this.simpleCountryCountyReportDataIds
        return '';
      }
      return '';
    },
  },
};
</script>

<style>
#country-county-map-view {
  /*height: 900px;*/
  margin-left: 100px;
  margin-right: 100px;
  position: relative;
  display: flex;
}

#divHomeButton {
  position: absolute;
  top: 95px;
  left: 20px;
  z-index: 1;
}

#divD3MapContainer {
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  width: 84%;
  height: 100%;
}

#divD3MapContainer.printerFriendly .esriSimpleSlider {
  display: none;
}

#divD3MapContainer.printerFriendly .homeContainer {
  display: none;
}

.tc {
  text-align: center;
}

.legendSettingSelectors label {
  display: block;
  font-weight: bold;
}

.legendSettingSelectors {
  padding: 10px 40px;
}

.modalButtons {
  text-align: center;
  padding: 10px 0;
}

.modalButtons button {
  width: 80px;
  margin: 0 5px;
  height: 37px;
  font-weight: bold;
}
.default-button {
  color: white;
  background-color: #075290;
}
.disabledButton {
  background-color: #f6f6f6;
  color: #cecece;
}

.HomeButton .home {
  background-image: url(../../../../../public/_SupportFiles/Shared.Images.resetviewicon.jpg);
  background-color: white;
  border: 1px solid #57585a;
  border-radius: 5px;
}

#loadingImg {
  position: absolute;
  left: 40%;
  top: 230px;
  z-index: 100;
}

/* override template.css .content rule causing empty area in info window */
#divD3MapContainer .content {
  min-height: 0px;
}

/* make the map popup display on top of the view title and map legend as in BRFSS */
.map {
  overflow: visible;
}

path:hover {
  fill-opacity: 0.7;
}

/* Style for Custom Tooltip */
div.tooltip {
  position: absolute;
  text-align: center;
  padding: 2px;
  font: 12px sans-serif;
  background: white;
  border: 1px solid #404040 !important;
  border-radius: 5px;
  pointer-events: none;
}

span.state-value {
  font-size: 20px;
  font-style: italic;
}

/* Legend Position Style */
.legend {
  position: absolute;
  left: 800px;
  top: 350px;
}

div.contentPane {
  padding: 6px;
  max-height: 125px;
}

div.contentPane div {
  padding-top: 8px;
}

div.legend-color-box {
  fill-opacity: 1;
  border: 1.35px solid #9a9a9a;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

div.cn {
  background-color: #444444;
  color: #ffffff;
  line-height: 20px;
  padding-left: 6px;
  -webkit-border-radius: 3px 3px 0px 0px;
  cursor: default;
}

div.legends-container {
  display: flex;
  flex-direction: column;
}
</style>
