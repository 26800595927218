<template>
  <div id="largepanel">
    <notifications
      :duration="10000"
      position="bottom right"
      width="50%"
    />
    <div
      v-if="showWaitSpinner"
      class="divWaitSpinner"
    >
      <div class="spinnerBackground">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <GlobalSelector />
    <div v-if="showHeadingsAndFilters" id="divDashboard" class="divBody">
      <div class="container-fluid">
        <div class="d-flex flex-wrap">
          <!-- StratFilters -->
          <div
            id="divFilter"
            class="stratFilter bgLightGray"
            :class="{ 'd-none': printerFriendly }"
          >
            <StratFilters />
          </div>
          <!-- main body -->
          <div id="divDashboardBody" class="dashBoardBody">
            <!-- Shared links -->
            <div id="divSharedLinks"><DashboardHeader /></div>
            <!-- view-buttons -->
            <div
              v-if="!printerFriendly && (locationId === '' || countyFlag === 'Y'
                || view === 'NationalChartTable'
                || (view === 'StateChartTable' && GSGo.countyExist === 'Y'))"
              id="divControls"
              class="viewButton txtCenter mt-2">
              <view-buttons />
            </div>
            <!-- VisualizationTitle -->
            <div id="divVisualizationTitle" class="txtCenter">
              <VisualizationTitle />
            </div>
            <!-- Map/Chart/Table -->
            <div id="divMapContent" class="txtCenter">
              <map-view-container v-if="showMapViewContainer" />
              <chart-view v-if="showChartViewContainer" />
              <table-view v-if="showTableViewContainer" />
            </div>
            <!-- All footers -->
            <div class="allFooters smallerText">
              <div id="divPanelNote">
                <panel-footnotes v-if="showFootnotes" />
              </div>
              <div
                v-if="!printerFriendly"
                class="buttonContainer">
                <button
                  type="button"
                  v-if="showPdfButton"
                  class="exportButton"
                  @click="exportToPdf">
                  Save as PDF
                </button>
                <button
                  type="button"
                  v-if="showCsvButton"
                  class="exportButton"
                  @click="exportToCsv">
                  Export CSV
                </button>
              </div>
              <div id="divPageNote">
                <page-footnotes />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./LargePanels1.js">
</script>

<style scoped>
  .divBody {
    margin-top: 10px;
  }
  .stratFilter {
    flex: 1;
    /* margin-top: 10px; */
  }
  .dashBoardBody {
    flex: 5;
  }
  /*@media (max-width: 576px) {
    .flexDirection {
      flex-direction: column !important;
    }
  }*/
  .allFooters {
    width: 80%;
    margin-left: 30px;
    /*margin-right: 50px;*/
    margin-top: 0.5rem;
  }
  .buttonContainer {
    margin-top: 1.5rem;
  }

  .exportButton {
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 4px 8px;
    margin: 0 3px;
    background-color: #b1dafb;
  }

  .exportButton:hover {
    background-color: #C9E4FC;
    cursor: pointer;
  }
  .viewButton {
    margin-top: -8px;
    padding-bottom: 10px;
  }

  @media (max-width: 576px) {
    .viewButton {
      margin-top: 5px;
    }
  }

</style>
