<template>
  <div>
    <fieldset class="form-group">
      <div class="no-wrap">
        <div class="displayInline">
          <label for="gsRiskFactor" class="col-form-label-sm">Characteristic</label>
        </div>
        <div class="displayInline ms-1">
          <select
            id="gsRiskFactor"
            :value="modelValue"
            class="form-select form-select-sm"
            @change="updateValue($event.target.value)"
          >
            <option key="" value="" class="dropdown-multi-level">
              <span>None Selected</span>
            </option>
            <optgroup
              :label="rft.abbr"
              v-for="rft in riskFactorTypes"
              :key="rft.id"
              :value="rft.id"
              class="dropdown-multi-level"
            >
              <option v-for="rfst in getRiskFactorSubTypes(rft.id)" :key="rfst.id" :value="rfst.id">
                {{ rfst.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<!-- ////////////////////////////////////////////////////////////////////////////////// -->
<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'GlobalSelectorRiskFactor',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      lku: (state) => state.lku,
    }),
    riskFactorTypes() {
      const ds = _(this.lku.RiskFactorCategoryType).orderBy('sort', 'asc').value();
      return ds;
      // const emptyDs = { id: '', abbr: 'All Data Sources' };
      // return [emptyDs, ...ds];
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    getRiskFactorSubTypes(riskFactorType) {
      const ds = _(this.lku.RiskFactorSubcategoryType)
        .filter({ namealias: riskFactorType })
        .orderBy('sort', 'asc')
        .value();
      return ds;
    },
  },
};
</script>
