<template>
  <div>
    <div id="mapContainer" />
    <div id="mapZoom" className="mapZoomContainer ml-3 p-1 d-print-none" :style="{ top: `${getBottomPosition()}px` }">
      <div>
        <button
          id="btnZoomIn"
          type="button"
          ref="zoomInRef"
          className="zoom-button filter-data-button btn btn-white p-1"
          :style="{ border: '1px solid #bdbdbd', color: 'gray' }"
          value="zoomIn"
          title="Zoom In"
        >
          <i class="fa fa-plus" aria-hidden="true" />
          <p className="sr-only">plus</p>
        </button>
      </div>
      <div>
        <button
          id="btnZoomOut"
          type="button"
          ref="zoomOutRef"
          className="zoom-button filter-data-button btn btn-white p-1"
          :style="{ border: '1px solid #bdbdbd', color: 'gray' }"
          value="zoomOut"
          title="Zoom Out"
        >
          <i class="fa fa-minus" aria-hidden="true" />
          <p className="sr-only">minus</p>
        </button>
      </div>
      <div>
        <button
          id="btnZoomReset"
          type="button"
          ref="zoomResetRef"
          className="zoom-button filter-data-button btn btn-white p-1"
          :style="{ border: '1px solid #bdbdbd', color: 'gray' }"
          value="zoomReset"
          title="Default Extend"
        >
          <i class="fa fa-repeat" aria-hidden="true" />
          <p className="sr-only">reset</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import usTopoJson from '../Topo/uscounties.topojson';
import smallStatesAndTerritories from '../Topo/small-state-territories';
import stateAbbrs from '../Topo/state-abbr';

export default {
  name: 'NationalMap',
  props: {
    stateCode: {
      value: '',
      default: '',
    },
    getColorRamp: {
      type: Function,
      default: Function,
    },
    onMapSelect: {
      type: Function,
      default: Function,
    },
    onStateHover: {
      type: Function,
      default: Function,
    },
    onStateHoverEnd: {
      type: Function,
      default: Function,
    },
    onLoaded: {
      type: Function,
      default: Function,
    },
    onStateClicked: {
      type: Function,
      default: Function,
    },
    stateAbbr: {
      type: Function,
      default: Function,
    },
    getTextColor: {
      type: Function,
      default: Function,
    },
  },
  data() {
    return {
      svg: null,
      g: {},
      path: null,
      active: null,
      width: 0,
      height: 0,
      margin: {},
      mapDrawn: false,
    };
  },
  watch: {
    stateCode(val) {
      this.handleRendering(val);
    },
  },
  mounted() {
    this.handleRendering(this.stateCode);
    this.onLoaded();
  },
  methods: {
    getTopoJson() {
      return topojson;
    },
    handleRendering(stateCode) {
      this.drawMap();
      if (stateCode) {
        this.onStateSelected(parseInt(stateCode, 10));
      }
    },
    refresh() {
      this.handleRendering(this.stateCode);
    },
    onStateSelected(stateCode) {
      this.active = d3.select(`#state-${stateCode}`);
      const nodeData = this.active.data()[0];
      this.highlightState(nodeData, this.active);
    },
    drawMap() {
      d3.select('#mapContainer').html('');
      this.margin = {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      };

      const mapRatio = 0.5;
      this.width = parseInt(d3.select('#mapContainer').style('width'), 10);
      this.width = this.width - this.margin.left - this.margin.right;
      this.height = this.width * mapRatio;
      this.active = d3.select(null);

      const svg = d3
        .select('#mapContainer')
        .append('svg')
        .attr('id', 'mational-map')
        .attr('height', this.height + 100)
        .attr('width', this.width + this.margin.left + this.margin.right)
        .attr('class', 'map-space');
      // .attr('height', this.height + 100) is the heigh of the map (increaes form +50 to + 100)

      // not really neccessary
      svg
        .append('rect')
        .attr('class', 'background')
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .attr('width', this.width + this.margin.left + this.margin.right)
        .on('click', this.highlightState);

      // Projection and Path
      const projection = d3
        .geoAlbersUsa()
        .translate([this.width / 2, this.height / 2])
        .scale(this.width);

      const path = d3.geoPath().projection(projection);

      // define group 1 - empty
      const g = svg
        .append('g')
        .attr('class', 'center-items us-state')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .attr('width', this.width + this.margin.left + this.margin.right)
        .attr('height', this.height + this.margin.top + this.margin.bottom);

      this.svg = svg;
      this.g = g;
      this.path = path;
      this.ready();
      document.getElementById('loadingImg').style.display = 'none';
    },
    beforeDestroy() {
      // console.log('StateMap: beforeDestroy');
    },
    ready() {
      // 1.draw states
      // eslint-disable-next-line no-unused-vars
      this.g
        .append('g')
        .attr('id', 'states')
        .selectAll('path')
        .data(this.getTopoJson().feature(usTopoJson, usTopoJson.objects.states).features)
        .enter()
        .append('path')
        .attr('d', this.path)
        .attr('class', 'state-boundary')
        .attr('fill', (d) => this.getColorRamp(d.id))
        .attr('id', (d) => `state-${d.id}`)
        .on('mouseover', (event, d) => this.onStateHover(d.id, event))
        .on('mouseout', (event, d) => this.onStateHoverEnd(d.id, event))
        .on('click', (event, d) => this.onStateClicked(d.id));

      // 2.draw state abbrs
      this.g
        .selectAll('text')
        .data(this.getTopoJson().feature(usTopoJson, usTopoJson.objects.states).features)
        .enter()
        .append('svg:text')
        .text((d) => {
          if (this.stateAbbr(d.id) === 'DC') return '';
          if (this.stateAbbr(d.id) === 'DE') return '';
          if (this.stateAbbr(d.id) === 'RI') return '';
          return this.stateAbbr(d.id);
        })
        .attr('class', 'state-text')
        .attr('x', (d) => {
          if (this.stateAbbr(d.id) === 'HI') return this.path.centroid(d)[0] - 20;
          if (this.stateAbbr(d.id) === 'FL') return this.path.centroid(d)[0] + 10;
          if (this.stateAbbr(d.id) === 'MI') return this.path.centroid(d)[0] + 10;
          return this.path.centroid(d)[0];
        })
        .attr('y', (d) => {
          if (this.stateAbbr(d.id) === 'NH') return this.path.centroid(d)[1] + 10;
          if (this.stateAbbr(d.id) === 'CT') return this.path.centroid(d)[1] + 5;
          if (this.stateAbbr(d.id) === 'MA') return this.path.centroid(d)[1] + 3;
          if (this.stateAbbr(d.id) === 'MI') return this.path.centroid(d)[1] + 20;
          return this.path.centroid(d)[1];
        })
        .attr('stroke', (d) => this.getTextColor(d.id));

      // 3.draw small states
      const smallState_x = 920;
      const smallState_y = 150;
      const smallState_width = 50;
      const smallState_height = 30;

      smallStatesAndTerritories.smallStateIds().forEach((stateId, index) => {
        this.g
          .append('g')
          .append('rect')
          .attr('class', 'state-boundary map-space')
          .attr('x', smallState_x)
          .attr('y', smallState_y + (index + 1) * 60)
          .attr('width', smallState_width)
          .attr('height', smallState_height)
          .style('fill', () => this.getColorRamp(stateId))
          .on('mouseover', (event) => this.onStateHover(stateId, event))
          .on('mouseout', (event) => this.onStateHoverEnd(stateId, event))
          .on('click', () => this.onStateClicked(stateId));

        // draw small states text
        this.g
          .append('g')
          .append('text')
          .attr('x', smallState_x + 25)
          .attr('y', smallState_y + 20 + (index + 1) * 60)
          .text(stateAbbrs[stateId])
          .attr('class', 'state-text')
          .attr('stroke', () => this.getTextColor(stateId));
      });

      // 4.draw teritories
      const smallTerritory_x = 200;
      const smallTerritory_y = 570;
      const smallTerritory_width = 50;
      const smallTerritory_height = 30;

      smallStatesAndTerritories.territoryIds().forEach((stateId, index) => {
        this.g
          .append('g')
          .append('rect')
          .attr('class', 'state-boundary')
          .attr('x', smallTerritory_x + (index + 1) * 75)
          .attr('y', smallTerritory_y)
          .attr('width', smallTerritory_width)
          .attr('height', smallTerritory_height)
          .style('fill', () => this.getColorRamp(stateId))
          .on('mouseover', (event) => this.onStateHover(stateId, event))
          .on('mouseout', (event) => this.onStateHoverEnd(stateId, event))
          .on('click', () => this.onStateClicked(stateId));

        // draw small teritories text
        this.g
          .append('g')
          .append('text')
          .attr('x', smallTerritory_x + 24 + (index + 1) * 75)
          .attr('y', smallTerritory_y + 21)
          .text(stateAbbrs[stateId])
          .attr('class', 'state-text')
          .attr('stroke', () => this.getTextColor(stateId));
      });
      /// ////////////////////
      this.mapDrawn = true;

      /// /////////////////////// Zoom ////////////////////////////////
      const mapGroup = this.g;
      function zoomed({ transform }) {
        mapGroup.attr('transform', transform);
      }
      const zoom = d3.zoom().scaleExtent([1, 40]).on('zoom', zoomed);

      // this make mouse scroll zoom the map
      // this.svg.call(zoom);

      d3.select('#btnZoomIn').on('click', () => {
        zoom.scaleBy(this.svg.transition().duration(500), 2);
        this.svg.call(zoom);
      });

      d3.select('#btnZoomOut').on('click', () => {
        zoom.scaleBy(this.svg.transition().duration(500), 0.5);
      });

      d3.select('#btnZoomReset').on('click', () => {
        this.svg
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity,
            d3.zoomTransform(this.svg.node()).invert([this.width / 2, this.height / 2]),
          );
        this.svg.on('.zoom', null); // unregister zoom -> no more draggin and mouse scrolling effect
      });
    },
    // highlight State
    highlightState(d, clickedPath) {
      if (!d) {
        return;
      }
      const activeNodePath = this ? this : clickedPath;
      if (d3.select('.background').node() === activeNodePath) {
        this.reset();
        return;
      }
      if (this.active.node() === activeNodePath) {
        this.reset();
        return;
      }

      // // active.classed("active", false);
      this.active.classed('active', true);
    },
    reset() {
      this.active.classed('active', false);
      this.active = d3.select(null);
      this.g
        .transition()
        .delay(100)
        .duration(750)
        .style('stroke-width', '.1px')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
    },
    getBottomPosition() {
      const element = document.getElementById('mapContainer');
      const c = element ? element.getBoundingClientRect() : null;
      const bottom = c && c.bottom ? c.bottom : 0;
      return bottom;
    },
  },
};
</script>
<style>
.map-space {
  overflow: visible;
}
.background {
  fill: #fff;
  pointer-events: all;
}
#states {
  fill: #fff;
}
#states .active {
  display: none;
}
.state-boundary {
  stroke: #000;
  stroke-width: 0.8px;
  stroke-dasharray: 0.5;
}
.state-boundary:hover {
  /* fill: orange !important; */
  stroke-dasharray: 0;
  stroke: orange !important;
  stroke-width: 5px;
  fill-opacity: 1 !important;
}
.state-text {
  pointer-events: none;
  text-anchor: middle;
  font-size: 10pt;
  stroke-width: 0.6; /* font-weight: 400; */
}
#mapContainer {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}
.mapZoomContainer {
  text-align: left;
  position: absolute;
  border: 1px solid lightgrey;
  background-color: white;
  /* top: 50px; */
  /*left: -50px;*/
  font-size: large;
}
.zoom-button {
  width: 35px;
  height: 35px;
}
@media (max-width: 767px) {
  .mapZoomContainer {
    top: 75px;
    left: -30px;
  }
}
</style>
