const smallStatesAndTerritories = {
  smallStateIds() {
    // 10: Delaware; 11: District Of Columbia; 44: Rhode Island
    return ['10', '11', '44'];
  },
  territoryIds() {
    // 66: Guam; 72: Puerto Rico; 78: U.S. Virgin Islands (VI);
    // 60: American Samoa (AS); 68: Marshall Islands (MH); 69: Northern Mariana Islands (MP)
    return ['66', '72', '78', '60', '68', '69'];
  },
  territories() {
    const csntTerritories = [{
      id: '66',
      properties: { name: 'Guam' },
    },
    {
      id: '72',
      properties: { name: 'Puerto Rico' },
    },
    {
      id: '78',
      properties: { name: 'Virgin Islands' },
      type: 'MultiPolygon',
      arcs: [],
    }];
    return csntTerritories;
  },
};
export default smallStatesAndTerritories;
