<template>
  <div :class="{ bodyPDF: printerFriendly, bodyBorder: !printerFriendly }">
    <!-- <div class="bodyBorder" v-if="!printerFriendly"></div> -->
    <PageHeader v-if="!printerFriendly" />
    <PageTitle v-if="!printerFriendly" />

    <div
      id="content-main"
      tabindex="-1"
      role="main">
      <router-view />
    </div>

    <PageFooter v-if="!printerFriendly" />
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import PageTitle from './components/PageTitle.vue';
import PageFooter from './components/PageFooter.vue';
import 'bootstrap/scss/bootstrap.scss';
import './scss/app.bundle.scss';

// import { mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
  },
  computed: {
    printerFriendly() {
      return this.$store.getters.printerFriendly;
    },
  },
};
</script>
