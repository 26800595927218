<template>
  <div id="national-map-view">
    <img
      id="loadingImg"
      src="_SupportFiles/Shared.Images.ajax-loader.gif"
      alt="Image that displays when the map is loading."
    />
    <div id="divD3MapContainer" :class="{ printerFriendly }" class="ThemeAlignTop">
      <div id="nationalMapContextData" />
      <NationalMap
        v-if="metadataLoaded === true"
        v-bind:stateCode="locationId"
        :getColorRamp="getColorRamp"
        :getTextColor="getTextColor"
        :stateAbbr="getStateAbbr"
        :onStateHover="onStateHover"
        :onStateHoverEnd="onStateHoverEnd"
        :onLoaded="onMapLoaded"
        :onStateClicked="onStateClicked"
        ref="nationalMap"
      />
    </div>
    <div class="legends-container" v-if="mapLoaded === true">
      <MapLegends
        :legendBreaks="legendBreaks"
        :classifierType="mapClassifierIdLoc"
        :classesCount="mapClassifierCountLoc"
        :printerFriendly="printerFriendly"
        :dataValueUnit="dataValueUnit"
        @ok="mapSettingsModified"
        @cancel="cancelClicked"
      />
    </div>
  </div>
</template>

<script>
// /* global triggerOmnitureInteractions */
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import * as d3 from 'd3';
import isNumber from 'is-number';
import helper from '../../../../helper';
import NationalMap from './NationalMap.vue';
import MapLegends from '../MapLegends.vue';
import dataClassification from '../Classification';
import constants from '../constants';
import { padLeftZeros } from '../../utils';
import geostats from '../Classification/geostats';
import stateAbbrs from '../Topo/state-abbr';

export default {
  name: 'NationalMapView',
  props: {},
  components: {
    NationalMap,
    MapLegends,
  },
  data() {
    return {
      mapClassifiers: constants.classifiers.types,
      mapClassifierCounts: constants.classifiers.classes,
      mapClassifierIdLoc: '',
      mapClassifierCountLoc: 0,
      classifyData: null,
      legendBreaks: [],
      counties: [],
      countyValue: '',
      previousStateId: '',
      metadataLoaded: false,
      mapLoaded: false,
      simplereportDataIds: {},
    };
  },
  computed: {
    ...mapState({
      dataSourceId: (state) => state.LP.GSGo.dataSourceId,
      indicatorId: (state) => state.LP.GSGo.indicatorId, // Omniture Only
      yearId: (state) => state.LP.stratFilters.yearId,
      // responseId: state => state.LP.stratFilters.responseId,
      ageId: (state) => state.LP.stratFilters.ageId,
      genderId: (state) => state.LP.stratFilters.genderId,
      raceId: (state) => state.LP.stratFilters.raceId,
      riskFactorId: (state) => state.LP.stratFilters.riskFactorId,
      riskFactorResponseId: (state) => state.LP.stratFilters.riskFactorResponseId,
      dataValueTypeId: (state) => state.LP.stratFilters.dataValueTypeId,
      mapClassifierId: (state) => state.LP.mapClassifierId,
      mapClassifierCount: (state) => state.LP.mapClassifierCount,
      reportDataIds: (state) => state.LP.reportDataIds,
      dataSourceTypeId: (state) => state.LP.GSGo.dataSourceTypeId,
      topicId: (state) => state.LP.GSGo.topicId,
      categoryId: (state) => state.LP.GSGo.categoryId,
      locationId: (state) => state.LP.GSGo.locationId,
      view: (state) => state.LP.view,
      compareViewYear: (state) => state.LP.stratFilters.compareViewYear,
      compareId: (state) => state.LP.stratFilters.compareId,
      compareId2: (state) => state.LP.stratFilters.compareId2,
      loadingreportDataIds: (state) => state.LP.flags.loadingreportDataIds,
      lku: (state) => state.lku,
      level1Name: (state) => state.LP.GSGo.level1Name,
      level2Name: (state) => state.LP.GSGo.level2Name,
      level3Name: (state) => state.LP.GSGo.level3Name,
      level4Name: (state) => state.LP.GSGo.level4Name,
      riskFactorSubCatId: (state) => state.LP.GSGo.riskFactorSubCatId,
    }),
    ...mapGetters([
      'indicator',
      'dataValueType',
      'dataValueUnit',
      'sampleSizeSymbol',
      'dataValueUnitShort',
      'decimalPlaces',
      'mapSummary',
      'printerFriendly',
      'confidenceIntervalLabel',
      'compareInteractionCode',
      'viewInteractionCode',
      'yearInteractionCode',
    ]),
    mapClassifier() {
      const row = _(this.mapClassifiers).find({ id: this.mapClassifierId });
      return row ? row.description : '';
    },
    legendSettingsCSV() {
      return `${this.mapClassifierId},${this.mapClassifierCount}`;
    },
    responseId() {
      const rId = helper.getResponseId(this.indicatorId);
      return rId;
    },
    locations() {
      const flattened = _(this.lku.Location).reduce((lo, val) => lo.concat([val]), []);
      return helper.addAllLocation(_(flattened).orderBy('sort', 'asc').value());
    },
  },
  created() {
    this.mapClassifierIdLoc = this.mapClassifierId;
    this.mapClassifierCountLoc = this.mapClassifierCount;
  },
  watch: {
    reportDataIds() {
      this.mapRefresh();
    },
  },
  mounted() {
    // this.getreportDataIds();
    document.getElementById('loadingImg').style.display = 'block';
    if (this.reportDataIds && this.reportDataIds.length > 0) {
      console.log(this.reportDataIds);
      this.mapRefresh();
    }
  },
  beforeUnmount() {
    // console.log('NationalMapView: beforeDestroy');
  },
  methods: {
    setClassifications() {
      const breaks = [];
      let dataUnavailable = false;
      const dataClassificationPoints = _.map(
        _.filter(this.reportDataIds, (o) => o.lci != null && o.hci != null),
        (val) => (val.dv === null ? 0 : val.dv),
      );

      dataUnavailable = _.map(_.filter(this.reportDataIds, (o) => o.dv == null)).length > 0;
      // eslint-disable-next-line
      const gs = new geostats(dataClassificationPoints);
      gs.setPrecision(4);
      switch (this.mapClassifierId) {
        case 'quantile':
          gs.getClassQuantile(this.mapClassifierCount);
          break;

        case 'natural-breaks':
          gs.getJenks2(this.mapClassifierCount);
          break;

        case 'equal-interval':
          gs.getEqInterval(this.mapClassifierCount);
          break;

        default:
          gs.getClassQuantile(this.mapClassifierCount);
          break;
      }

      const classificationRanges = gs.ranges.map((o, i) => ({
        c: i,
        min: parseFloat(o.split('-')[0]),
        max: parseFloat(o.split('-')[1]),
      }));

      this.classifyData = {
        legend: classificationRanges,
      };
      const lengendRanges = this.classifyData.legend;
      for (let i = 0; i < lengendRanges.length; i += 1) {
        let minNumber = 0;
        let maxNumber = 0;
        if (lengendRanges[i].min) {
          if (i > 0) {
            lengendRanges[i].min += 0.01;
            if (lengendRanges[i].min > lengendRanges[i].max) {
              lengendRanges[i].min = lengendRanges[i].max;
            }
          }
          minNumber = lengendRanges[i].min.toFixed(2);
          maxNumber = lengendRanges[i].max.toFixed(2);
          if (Number(minNumber) <= Number(maxNumber) && Number(minNumber) > 0 && Number(maxNumber) > 0) {
            const existBreak = breaks.find((e) => e.min === minNumber && e.max === maxNumber);
            if (!existBreak) {
              breaks.push({
                index: i,
                min: minNumber,
                max: maxNumber,
                color: dataClassification.getColorInRange(maxNumber, lengendRanges),
                desc: `${padLeftZeros(minNumber)} - ${padLeftZeros(maxNumber)}`,
                // desc: `${padLeftZeros(lengendRanges[i].min.toFixed(2))} - ${padLeftZeros(lengendRanges[i].max.toFixed(2))}`,
              });
            }
          }
        } else {
          dataUnavailable = true;
          // The range may start with 0, that means there is no data county
          // however, the range that starts with 0 may include having-data counties
          // thus, set the ramge min + 0.01 instead of ignore the whole range
          if (lengendRanges[i].min === 0 && lengendRanges[i].max > 0) {
            lengendRanges[i].min += 0.01;
            minNumber = lengendRanges[i].min.toFixed(2);
            maxNumber = lengendRanges[i].max.toFixed(2);
            // if (Number(minNumber) < Number(maxNumber)) {
            if (Number(minNumber) <= Number(maxNumber) && Number(minNumber) > 0 && Number(maxNumber) > 0) {
              const existBreak = breaks.find((e) => e.min === minNumber && e.max === maxNumber);
              if (!existBreak) {
                breaks.push({
                  index: i,
                  min: minNumber,
                  max: maxNumber,
                  color: dataClassification.getColorInRange(maxNumber, lengendRanges),
                  desc: `${padLeftZeros(minNumber)} - ${padLeftZeros(maxNumber)}`,
                  // desc: `${padLeftZeros(lengendRanges[i].min.toFixed(2))} - ${padLeftZeros(lengendRanges[i].max.toFixed(2))}`,
                });
              }
            }
          }
        }
      }

      if (dataUnavailable) {
        breaks.push(this.noDataColorForLegend());
      }
      this.legendBreaks = breaks;
    },
    noDataColorForLegend() {
      return {
        index: 0,
        min: 0,
        max: 0,
        color: constants.colorRamp.noDataColor,
        desc: constants.labels.dataUnavailable,
      };
    },
    noDataColorForLegendExists() {
      if (
        this.legendBreaks.length > 0 &&
        this.legendBreaks[this.legendBreaks.length - 1].color === constants.colorRamp.noDataColor
      ) {
        return true;
      }
      return false;
    },
    getColor(dataPoint) {
      if (this.classifyData != null) {
        return dataClassification.getColorInRange(dataPoint, this.classifyData.legend);
      }
      return constants.colorRamp.noDataColor;
    },
    onMapLoaded() {
      const svgEl = document.getElementById('mational-map');
      if (svgEl) {
        const svgHeight = svgEl.getBoundingClientRect().height;
        document.getElementById('national-map-view').style.height = `${svgHeight}px`;
      }
      this.mapLoaded = true;
    },
    onStateHover(stateId, eventData) {
      const mapToolTip = d3.select('#nationalMapContextData');
      const selectedState = _.find(this.reportDataIds, (item) => parseInt(item.loc, 10) === parseInt(stateId, 10));

      let toolTipCoordinates = {
        x: eventData.offsetX,
        y: eventData.offsetY,
      };

      if (!!(window.MSInputMethodContext && document.documentMode) || /MSIE 10/.test(navigator.userAgent)) {
        toolTipCoordinates = {
          x: eventData.x,
          y: eventData.y,
        };
      }
      if (selectedState) {
        mapToolTip.transition().duration(200).attr('class', 'tooltip').style('opacity', 0.9);

        mapToolTip
          .html(this.buildToolTipContent(selectedState))
          .style('left', `${toolTipCoordinates.x}px`)
          .style('top', `${toolTipCoordinates.y - 28}px`);
      }
    },
    onStateHoverEnd() {
      this.clearToolTip();
    },
    buildToolTipContent(reportData) {
      const dataValue = reportData.dv;
      const footnoteSymbol = reportData.fs;
      const locationDesc = this.getLocationName(reportData.loc);
      const lowCI = reportData.lci;
      const highCI = reportData.hci;
      const sampleSize = reportData.ss;
      const showCI = isNumber(dataValue) && isNumber(lowCI) && isNumber(highCI);
      const showSS = isNumber(dataValue) && isNumber(sampleSize);
      const locationAbbr = this.getLocationAbbr(reportData.loc);
      const dvuPrefix = this.dataValueUnitShort === '$' ? '$' : '';
      const dvuSuffix = this.dataValueUnitShort === '%' ? '%' : ` ${this.dataValueUnitShort}`;

      let templateContent = `<div class="tooltip-header">${this.getLocationName(reportData.loc)}</div>`;
      templateContent += '<div class="tooltip-body">';

      templateContent += `<strong>${locationDesc}: ${
        isNumber(dataValue) ? dvuPrefix + Number(dataValue).toFixed(this.decimalPlaces) + dvuSuffix : footnoteSymbol
      }</strong>`;
      if (showCI) {
        templateContent += `<div style='padding-top:8px'>${this.confidenceIntervalLabel} (${Number(lowCI).toFixed(
          this.decimalPlaces,
        )} - ${Number(highCI).toFixed(this.decimalPlaces)})</div>`;
      }
      if (showSS) {
        templateContent += `<div style='padding-top:8px'>${this.sampleSizeSymbol} = ${Number(
          sampleSize,
        ).toLocaleString()}</div>`;
      }
      if (showCI) {
        // templateContent += `<div style='padding-top:8px'><a href='${process.env.VUE_APP_RouterBase}/LP?LocationId=${locationId}&Level1=${this.level1Name}&Level2=${this.level2Name}&Level3=${this.level3Name}&Level4=${this.level4Name}&DataSourceId=${this.dataSourceId}&IndicatorId=${this.indicatorId}&ShowFootnotes=true&View=Chart&CompareViewYear=1&CompareId=&CompareId2=&YearId=${this.yearId}&ResponseId=${this.responseId}&AgeId=${this.ageId}&GenderId=${this.genderId}&RaceId=${this.raceId}&RiskFactorId=${this.riskFactorId}&RiskFactorResponseId=${this.riskFactorResponseId}&DataValueTypeId=${this.dataValueTypeId}&MapClassifierId=${this.mapClassifierId}&MapClassifierCount=${this.mapClassifierCount}'>Explore VEHSS data for ${locationAbbr}</a></div>`;
        templateContent += `<div style='padding-top:8px'>Click to explore VEHSS data for ${locationAbbr}</div>`;
      }
      templateContent += '</div>';
      return templateContent;
    },
    getColorRamp(stateId) {
      const matchingState = _.find(this.reportDataIds, (dt) => parseInt(dt.loc, 10) === parseInt(stateId, 10));
      if (matchingState) {
        if (matchingState.dv) {
          return this.getColor(matchingState.dv);
        }
        return constants.colorRamp.noDataColor;
      }
      if (!this.noDataColorForLegendExists()) {
        this.legendBreaks.push(this.noDataColorForLegend());
      }
      return constants.colorRamp.noDataColor;
    },
    mapRefresh() {
      this.metadataLoaded = false;
      this.buildSimplereportDataIds();
      if (_.find(this.reportDataIds, (o) => o.loc)) {
        this.metadataLoaded = true;
        this.setClassifications();
        if (this.$refs.nationalMap) {
          this.$refs.nationalMap.refresh();
        }
      }
    },
    mapSettingsModified(legendSettings) {
      this.mapClassifierIdLoc = legendSettings.classificationMethod;
      this.mapClassifierCountLoc = legendSettings.classesCount;

      const idChanged = this.mapClassifierIdLoc !== this.mapClassifierId;
      const countChanged = this.mapClassifierCountLoc !== this.mapClassifierCount;
      if (idChanged) {
        this.$store.commit('setMapClassifierId', this.mapClassifierIdLoc);
      }
      if (countChanged) {
        this.$store.commit('setMapClassifierCount', this.mapClassifierCountLoc);
      }

      if (idChanged || countChanged) {
        this.mapRefresh();
        this.$store.getters.setBrowerUrlBarToMatchCurrentView(this.$router);
      }
    },
    clearToolTip() {
      const mapToolTip = d3.select('#nationalMapContextData');
      mapToolTip.transition().duration(500).style('opacity', 0);
    },
    decimalPointNumber(inputNumber) {
      if (inputNumber && inputNumber.toString().indexOf('.') > -1) {
        return inputNumber.toString().split('.')[1].length;
      }
      return 0;
    },
    buildSimplereportDataIds() {
      if (this.reportDataIds && this.reportDataIds.length > 0) {
        // TODO: implement later
        // this.simplereportDataIds
        return '';
      }
      return '';
    },
    getLocationName(locationId) {
      let locationName = '';
      if (locationId) {
        locationName = helper.getLocationName(locationId, this.locations);
      }
      return locationName;
    },
    getLocationAbbr(locationId) {
      let locationAbbr = '';
      if (locationId) {
        locationAbbr = helper.getLocationAbbr(locationId, this.locations);
      }
      return locationAbbr;
    },
    onStateClicked(stateId) {
      const reportData = _.find(this.reportDataIds, (item) => parseInt(item.loc, 10) === parseInt(stateId, 10));
      if (reportData) {
        const dataValue = reportData.dv;
        const lowCI = reportData.lci;
        const highCI = reportData.hci;
        const showCI = isNumber(dataValue) && isNumber(lowCI) && isNumber(highCI);
        const locationId = reportData.loc;
        if (showCI) {
          const openStateUrl = `${process.env.VUE_APP_RouterBase}/LP?LocationId=${locationId}&Level1=${this.level1Name}&Level2=${this.level2Name}&Level3=${this.level3Name}&Level4=${this.level4Name}&DataSourceId=${this.dataSourceId}&RiskFactorSubCatId=${this.riskFactorSubCatId}&IndicatorId=${this.indicatorId}&ShowFootnotes=true&View=Chart&CompareViewYear=1&CompareId=&CompareId2=&YearId=${this.yearId}&ResponseId=${this.responseId}&AgeId=${this.ageId}&GenderId=${this.genderId}&RaceId=${this.raceId}&RiskFactorId=${this.riskFactorId}&RiskFactorResponseId=${this.riskFactorResponseId}&DataValueTypeId=${this.dataValueTypeId}&MapClassifierId=${this.mapClassifierId}&MapClassifierCount=${this.mapClassifierCount}`;
          window.open(openStateUrl, '_self');
        }
      }
    },
    getStateAbbr(stateId) {
      let stateAbbr = '';
      const reportData = _.find(this.reportDataIds, (item) => parseInt(item.loc, 10) === stateId);
      if (reportData) {
        stateAbbr = this.getLocationAbbr(reportData.loc);
      } else {
        let formatStateId = stateId;
        if (stateId in [1, 2, 5, 6, 8, 9]) {
          formatStateId = `0${stateId.toString()}`;
        }
        stateAbbr = stateAbbrs[formatStateId];
      }
      return stateAbbr;
    },
    getTextColor(stateId) {
      let stateBackgroundColor = constants.colorRamp.noDataColor;
      let stateNameColor = '#000';
      const blue1 = 'rgb(239,243,255)'; // #EFF3FF
      const blue2 = 'rgb(198,219,239)'; // #C6DBEF
      const blue3 = 'rgb(158,202,225)'; // #9ECAE1
      const blue4 = 'rgb(107,174,214)'; // #6BAED6
      const blue5 = 'rgb(66,146,198)'; // #4292C6
      const blue6 = 'rgb(33,113,181)'; // #2171B5
      const blue7 = 'rgb(8,69,148)'; // #084594

      const matchingState = _.find(this.reportDataIds, (dt) => parseInt(dt.loc, 10) === parseInt(stateId, 10));
      if (matchingState) {
        if (matchingState.dv) {
          stateBackgroundColor = this.getColor(matchingState.dv);
        }
      }
      switch (stateBackgroundColor) {
        case blue1:
        case blue2:
        case blue3:
        case blue4:
        case blue5:
          stateNameColor = '#000';
          break;
        case blue6:
        case blue7:
          stateNameColor = '#fff';
          break;
        default:
          stateNameColor = '#000';
      }
      // Specific
      if (stateAbbrs[stateId] === 'HI') {
        stateNameColor = '#000';
      }
      return stateNameColor;
    },
  },
};
</script>

<style>
#national-map-view {
  /*height: 900px;*/
  margin-left: 100px;
  margin-right: 100px;
  position: relative;
  display: flex;
}

#divHomeButton {
  position: absolute;
  top: 95px;
  left: 20px;
  z-index: 1;
}

#divD3MapContainer {
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  width: 84%;
  height: 100%;
}

#divD3MapContainer.printerFriendly .esriSimpleSlider {
  display: none;
}

#divD3MapContainer.printerFriendly .homeContainer {
  display: none;
}

.tc {
  text-align: center;
}

.legendSettingSelectors label {
  display: block;
  font-weight: bold;
}

.legendSettingSelectors {
  padding: 10px 40px;
}

.modalButtons {
  text-align: center;
  padding: 10px 0;
}

.modalButtons button {
  width: 80px;
  margin: 0 5px;
  height: 37px;
  font-weight: bold;
}
.default-button {
  color: white;
  background-color: #075290;
}
.disabledButton {
  background-color: #f6f6f6;
  color: #cecece;
}

.HomeButton .home {
  background-image: url(../../../../../public/_SupportFiles/Shared.Images.resetviewicon.jpg);
  background-color: white;
  border: 1px solid #57585a;
  border-radius: 5px;
}

#loadingImg {
  position: absolute;
  left: 40%;
  top: 230px;
  z-index: 100;
}

/* override template.css .content rule causing empty area in info window */
#divD3MapContainer .content {
  min-height: 0px;
}

/* make the map popup display on top of the view title and map legend as in BRFSS */
.map {
  overflow: visible;
}
path:hover {
  fill-opacity: 0.7;
}
span.state-value {
  font-size: 20px;
  font-style: italic;
}
/* Legend Position Style */
.legend {
  position: absolute;
  left: 800px;
  top: 350px;
}
div.contentPane div {
  padding-top: 8px;
}
div.legend-color-box {
  fill-opacity: 1;
  border: 1.35px solid #9a9a9a;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
div.legends-container {
  display: flex;
  flex-direction: column;
}
/* Style for Custom Tooltip */
div.tooltip {
  position: absolute;
  text-align: center;
  padding: 2px;
  font: 12px sans-serif;
  background: white;
  border: 1px solid #404040 !important; /*border: 0px;*/
  border-radius: 5px;
  pointer-events: none;
  z-index: 1;
}
div.tooltip-header {
  background-color: #444444;
  color: #ffffff;
  line-height: 20px;
  padding-left: 6px;
  -webkit-border-radius: 3px 3px 0px 0px;
  cursor: default;
}
div.tooltip-body {
  padding: 6px;
  max-height: 125px;
}
</style>
