import _ from 'lodash';
import isNumber from 'is-number';
import numeral from 'numeral';

export default function getPivotedTableViewRows(
  lku,
  reportDataIdValues,
  compareId,
  confidenceIntervalLabel,
  sampleSizeSymbol,
  countyFlag,
) {
  const reportDataIds = _.cloneDeep(reportDataIdValues);
  // group by location for now. This could be changed in the future to group by some other strat
  // if there is only one location.
  // GROUP
  const groupColumn = 'loc'; // used to map with 'loc' prop in reportDataIds
  const groupLookupType = 'Location';

  let groupRows = [];
  if (countyFlag === 'Y') {
    const groupRows1 = _(reportDataIds)
      .map((item) => {
        const cn = _.camelCase(item.cn);
        return {
          id: item.cf,
          groupId: cn,
          abbr: cn,
          name: item.cn,
          sort: 1,
        };
      })
      .value();

    groupRows = _.uniqBy(groupRows1, 'id');// _(groupRows1).map('groupId').uniq().value();
  } else {
    groupRows = _(reportDataIds).map(groupColumn).uniq()
      .map((id) => lku[groupLookupType][id])
      .orderBy('sort', 'asc')
      .value();
  }

  //  console.log('groupRows', groupRows);
  //  console.log('groupRows.length', groupRows.length);

  function getStratCompareColumn() {
    switch (compareId) {
      case '':
        return 'OVERALL';
      case 'YEAR':
        return 'yr';
      case 'RESPONSE':
        return 'rs';
      case 'AGE':
        return 'ag';
      case 'GENDER':
        return 'ge';
      case 'RACE':
        return 're';
      default:
        if (compareId.substring(0, 2) === 'RF') return 'rfr';
        throw new Error(`Unexpected compareId: ${compareId}`);
    }
  }

  function getStratCompareLookupType() {
    switch (compareId) {
      case '':
        return '';
      case 'YEAR':
        return 'Year';
      case 'RESPONSE':
        return 'Response';
      case 'AGE':
        return 'AgeGroup';
      case 'GENDER':
        return 'Gender';
      case 'RACE':
        return 'RaceEthnicity';
      default:
        if (compareId.substring(0, 2) === 'RF') return 'RiskFactorResponse';
        throw new Error(`Unexpected compareId: ${compareId}`);
    }
  }

  const subGroupColumn = getStratCompareColumn();
  const stratLookupType = getStratCompareLookupType();

  function getStratColumns() {
    if (compareId === '') {
      return [{
        id: 'OVERALL', name: 'Overall', abbr: 'Overall', sort: 1,
      }];
    }
    return _(reportDataIds).map(subGroupColumn).uniq()
      .map((id) => lku[stratLookupType][id])
      .orderBy('sort', 'asc')
      .value();
  }

  const subGroupColumns = getStratColumns();

  // console.log('subGroupColumns', subGroupColumns);
  // console.log('subGroupColumns.length', subGroupColumns.length);

  function getDataRowLabel(dataValueType, rptDataIds) {
    const dataValueUnit = rptDataIds.length > 0 ? rptDataIds[0].dvu : '';
    if (dataValueUnit === '%') return 'Percent (%)';
    if (dataValueUnit === '') return dataValueType;
    return `${dataValueType} (${dataValueUnit})`;
  }

  const hasCI = _(reportDataIds).map('lci').filter((x) => _.isNumber(x)).value().length > 0;
  const hasSampleSize = _(reportDataIds).map('ss').filter((x) => _.isNumber(x)).value().length > 0;
  const dataRowLabel = getDataRowLabel('Crude Prevalence', reportDataIds);
  const decimalPlaces = 2;

  //
  // Set values, confidence index to null if the sample
  // size is 0
  _.map(reportDataIds, (r) => {
    if (_.isNumber(r.ss) && r.ss === 0) {
      /* eslint-disable no-param-reassign */
      r.dv = null;
      r.lci = null;
      r.hci = null;
      r.ss = null;
    }
    return r;
  });

  //  console.log('hasCI', hasCI);
  //  console.log('hasSampleSize', hasSampleSize);
  //  console.log('dataRowLabel', dataRowLabel);
  // groupId   = 01
  // groupName = Alabama
  // rowType   = Data || SampleSize || SampleSize
  // grpRows   = {"loc":"01","yr":"YR7","rs":"RYES","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFAPAR","rfr":"RFALLPAR2","dv":3.2,"dvu":"%","lci":3,"hci":3.4,"ss":47476},
  // stratCols = {"id":"OVERALL","name":"Overall","abbr":"Overall","sort":1}
  function buildDataRow(groupId, groupName, rowType, grpRows, stratCols) {
    let rowLabel;

    if (rowType === 'Data') {
      rowLabel = dataRowLabel; // Percent (%) || Crude Prevalence
    } else if (rowType === 'CI') {
      rowLabel = confidenceIntervalLabel; // 95% CI
    } else {
      rowLabel = sampleSizeSymbol; // N || n
    }

    const tableRowObj = { groupId, group: groupName, label: rowLabel };
    _(stratCols).forEach((col) => {
      // add column for each strat
      const filter = {};
      if (col.id !== 'OVERALL') filter[subGroupColumn] = col.id; // yr : YR7 ||
      const groupRowsForThisColumn = grpRows.filter(filter).value(); // grpRows above

      let colContent = '';
      if (groupRowsForThisColumn.length === 0) {
        colContent = '';
      } else {
        // Only use the first row if there are more than 1 row for this group.
        // Duplicate id data errors are now caught via
        // the LP action getReportDataIdsAndSetStratFilterIds.
        // {"loc":"01","yr":"YR7","rs":"RYES","ag":"AGEALL","ge":"GALL","re":"ALLRACE","rf":"RFAPAR","rfr":"RFALLPAR2","dv":3.2,"dvu":"%","lci":3,"hci":3.4,"ss":47476},
        // Percent (%): 3.20 (dv)
        // 95% CI: 3.00 - 3.40 (lci-hci)
        // N: 47,476 (ss)
        const firstRow = groupRowsForThisColumn[0];
        if (rowType === 'Data') {
          const dataValue = firstRow.dv;
          const footNoteSymbol = firstRow.fs;
          const value = isNumber(dataValue)
            ? Number(dataValue).toFixed(decimalPlaces)
            : dataValue;
          if (value) {
            if (footNoteSymbol) {
              colContent = `${value}<sup>${footNoteSymbol}</sup>`;
            } else {
              colContent = value;
            }
          } else if (footNoteSymbol) {
            colContent = footNoteSymbol;
          }
        } else if (rowType === 'CI') {
          let lowCI = firstRow.lci;
          let highCI = firstRow.hci;
          lowCI = isNumber(lowCI) ? Number(lowCI).toFixed(decimalPlaces) : '';
          highCI = isNumber(highCI) ? Number(highCI).toFixed(decimalPlaces) : '';
          colContent = lowCI && highCI ? `${lowCI} - ${highCI}` : '';
        } else {
          const sampleSize = firstRow.ss;
          colContent = isNumber(sampleSize) ? numeral(sampleSize).format('0,0') : '';
        }
      }
      // add property for strat column
      tableRowObj[col.id] = colContent; // OVERALL : 3.2
    });
    return tableRowObj;
  }

  const pivotedTableViewRows = [];
  _(groupRows).forEach((group) => {
    const groupFilter = {};
    if (countyFlag === 'Y') {
      groupFilter.cf = group.id; // cf : 10001
    } else {
      groupFilter[groupColumn] = group.id; // loc : 01
    }
    const groupByDataRows = _(reportDataIds).filter(groupFilter);
    pivotedTableViewRows.push(buildDataRow(group.id, group.name, 'Data', groupByDataRows, subGroupColumns));
    if (hasCI) pivotedTableViewRows.push(buildDataRow(group.id, group.name, 'CI', groupByDataRows, subGroupColumns));
    if (hasSampleSize) pivotedTableViewRows.push(buildDataRow(group.id, group.name, 'SampleSize', groupByDataRows, subGroupColumns));
  });

  // console.log('pivotedTableViewRows', pivotedTableViewRows);
  return { pivotedTableViewRows, subGroupColumns, groupRows };
}
