import _ from 'lodash';
import * as d3 from 'd3';
import isNumber from 'is-number';

/* eslint-disable */
function makeChartBar(dataObj, lku, compareStr, settingsObj, chartMountNodeIdStr, locationId) {

  let compareStrOverall = 'Overall'; //- one bar per State
  let dataCompareColumn = settingsObj.dataCompareColumn; //"ag"
  let chartTitleStr = settingsObj.chartTitleStr; //"Percent (%)"
  let decimalPlaces = settingsObj.decimalPlaces; //2
  let chartSummary = settingsObj.chartSummary; //`This chart gives ${this.dataValueType} data from the ${this.dataSourceName} database for the selected topic and category with [CompareValue] for ${this.location} on the x-axis.`;
  let sampleSizeSymbol = settingsObj.sampleSizeSymbol; //"N"
  let colorsArrStr = settingsObj.colorsArrStr; //["#1f78b4", "#e31a1c", "#33a02c", "#6a3d9a", "#ff7f00", "#b15928", "#a6cee3", "#fb9a99", "#b2df8a", "#cab2d6", "#fdbf6f", "#ffff99"]
  let colorsArrStrHover = settingsObj.colorsArrStrHover; //['#2592da', '#ea484b', '#41c837', '#7349b6', '#ff9933', '#d0672f', '#c5dfed', '#fcb7b6', '#cae9af', '#ddcde5', '#fdd39b', '#ffffcc']
  let confidenceIntervalLabel = settingsObj.confidenceIntervalLabel; //"95% CI"
  let maxTicks = settingsObj.maxTicks;
  let txttAngleRotate = settingsObj.txttAngleRotate;

  let dataObjSorted = [];
  let groupTypes = {};
  let groupIds = [];
  let groupNames = [];
  let totalGroupIds = 0;

  //--1--Filter out one location
  let oneLocData = dataObj.filter(item => item.loc == locationId);

  // adding location name
  oneLocData = _.forEach(oneLocData, function (o) {
    o.locName = lku.Location[o.loc].name;
    return o;
  })

  // adding group name
  if (compareStr === compareStrOverall) {
    dataObjSorted = _.forEach(oneLocData, function (o) {
      o.groupName = compareStrOverall;
      o.groupId = '';
      o.sortGroup = 1;
      return o;
    });
    totalGroupIds = 1;
    groupNames = [compareStrOverall];
  }
  else { // Not Overall
    //1.-------------- ADD additional column into each row: groupName, sortGroup ------
    // adding type name and type sort
    groupTypes = lku[compareStr];
    //compareStr: AgeGroup
    //groupTypes: {"AGEALL":{"id":"AGEALL","name":"All Ages","sort":1},"AGE017":{"id":"AGE017","name":"0-17 years","sort":2},...}
    //console.log(`compareStr: ${compareStr}`);
    //console.log(`groupTypes: ${JSON.stringify(groupTypes)}`);

    //dataCompareColumn: "ag"
    //o[dataCompareColumn]: "AGE1839"
    //groupTypes[o[dataCompareColumn]].name: "18-39 years"
    //o[dataCompareColumn]: "AGE4064"
    //groupTypes[o[dataCompareColumn]].name: "40-64 years"
    oneLocData.forEach(function (o) {
      o.groupName = groupTypes[o[dataCompareColumn]].name;
      o.groupId = groupTypes[o[dataCompareColumn]].id;
      o.sortGroup = groupTypes[o[dataCompareColumn]].sort;
      return o
    });
    // console.log(`oneLocData: ${JSON.stringify(oneLocData)}`);

    //2.-------------- CALCULATE TOTAL GROUPS ------
    //-GROUP: total number of bars, sorted by group sort order
    let allGroups = _.map(oneLocData, dataCompareColumn);
    groupIds = _.sortBy(_.uniq(allGroups), [function (o) {
      return groupTypes[o].sort;
    }])
    totalGroupIds = groupIds.length;
    //groupIds: ["AGEALL","AGE017","AGE1839","AGE4064","AGE6584","AGE85PLUS"]

    //3.Remove all rows  which doesn't have dv value
    dataObjSorted = oneLocData.filter((d) => !isNaN(d.dv));

    //4.--------------SORT ------
    dataObjSorted = _.sortBy(dataObjSorted, ['sortGroup'])

    //5.--------------CREATE GROUP NAMES ARRAY ------
    groupNames = _.uniq(dataObjSorted.map((r) => r.groupName));

    //let groupNames1 = _.uniq(dataObjSorted.map((r) =>  [r.groupId, r.groupName] ));
    //console.log(`groupNames1: ${JSON.stringify(groupNames1)}`);

  } // Not Overall
  //-------------------------------------- Create Chart ------------------------------------------

  //---chart with 1000 pixels width and 600 pixels height.
  let svgAreaWidth = 1200;
  let svgAreaHeight = 500;
  let catPadding = 0.2;
  let textRotate = "0";
  let textAnchor = "middle";
  let spaceForTiltLabel = 0;
  let maxBarWidth = 100;
  let totalGroupName = groupNames.length;

  //calculate svgAreaWidth
  if (totalGroupName === 1) {
    svgAreaWidth = totalGroupName * 500;
  }
  else {
    svgAreaWidth = totalGroupName * 250;
  }
  if (svgAreaWidth > 1200) {
    svgAreaWidth = 1200;
  }

  let margin = { top: 10, right: 20, bottom: 50, left: 60 };
  let chartWidth = svgAreaWidth - margin.left - margin.right;
  let chartHeight = svgAreaHeight - margin.top - margin.bottom;

  //////////////////////////////////////////////////////////////////---create X SCALE
  const xScale = d3.scaleBand()
    .range([0, chartWidth])
    .domain(groupNames)
    .padding(catPadding);
    //groupNames = dataObjSorted.map((r) => r.groupName)

  //adjustlabel display,  margin bottom, and chart height
  let xAxisLabelMaxWidth = xScale.bandwidth();
  let longestGroupName = groupNames.sort(function (a, b) { return b.length - a.length; })[0];
  let longestGroupNameInPixel = getStringLengthInPixel(longestGroupName);
  if (xScale.bandwidth() < longestGroupNameInPixel - 10) {
    textRotate = txttAngleRotate; //for tilt label
    if (longestGroupNameInPixel >= 200) {
      spaceForTiltLabel = longestGroupNameInPixel - 120;
    }
    else {
      spaceForTiltLabel = Math.max(50, longestGroupNameInPixel - 60); //for tilt label
    }
    //spaceForTiltLabel = Math.max(40, longestGroupNameInPixel - 250); //for horizontal label
    textAnchor = "end";
  }

  margin.bottom = margin.bottom + spaceForTiltLabel;
  chartHeight = svgAreaHeight - margin.top - margin.bottom;

  ///////////////////////////////////////////////////////////////////---create Y SCALE
  let maxHci = d3.max(dataObjSorted, function (d) { try { return parseFloat(d.hci); } catch (err) { return 0; } });
  let maxDv = d3.max(dataObjSorted, function (d) { try { return parseFloat(d.dv); } catch (err) { return 0; } });
  let maxValue = Math.max(maxHci, maxDv);

  if (maxValue === 0) {
    maxValue = 0.09;
  }

  const yScale = d3.scaleLinear()
    .range([chartHeight, 0])
    .domain([0, maxValue]).nice();  //.domain([0, maxValue + scaleExtend]);

  //add extra tick to Y axis
  const ticks = yScale.ticks();
  const lastTick = ticks[ticks.length - 1];
  const newLastTick = lastTick + (ticks[1] - ticks[0]);
  if (lastTick < yScale.domain()[1]) {
    ticks.push(newLastTick);
  }
  yScale.domain([yScale.domain()[0], newLastTick]).nice(); //<-- adjust domain for further value

  //console.log(`lastTick: ${lastTick}`);
 // console.log(`newLastTick: ${newLastTick}`);

  //define yAxis with ticks
  let yAxis = d3.axisLeft(yScale);
  let tickLength = yScale.ticks().length;
  tickLength = getTickLength(tickLength);
  yAxis = yAxis.ticks(tickLength);

  //Add another extra tick
  const ticks1 = yScale.ticks();
  const lastTick1 = ticks1[ticks1.length - 1];
  const newLastTick1 = lastTick1 + (ticks1[1] - ticks1[0]);
  //console.log(`lastTick1: ${lastTick1}`);
  //console.log(`newLastTick1: ${newLastTick1}`);

  //if (lastTick < yScale.domain()[1]) {
  //  ticks.push(newLastTick);
  //}
  if (lastTick1 === newLastTick && newLastTick1 > newLastTick) {
    yScale.domain([yScale.domain()[0], newLastTick1]).nice; //<-- adjust domain for further value
    yAxis = d3.axisLeft(yScale);
    yAxis = yAxis.ticks(tickLength);
  }

////////////////////////////////////////
  //---create COLOR scale
  let color = d3.scaleOrdinal()
    .range(colorsArrStr);

  let colorHover = d3.scaleOrdinal()
    .range(colorsArrStrHover);

  //---CLEAR out the chart div for a new chart
  const chartMountNode = document.getElementById(chartMountNodeIdStr);
  while (chartMountNode.firstChild) {
    chartMountNode.removeChild(chartMountNode.firstChild)
  }

  //---create SVG area ------------------
  const svgArea = d3.select('#' + chartMountNodeIdStr).append("svg")
    .attr("width", svgAreaWidth)
    .attr("height", svgAreaHeight)
    .attr('id', 'svgArea');
    //.attr("style", "outline: thin solid red;");

  //---CHART summary ------------------
  svgArea.append('desc')
    .text(chartSummary);

  //---create CHART STRATING POINT -----------------
  const svgChart = svgArea.append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  //---add y axis to svgChart ----------
  svgChart.append('g')
    .attr("class", "yAxis")
    .call(yAxis);

  //---add x axis to svgChart
  svgChart.append('g')
    .attr("class", "xAxis")
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(d3.axisBottom(xScale))
    .selectAll("text")
    .call(wrap, xAxisLabelMaxWidth)
    .attr("transform", `rotate(${textRotate})`)
    //.style("text-anchor", `${textAnchor}`)
    //.attr("dy", "-5em")
    //.attr("dx", "-.8em")

  //--- create GRID LINE----------------------
  //create grid line first so it lays under Bar layer
  svgChart.append('g')
    .attr('class', 'grid')
    .call(yAxis
      .tickSize(-chartWidth, 0, 0)
      .tickFormat(''))

  ////////////////////////////////////////////////////////////////
  //--- create BAR ---------------------------
  //console.log(`dataObjSorted: ${JSON.stringify(dataObjSorted)}`);
  svgChart.selectAll()
    .data(dataObjSorted)
    .enter()
    .append('rect')
    .attr('id', function (d, index) {
      return "rectId" + index;
    })
    .attr('x', (r) => xScale(r.groupName) + (xScale.bandwidth() - Math.min(xScale.bandwidth(), maxBarWidth)) / 2)
    .attr('y', (r) => yScale(isNaN(r.dv) ? 0 : r.dv))
    .attr('height', function (r) {
      let ch = chartHeight - yScale(isNaN(r.dv) ? 0 : r.dv);
      //if (ch === 0) {
      //  ch = 0.0001; //create this so stroke can take effect
      //}
      return ch;
      })
      .attr('width', Math.min(xScale.bandwidth(), maxBarWidth))
      .attr('fill', color)
      .style('stroke', color)
      .style('stroke-width', function (d) {
        if (isNaN(d.dv) || d.dv === 0) {
          return "1px";
        }
        else { return "0" }
    })
    .on('mouseover', function (event, d, index) {
      OnMouseOver(d, event, index);
    })
    .on('mouseout', function (event, d, index) {
      OnMouseOut(d, event, index)
    });

  /////////////////////////////////////////////////////////////////////////
  //--- create HI-LO LINE----------------
  let intervalLines = svgChart.selectAll('intervalBarsG')
    .data(dataObjSorted)
    .enter()
    .append('g');

  //a.main line
  intervalLines
    .append('line')
    .attr('class', 'hiloLine')
    .attr('x1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2;
      }
    })
    .attr('y1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.lci);
      }
    })
    .attr('x2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2;;
      }
    })
    .attr('y2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.hci);
      }
    })
    .style('visibility', function (data) {
      if (isNaN(data.lci) || isNaN(data.hci) || data.hci === 0) {
        return 'hidden';
      }
      return 'visible';
    });

  //b.bottom line
  intervalLines
    .append('line')
    .attr('class', 'hiloLine')
    .attr('x1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2 - 5;
      }
    })
    .attr('y1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.lci);
      }
    })
    .attr('x2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2 + 5;
      }
    })
    .attr('y2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.lci);
      }
    })
    .style('visibility', function (data) {
      if (isNaN(data.lci) || isNaN(data.hci) || data.hci === 0) {
        return 'hidden';
      }
      return 'visible';
    });

  //c.top line
  intervalLines
    .append('line')
    .attr('class', function (data) {
      return 'hiloLine';
    })
    .attr('x1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2 - 5;
      }
    })
    .attr('y1', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.hci);
      }
    })
    .attr('x2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return xScale(data.groupName) + xScale.bandwidth() / 2 + 5;
      }
    })
    .attr('y2', function (data, index) {
      if (!isNaN(data.lci) && !isNaN(data.hci)) {
        return yScale(data.hci);
      }
    })
    .style('visibility', function (data) {
      if (isNaN(data.lci) || isNaN(data.hci) || data.hci === 0) {
        return 'hidden';
      }
      return 'visible';
    });

  //d. create onmouseover
  intervalLines
    .on('mouseover', function (event, d, index) {
      OnMouseOver(d, event, index);
    })
    .on('mouseout', function (event, d, index) {
      OnMouseOut(d, event, index)
    });

  ///////////////////////////////////////////////////////////////////
  //Create invisible big circle on top of each rectangle
  //so when the mouse is over it, popup will display
  let invisibleCircle = svgChart.selectAll('invisibleCircle')
    .data(dataObjSorted)
    .enter()
    .append('g');

  invisibleCircle
    .append("circle")
    .attr('class', function (d) {
      if (isNaN(d.dv) || d.dv === 0)
        return 'invisibleCircle';
      else
        return 'invisibleCircleNone';
    })
    .attr("r", function (d) {
      if (isNaN(d.dv) || d.dv === 0)
        return 5;
      else
        return 0;
    })
    .attr("fill", "transparent")
    .attr("cx", function (d) {
      if (!isNaN(d.dv)) {
        return xScale(d.groupName) + xScale.bandwidth() / 2;
      }
    })
    .attr("cy", function (d) {
      if (!isNaN(d.dv)) {
        return yScale(d.dv);
      }
    })

  invisibleCircle
    .on('mouseover', function (event, d, index) {
      OnMouseOver(d, event, index);
    })
    .on('mouseout', function (event, d, index) {
      OnMouseOut(d, event, index)
    });

  /////////////////////////////////////////////////////////////////////
  //--- create LABEL for Y-AXIS----------------
  svgArea.append('text')
    .attr('class', 'chartLabel')
    .attr('x', -(chartHeight / 2) - margin.top)
    .attr('y', margin.left / 6)
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .text(chartTitleStr);

  ////////////////////////////////////////////////////////////////////////
  //--- create Mouser Over and ToolTip ----------------
  let barTooltip = d3.select('#' + chartMountNodeIdStr)
    .append('div')
    .style('opacity', 0)
    .attr('id', 'barTooltip')

  function getTooltipStr(d) {
    let tooltipStr = '<strong>' + d.groupName + '<br>' + Number(d.dv).toFixed(decimalPlaces) + d.dvu + '</strong>'
    if (isNumber(d.lci) && isNumber(d.hci)) {
      tooltipStr += '<br>' + confidenceIntervalLabel + ' (' + Number(d.lci).toFixed(decimalPlaces)
        + ' - ' + Number(d.hci).toFixed(decimalPlaces) + ')';
    }
    if (isNumber(d.ss)) {
      tooltipStr += '<br>' + sampleSizeSymbol + ' = ' + Number(d.ss).toLocaleString();
    }
    return tooltipStr;
  }

  function OnMouseOver(d, event, index) {
    barTooltip.transition()
      .duration(450)
      .style('opacity', 1)
    barTooltip.html(getTooltipStr(d))
      .style('left', (event.pageX + 15) + 'px')
      .style('top', (event.pageY - 20) + 'px')

    d3.select('#rectId' + index)
      .attr('fill', colorHover)
      .style('stroke', colorHover)
      .style('stroke-width', function (d) {
        if (isNaN(d.dv) || d.dv === 0) {
          return "4px";
        }
        else { return "0" }
      })
  }

  function OnMouseOut(d, event, index) {
    barTooltip.transition()
      .duration(250)
      .style('opacity', 0)

    d3.select('#rectId' + index)
      .attr('fill', color)
      .style('stroke', color)
      .style('stroke-width', function (d) {
        if (isNaN(d.dv) || d.dv === 0) {
          return "1px";
        }
        else { return "0" }
      })
  }

  ///////////////////////////////////////////////////////////
  //--------------------- MISC --------------------------
  function getStringLengthInPixel(strInput) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.font = "14px";
    var width = ctx.measureText(strInput).width;
    return width;
  }

  function getTickLength(tickLength) {
    while (tickLength >= maxTicks) {
      tickLength = tickLength / 2;
    }
    return tickLength;
  }

  function wrap(text, width) {
    text.each(function wrapText() {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y)
        .attr('dy', `${dy}em`)
        .style('text-anchor', () => {
          if (textRotate != "0") { return 'end'; }
          return 'middle';
        });
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', `${++lineNumber * lineHeight + dy}em`)
            .style('text-anchor', () => {
              if (textRotate != "0") { return 'end'; }
              return 'middle';
            })
            .text(word);
        }
      }
    });
  }
}

export default makeChartBar;
