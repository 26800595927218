<template>
  <div id="mapContainer" />
</template>
<script>
import _ from 'lodash';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import usTopoJson from '../Topo/uscounties.topojson';
import { padLeftZeros } from '../../utils';

export default {
  name: 'StateMap',
  props: {
    stateCode: {
      value: '',
      default: '',
    },
    getColorRamp: {
      type: Function,
      default: Function,
    },
    onMapSelect: {
      type: Function,
      default: Function,
    },
    onHover: {
      type: Function,
      default: Function,
    },
    onHoverEnd: {
      type: Function,
      default: Function,
    },
    onLoaded: {
      type: Function,
      default: Function,
    },
  },
  data() {
    return {
      g: {},
      active: null,
      path: null,
      width: 0,
      height: 0,
      margin: {},
      mapDrawn: false,
    };
  },
  watch: {
    stateCode(val) {
      this.handleRendering(val);
    },
  },
  mounted() {
    this.handleRendering(this.stateCode);
    this.onLoaded();
  },
  methods: {
    getTopoJson() {
      return topojson;
    },
    handleRendering(stateCode) {
      this.drawMap();
      if (stateCode) {
        this.onStateSelected(parseInt(stateCode, 10));
      }
    },
    refresh() {
      this.handleRendering(this.stateCode);
    },
    onStateSelected(stateCode) {
      this.active = d3.select(`#state-${stateCode}`);
      const nodeData = this.active.data()[0];
      this.highlightState(nodeData, this.active);
    },
    drawMap() {
      d3.select('#mapContainer').html('');
      this.margin = {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      };
      const mapRatio = 0.5;
      this.width = parseInt(d3.select('#mapContainer').style('width'), 10);
      this.width = this.width - this.margin.left - this.margin.right;
      this.height = this.width * mapRatio;
      this.active = d3.select(null);

      const svg = d3.select('#mapContainer').append('svg')
        .attr('id', 'state-map')
        .attr('class', 'center-container')
        .attr('height', this.height - 10)
        .attr('width', this.width + this.margin.left + this.margin.right);

      // not really neccessary
      svg.append('rect')
        .attr('class', 'background center-container')
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .attr('width', this.width + this.margin.left + this.margin.right)
        .on('click', this.highlightState);

      // Projection and Path
      const projection = d3.geoAlbersUsa()
        .translate([this.width / 2, this.height / 2])
        .scale(this.width);

      const path = d3.geoPath()
        .projection(projection);

      // define group 1 - empty
      const g = svg.append('g')
        .attr('class', 'center-container center-items us-state')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .attr('width', this.width + this.margin.left + this.margin.right)
        .attr('height', this.height + this.margin.top + this.margin.bottom);

      this.path = path;
      this.g = g;
      this.ready(usTopoJson);
    },
    // beforeDestroy() {
    //   // console.log('StateMap: beforeDestroy');
    // },
    ready() {
      // define groups child 1 of g - each group is a county
      this.g.append('g')
        .attr('id', 'counties')
        .selectAll('path')
        .data(this.getTopoJson().feature(usTopoJson, usTopoJson.objects.counties).features)
        .enter()
        .append('path')
        .attr('d', this.path)
        .attr('class', 'county-boundary')
        .attr('fill', (d) => {
          const paddedCt = padLeftZeros(d.id);
          if (_.startsWith(padLeftZeros(d.id), this.stateCode)) {
            const colorCode = this.getColorRamp(paddedCt);
            return colorCode;
          }
          return '#fff';
        })
        .attr('id', (d) => `county-${padLeftZeros(d.id)}`)
        .on('mouseover', (event, d) => this.onHover(d, event))
        .on('mouseout', (event, d) => this.onHoverEnd(d, event));

      // define groups child 2 of g - each group is a state
      this.g.append('g')
        .attr('id', 'states')
        .selectAll('path')
        .data(
          this.getTopoJson().feature(
            usTopoJson,
            usTopoJson.objects.states,
          )
            .features,
        )
        .enter()
        .append('path')
        .attr('d', this.path)
        .attr('class', 'state')
        .attr('id', (d) => `state-${d.id}`);

      this.g.append('path')
        .datum(this.getTopoJson().mesh(usTopoJson, usTopoJson.objects.states, (a, b) => a !== b))
        .attr('id', 'state-borders')
        .attr('d', this.path);

      // eslint-disable-next-line no-undef
      $(`[id^=county-${this.stateCode}]`).addClass('state-county-boundary');
      this.mapDrawn = true;
    },
    highlightState(d, clickedPath) {
      if (!d) {
        return;
      }
      const activeNodePath = this ? this : clickedPath;
      if (d3.select('.background').node() === activeNodePath) {
        this.reset();
        return;
      }
      if (this.active.node() === activeNodePath) {
        this.reset();
        return;
      }

      // active.classed("active", false);
      this.active.classed('active', true);
      const bounds = this.path.bounds(d);
      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;
      const scale = 0.9 / Math.max(dx / this.width, dy / this.height);
      const translate = [this.width / 2 - scale * x, this.height / 2 - scale * y];

      this.g.transition()
        .duration(550)
        .style('stroke-width', `${0.1 / scale}px`)
        .attr('transform', `translate(${translate})scale(${scale})`);
    },
    reset() {
      this.active.classed('active', false);
      this.active = d3.select(null);
      this.g.transition()
        .delay(100)
        .duration(750)
        .style('stroke-width', '.1px')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
    },
  },
};
</script>
<style>
.background {
    fill: #fff;
    pointer-events: all;
}

#states {
    fill: #fff;
}

#states .active {
    display:none;
}

#state-borders {
    fill: none;
    stroke: #000;
    stroke-width: 0.0px;
    stroke-linejoin: round;
    stroke-linecap: round;
    pointer-events: none;
}

.county-boundary {
    stroke: #fff;
    stroke-width: .2px;
    stroke-dasharray: 0.5;
}

 .state:hover {
    fill: #fff;
}

.county-boundary:hover {
    /* fill: orange; */
    stroke-dasharray: 0;
    stroke: orange !important;
    stroke-width: 1px !important;
    fill-opacity: 1 !important;
}

.county-selected {
    /* fill: orange; */
    stroke-width: .4px;
    stroke-dasharray: 0;
    stroke: orange !important;
    stroke-width: 1px !important;
    fill-opacity: 1 !important;
}

#mapContainer {
    width:100%;
    height:100%;
}

#divMapContent {
  min-height:700px;
}

#divMapViewContainer {
  margin:0px;
}

.state-county-boundary {
  stroke:#000;
  stroke-width:0.1px;
  stroke-dasharray:0;
}
</style>
