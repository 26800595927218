<template>
  <div>
    <h2>{{ question }}</h2>
    <div class="title">
      <div class="txtLarge" v-if="response">{{ responseLabel }}{{ response }}</div>
      <div class="txtLarge">{{ location }}&nbsp;| {{ year }}&nbsp;|&nbsp;{{ dataSourceViewTitle }}</div>
      <div class="txtLarge">
        {{ dataValueType }}
      </div>
      <div class="txtLarge" v-if="ageGenderRaceFiltersDescription !== ''">
        {{ ageGenderRaceFiltersDescription }}
      </div>
      <div class="txtLarge" v-if="compare1WithCompare2 !== ''">
        {{ compare1WithCompare2 }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      lku: (state) => state.lku,
      GSGo: (state) => state.LP.GSGo,
      compareId: (state) => state.LP.stratFilters.compareId,
      ageId: (state) => state.LP.stratFilters.ageId,
      genderId: (state) => state.LP.stratFilters.genderId,
      raceId: (state) => state.LP.stratFilters.raceId,
      view: (state) => state.LP.view,
      location: (state) => state.LP.GSGo.location,
      compareId2: (state) => state.LP.stratFilters.compareId2,
    }),
    ...mapGetters([
      'question',
      'year',
      'response',
      'age',
      'gender',
      'race',
      'dataValueType',
      'dataSourceViewTitle',
      'compareName',
      'compareName2',
      'riskFactorResponseAlternate',
    ]),
    ageGenderRaceFiltersDescription() {
      const desc = [];

      // check this.age is not undefined, not null, and not empty
      if (this.age) {
        desc.push(this.age);
      }
      if (this.gender) {
        desc.push(this.gender);
      }
      if (this.race) {
        desc.push(this.race);
      }
      if (this.riskFactorResponseAlternate) {
        desc.push(this.riskFactorResponseAlternate);
      }
      return desc.join(' | ');
    },
    responseLabel() {
      const dsRow = this.lku.DataSource[this.GSGo.dataSourceId];
      if (dsRow) {
        const dataSourceTypeId = dsRow.namealias; // used namealias for dataSourceTypeId from query
        return dataSourceTypeId === 'SURVEY' ? 'Response: ' : '';
      }
      return '';
    },
    compare1WithCompare2() {
      if (this.compareId === 'YEAR' && this.compareId2 !== '') {
        return `Compare By: ${this.compareName2}`;
      }
      if (this.compareId !== 'YEAR' && this.compareId !== '' && this.compareId2 === '') {
        return `Compare By: ${this.compareName}`;
      }
      if (this.compareId !== 'YEAR' && this.compareId2 !== '') {
        return `Compare By: ${this.compareName} & ${this.compareName2}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
  font-size: 1.1rem !important;
  font-weight: bold;
}
.title {
  font-weight: bold;
}
.txtLarge {
  font-size: 1rem;
}
.txtLarger {
  font-size: 1.2rem;
}
</style>
