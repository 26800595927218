<template>
  <div>
    <div class="county-typeahead-container">
      <!-- County Search Input -->
      <input
        id="county-search"
        class="form-control"
        type="text"
        v-model="selection"
        :placeholder="verbiage.searchCounty"
        :aria-label="verbiage.searchCounty"
        @keydown.esc='escape'
        @keydown.enter='enter'
        @keydown.down='down'
        @keydown.up='up'
        @input='change'
      />
      <!-- County Suggestions based on the search filter -->
      <ul
        class="dropdown-menu"
        v-bind:aria-expanded="open"
        v-bind:class="{ 'typeahead-active': open }"
      >
        <li
          class="cn-item"
          v-for="(suggestion, i) in matches"
          v-bind:key="suggestion.index"
          v-bind:class="{ active: isActive(i) }"
          @click="suggestionClick(i)"
          @keyup="suggestionClick(i)"
        >{{ suggestion.name }}
        </li>
      </ul>
    </div>
    <!-- Clear County Selection -->
    <!-- <div class="align-right">
        <a class="clear-selection"
          role="button"
          v-bind:aria-label="verbiage.clearSelection"
          aria-describedby="county-search"
          href="#" @click.prevent="clearSelection"
          :disabled="{'disabled': !selection}">{{verbiage.clearSelection}}</a>
      </div> -->
  </div>
</template>
<script>
export default {
  name: 'CountyTypeahead',
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      current: 0,
      selection: '',
      verbiage: {
        clearSelection: 'Clear',
        searchCounty: 'Search county',
      },
    };
  },
  methods: {
    escape() {
      this.open = false;
    },
    // When enter pressed on the input
    enter() {
      if (this.matches.length === 0) {
        return;
      }
      if (this.matches.length === 1 && this.matches.length < this.current) {
        this.current = 0;
      }
      this.selection = this.matches[this.current].name;
      this.open = false;
      this.suggestionClick(this.current);
    },

    // When up pressed while suggestions are open
    up() {
      if (this.current > 0) this.current -= 1;
    },

    // When up pressed while suggestions are open
    down() {
      if (this.current < this.suggestions.length - 1) this.current += 1;
    },

    // For highlighting element
    isActive(index) {
      return index === this.current;
    },

    // When the user changes input
    change() {
      if (this.open === false) {
        this.open = true;
        this.current = 0;
      }
      if (this.matches.length === 0) {
        this.open = false;
      }
    },

    clearSelection(doNotEmit) {
      this.selection = '';
      this.open = false;
      if (doNotEmit !== true) {
        this.$emit('countySelected');
      }
    },

    // When one of the suggestion is clicked
    suggestionClick(index) {
      const validIndex = this.matches.length <= index ? 0 : index;
      const countyMatch = this.matches[validIndex];
      this.selection = countyMatch.name;
      this.open = false;
      this.$emit('countySelected', { id: countyMatch.cf, name: countyMatch.name });
    },
  },
  computed: {

    // Filtering the suggestion based on the input
    matches() {
      return this.suggestions.filter((str) => {
        if (this.selection) {
          return str.name.toLowerCase().indexOf(this.selection.toString().toLowerCase()) >= 0;
        }
        return true;
      });
    },
    // The flag
    openSuggestion() {
      return this.selection !== '' &&
               this.matches.length !== 0 &&
               this.open === true;
    },
  },
};
</script>
<style>
li.cn-item {
  cursor: pointer;
  font-size: 13px;
  padding-left: 10px;
}

li.active {
  background-color:darkgray;
}

ul.dropdown-menu {
  width:100%;
  max-height: 200px;
  overflow-y: auto;
}

ul.typeahead-active {
  display:block;
}

div.county-typeahead-container {
    position: relative;
}

div.align-right {
    text-align: right;
}

a.clear-selection {
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
}
</style>
