const mutations = {
  setInitialPageLoad: (state, val) => {
    state.flags.initialPageLoad = val;
  },
  setShowDebugIds: (state, val) => {
    state.flags.showDebugIds = val;
  },
  setShowMode: (state, val) => {
    state.flags.showMode = val;
  },
  setLoadingInitialApiData: (state, val) => {
    state.flags.loadingInitialApiData = val;
  },
  setLku: (state, lku) => {
    state.lku = lku;
  },
  setDSLink: (state, ds) => {
    state.dsLink = ds;
  },
  setFootnotes: (state, fn) => {
    state.footnotes = fn;
  },
  addApiError: (state, err) => {
    state.apiErrors.push(err);
  },
  addDataError: (state, err) => {
    state.dataErrors.push(err); // err: string
  },
  clearDataErrors: (state) => {
    state.dataErrors = [];
  },
  setLoadingRiskFactorApiData: (state, val) => {
    state.flags.loadingRiskFactorApiData = val;
  },
  setLoadingCategoryLevelApiData: (state, val) => {
    state.flags.loadingCategoryLevelApiData = val;
  },
  setLoadingDSLinkLocationApiData: (state, val) => {
    state.flags.loadingDSLinkLocationApiData = val;
  },
  setLoadingQuestionLocationApiData: (state, val) => {
    state.flags.loadingQuestionLocationApiData = val;
  },
  setDSCatLevel: (state, ds) => {
    state.dsCatLevel = ds;
  },
  setDSLocationLink: (state, ds) => {
    state.dsLocationLink = ds;
  },
  setDSQuestionLocationLink: (state, ds) => {
    state.dsQuestionLocationLink = ds;
  },
  setDSRiskFactor: (state, ds) => {
    state.dsRiskFactor = ds;
  },
  setLoadingDefaultDSApiData: (state, val) => {
    state.flags.loadingDefaultDSApiData = val;
  },
  setDefaultDataSourceId: (state, value) => {
    state.defaultDataSourceId = value;
  },
};

export default mutations;
